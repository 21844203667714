/* eslint-disable func-style */
/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
import React from "react";
import ReactDOM from "react-dom";
import Header from "./Header.jsx";
import Body from "./Body.jsx";

function ParentPage() {
    return (
        <div>
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
                integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
                crossOrigin="anonymous"
            />
            <style>
                {`
                html, body {
                    height: 100%;
                    margin:0;
                }
                    .full-height {
                    height: 85%;
                    //   overflow:scroll;
                    margin:45px 45px 45px 45px;
                    padding:35px 35px 5px 35px;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    scrollbar-width: none;
                }
                .full-height::-webkit-scrollbar {
                    display: none;
                }
                .full-height {
                    -ms-overflow-style: none;  / IE and Edge /
                    scrollbar-width: none;  / Firefox /
                }
                .bg{
                    background-image: url('https://i.ibb.co/W6rQsGd/Courses-Path-of.png');
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    background-size: cover;
                    background-position:center;
                    width:100%;

                }
                .mx-4{
                    margin-left: 60px;
                }

                .input{
                    background-color: transparent;
                    height:200px;
                    border-radius: 15px;
                }
                button{
                    text-align:left;
                    border:none;
                    background-color:#fff;
                }
                .btn-class {
                    border: 2px solid transparent;
                    font-size: 30px;
                    margin: 0;
                    width: 100%;
                    line-height: 35px;
                    background: linear-gradient(to left, #227088 2px, transparent 2px);
                    background-position: left center;
                    background-repeat: repeat-y;
                  }

                  .active {
                    margin: 0;
                    border: none;
                    max-width: 196px;
                    border: 2px solid #227088;
                    border-right: none;
                    background: linear-gradient(to left, #227088 -54px, transparent 2px, transparent);
                    background-position: left center;
                    background-repeat: repeat-y;

                  }


                .select-group{
                    width:80%
                    margin:0 10px;
                }
                .select{
                    height:35px;
                    background-color:#F2F2F2;
                    border: 2px solid #B6B6B6;
                    border-radius:5px;

                }
                .text{

                    border-left:3px solid #B7B7B7;
                }
                .w-100 {
                    width: 100%!important;
                    display: flex;
                    flex-direction: column;
                    /* gap: 5px; */
                }
                .w-100-performroll {
                    width: 100%!important;
                    display: flex;
                    flex-direction: row;
                }
                @keyframes append-animate {
                    from {
                        transform: scaleY(0);
                        opacity: 0;
                    }
                    to {
                        transform: scaleY(1);
                        opacity: 1;
                    }
                }

                .new-box {
                    transform-origin: 50% 0;
                    animation: append-animate .3s linear;
                }
                .text.w-50.mx-4 {
                    max-height: 35px;
                }
                .text-p{
                    height:35px;
                    font-size:14px;
                    color:#989797;
                    align-items: center;
                    padding-top: 7px;
                    padding-left: 24px;
                    width: 233px;
                    word-break: break-word;
                    white-space: pre-wrap;

                }
                .select-group-all{
                    width:60%;
                    padding-right:5%;
                }
                .text-group{
                    padding-left:15px;
                    width:40%
                }
                .tiles-space {
                    width: 63vw;
                    height: 288px;
                    border: 1px solid;
                    border-radius: 5px;
                    overflow: auto;



                }
                #updated-button:hover{
                    color: white;
                    background: rgb(38, 113, 139);
                }

                .progress-row{
                    display: flex;
                    gap: 11px;
                    height: 44px;
                    margin-left: 34px;
                    margin-top: 16px;
                  }

                  .arrow1{
                    height: 82px;
                    margin-top: 19px;
                    margin-left: 2px;
                  }
                  .arrow2{
                    height: 82px;
                    margin-top: 13px;
                    margin-right: -8px;
                    margin-left: -20px;
                  }
                  .starEnd{
                    height: 45px;
                    margin-top: -7px;
                    margin-left: 12px;
                }
                .arrow{
                    height: 38px;
                    padding-top: 7px;

                }

                .last-name {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -50px;
                    margin-left: 245px;
                }

                .phSelect {
                    width: 48vw;
                }

                .phTips {
                    display: flex;
                    width: 16vw;
                    overflow: hidden;
                    word-wrap: normal;
                    flex-wrap: wrap;
                    color: #9B9999;
                    font-size: 12px;
                    align-items: center;
                    justify-content: flex-start;
                    width: 226px;
                    word-break: break-word;
                    white-space: pre-wrap;
                }

                .generate{
                    height:35px;
                    border-radius:5px;
                    align-content:center;
                    justify-content: center;
                    // margin: 0;
                    position: relative;
                    margin-top:18%;
                    width: 48%;
                    overflow: hidden;
                }
                .generate-p{
                    text-align:center;
                    margin:0;
                    position: absolute;
                    top: 50%;
                    -ms-transform: translateY(50%);
                    transform: translateX(80%)
                    translateY(-50%);
                }
                .generate-button{
                    background-color: transparent;
                    width: 100%;
                    height:35px;
                    color:#fff;
                    background-color:#227088;
                }

                .generate-button:disabled,
                    button[disabled]{
                    color: #666666;
                }

                .icon img{
                    width:50px;
                    margin:auto;
                }
                .logo img{
                    width:200px;
                    margin-left: -12px;
                }
                .sub-title{
                    margin-left:0;
                    margin-bottom:50px;
                }
                #menu{
                    width:222px;
                    margin-left: -12px;
                }
                .image{
                    width:50px;
                    margin:5px
                }
                .perform-roll{
                    border:1px solid #000;
                    height:100%
                }
                .perform-text{
                    font-size:14px;
                    line-height:16px;
                    color:#fff;
                    margin:5px 3px;
                    padding:3px;
                    cursor:pointer;
                    width:98%
                }
                .selected-perform-text{
                    font-size:14px;
                    line-height:16px;
                    color:#000;
                    margin:5px 3px;
                    padding:3px;
                    cursor:pointer;
                    width:100%;

                }

                .clickSelect{
                    border:1px solid #000;
                    color:#000;
                    cursor:pointer;
                    font-size:14px;
                    line-height:16px;
                    margin:3px;
                    padding:3px;
                    background-color:#fff;
                }

                .enrolled .perform-text{
                    background-color:#014A6C;
                }
                .booked .perform-text{
                    background-color:#26718B;
                }
                .class .perform-text{
                    background-color:#77B0B5;
                }
                perform-text:hover{
                    background-color:#fff;
                    color:#000;
                }
                .input-code{
                    width:60%;
                }
                .code-group{
                    display:grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 5px;
                    align-items: center;
                    justify-content: center;
                    padding:25px;
                }
                .code{
                    border: 3px solid #C7CBCE;
                    border-radius: 5px;
                    width:40px;
                    height:40px;
                    text-align:center;
                    margin:auto;
                    font-size:23px;
                    font-weight:bold;
                }
                .menu{
                    margin-top:25px

                }
                .create-section{
                    width:90%;
                    padding-left:5%
                }
                .text-one{

                }
                .text-two{
                    margin-top:25px;
                }
                .text-three{
                    margin-top:15px;
                }
                .photo-warning{
                    max-width: 20px;
                }
                [data-tooltip]:before {
                    /* needed - do not touch */
                    content: attr(data-tooltip);
                    position: absolute;
                    opacity: 0;

                    /* customizable */
                    transition: all 0.15s ease;
                    font-size: 12px;
                    padding: 5px;
                    color: #333;
                    border-radius: 3px;
                    box-shadow: 2px 2px 1px silver;
                }

                [data-tooltip]:hover:before {
                    /* needed - do not touch */
                    opacity: 1;

                    /* customizable */
                    background: #ffffff;
                    border: 2px solid #e82929;
                    margin-top: -50px;
                    margin-left: 20px;
                }

                [data-tooltip]:not([data-tooltip-persistent]):before {
                    pointer-events: none;
                }
                @media only screen and (min-width: 600px){
                    .btn-class{
                        font-size:15px
                        line-height:20px;
                    }
                    .title,.sub-title{
                        font-size:15px;
                        padding:auto;
                        margin:auto;
                    }
                    .full-height{
                        // height: 100%;
                    }

                   .tiles-space {
                    width: 73vw;
                   }

                }
                @media only screen and (min-width: 1024px){
                    .title,.sub-title{
                        font-size:20px;
                        padding:auto;
                        margin:auto;
                    }
                    .full-height{
                        // height: 100%;
                    }

                    .btn-class,.select,.generate-p{
                        font-size:12px;
                    }
                    .select,.text-p,{
                        height:25px;
                    }
                    .input-code{
                        width:75%;
                    }
                    .tiles-space {
                        width: 64vw;
                       }
                       .parent-button{
                        top: 75%;
                    }
                }
                @media only screen and (min-width: 1100px){
                    .title,.sub-title{
                        font-size:20px;
                        padding:auto;
                        margin:auto;
                    }
                    .full-height{
                        // height: 100%;
                    }

                    .btn-class,.select,.generate-p{
                        font-size:11px;
                    }
                    .select,.text-p,{
                        height:25px;
                    }
                    .input-code{
                        width:75%;
                    }
                    .tiles-space {
                        width: 59vw;
                       }
                       .parent-button{
                        top: 75%;
                    }

                }
                @media only screen and (min-width: 1200px){
                    .title,.sub-title{
                        font-size:20px;
                        padding:auto;
                        margin:auto;
                    }
                    .full-height{
                        // height: 100%;
                    }

                    .btn-class,.select,.generate-p{
                        font-size:13px;
                    }
                    .select,.text-p,{
                        height:25px;
                    }
                    .input{
                        height:45px;
                    }
                    .tiles-space {
                        width: 57vw;
                       }

                       .parent-button{
                        top: 75%;
                    }
                }
                @media only screen and (min-width: 1280px){
                    .title,.sub-title{
                        font-size:20px;
                        padding:auto;
                        margin:auto;
                    }
                    .bg{
                        // height:570px;
                    }
                    .full-height{
                        // height: 90%;
                        margin:45px 45px 0px 45px;
                        padding:35px 35px 0px 35px;
                        overflow:scroll;
                        scrollbar-width: none;
                    }
                    .w-100.input.h-100{
                        margin-left:75px;
                    }
                    .input-code{
                        width:75%;
                    }

                    p.generate-p {
                        position: absolute;
                        right: 60%;
                    }
                    .btn-class,.select,.generate-p{
                        font-size:15px;
                    }
                    .select,.text-p,{
                        height:25px;
                    }
                    .input{
                        max-width:80%;
                        height:45px;

                    }
                    .code-group{
                        grid-gap:2px;
                    }
                    .code-group .code {
                        font-size: 15px;
                        /* border: 1px solid; */
                        width: 30px;
                        height: 30px;
                    }

                    .icon img {
                        width: 50px;
                        /* margin: auto; */
                        margin-left: 20px;
                        margin-top: -28px;
                    }

                    .menu{
                        margin-top:5px
                    }
                    .text-p{
                        padding:15px;
                        width: 200px;
                    }
                    .text-one{

                    }
                    .text-two{
                    margin-top:25px;
                    }
                    .text-three{
                        margin-top:15px;
                    }
                    .tiles-space {
                        width: 56vw;
                       }
                       .parent-button{
                        top: 65%;
                    }
                }
                @media only screen and (min-width: 1300px){
                    .title,.sub-title{
                        font-size:25px;
                        padding:auto;
                        margin:auto;
                    }
                    .full-height{
                        // height: 100%;
                    }

                    .btn-class,.select,.generate-p{
                        font-size:13px;
                    }
                    .select,.text-p,{
                        height:25px;
                    }
                    .tiles-space {
                        width: 56vw;
                       }
                }
                @media only screen and (min-width: 1400px){
                    .full-height{
                        // height: 100%;
                    }

                    .btn-class,.select,.generate-p{
                        font-size:14px;
                    }
                    .select,.text-p,{
                        height:40px;
                    }
                }
                @media only screen and (min-width: 1450px){
                    .full-height{
                        // height: 100%;
                    }

                    .btn-class,.select,.generate-p{
                        font-size:16px;
                    }
                    .select,.text-p,{
                        height:40px;
                    }
                    .icon{
                        font-size:30px;
                    }
                    .input-code{
                        width:60%;
                    }
                    .tiles-space {
                        width: 56vw;
                       }
                       .parent-button{
                        top: 57%;
                    }
                }
                @media only screen and (min-width: 1688px){
                    .full-height{
                        // height: 100%;
                    }

                    .btn-class,.select,.generate-p{
                        font-size:18px

                    }
                    .select,.text-p,{
                        height:35px;
                    }
                    .input{
                        height:60px;
                    }
                    .input-code{
                        width:55%;
                    }
                    .tiles-space {
                        width: 43vw;
                       }
                    .parent-button{
                        top: 50%;
                    }
                }
                @media only screen and (min-width: 2400px){
                    .full-height{
                        // height: 100%

                    }

                    .btn-class,.select,.generate-p{
                        font-size:25px
                        display:flex;
                        justify-content:center;
                        align-item:center;

                    }
                    .select,.text-p,{
                        height:90px;
                    }
                    .input-code{
                        width:45%;
                    }
                    .tiles-space {
                        width: 25vw;
                       }

                       .parent-button{
                        top: 58%;
                    }


                }`}
            </style>
            <div className="bg p-2 h-100">
                <div className="bg-white rounded full-height">
                    <Header />
                    <Body />
                </div>
            </div>
        </div>
    );
}

export default ParentPage;

// const appTarget = document.createElement("div");
// document.body.appendChild(appTarget);

// ReactDOM.render(<ParentPage />, appTarget);
