import Box from "../box/box.jsx";
import React from "react";
import styles from "./gui.css";
import TargetPane from "../../containers/target-pane.jsx";



function CommonTargetpane({open,stageOpen,stageSize,vm}) {
    return (
        <>
            <Box
                id="stage2_vertical"
                className={`${styles.targetWrapper} ${styles.stage2_vertical}`}
                style={{
                    marginLeft: open ? "-2px" : "-590px",
                }}
            >
                <TargetPane stageSize={stageSize} vm={vm} open={open} />
            </Box>

            <Box
                id="stage2_vertical"
                className={`${styles.targetWrapper} ${styles.stage2_vertical}`}
                style={{
                    marginLeft: stageOpen ? "-2px" : "-590px",
                }}
            >
                <TargetPane
                    stageSize={stageSize}
                    vm={vm}
                    stageOpen={stageOpen}
                />
            </Box>
        </>
    );
}

export default CommonTargetpane;