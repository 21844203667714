import React from 'react'
import { Parallax } from "react-parallax";

function ParallaxEffect({ children }, data) {
    
   
    const imageOfPath = localStorage.getItem('imageOfPath')? JSON.parse(localStorage.getItem('imageOfPath')): {}


    return (
        <>
            <Parallax
                strength={-300}
                className={`scroll`}
                bgImage={imageOfPath?.baseString}
                bgImageAlt="images"
                bgImageStyle={{
                    width: "100%",
                    height: "auto",
                    // transition: "0.6s ease-out transform",
                }}
                bgClassName="parallXImage"
            >
                {children}
            </Parallax>
        </>
    )
}

export default ParallaxEffect