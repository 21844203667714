import { drop } from "lodash";
import Blockly from "scratch-blocks";
Blockly.PythonGenerator = new Blockly.Generator("JSON");

// PRECEDENCE
Blockly.PythonGenerator.ORDER_ATOMIC = 0; // 0 "" ...
Blockly.PythonGenerator.ORDER_NEW = 1.1; // new
Blockly.PythonGenerator.ORDER_MEMBER = 1.2; // . []
Blockly.PythonGenerator.ORDER_FUNCTION_CALL = 2; // ()
Blockly.PythonGenerator.ORDER_INCREMENT = 3; // ++
Blockly.PythonGenerator.ORDER_DECREMENT = 3; // --
Blockly.PythonGenerator.ORDER_BITWISE_NOT = 4.1; // ~
Blockly.PythonGenerator.ORDER_UNARY_PLUS = 4.2; // +
Blockly.PythonGenerator.ORDER_UNARY_NEGATION = 4.3; // -
Blockly.PythonGenerator.ORDER_LOGICAL_NOT = 4.4; // !
Blockly.PythonGenerator.ORDER_TYPEOF = 4.5; // typeof
Blockly.PythonGenerator.ORDER_VOID = 4.6; // void
Blockly.PythonGenerator.ORDER_DELETE = 4.7; // delete
Blockly.PythonGenerator.ORDER_AWAIT = 4.8; // await
Blockly.PythonGenerator.ORDER_EXPONENTIATION = 5.0; // **
Blockly.PythonGenerator.ORDER_MULTIPLICATION = 5.1; // *
Blockly.PythonGenerator.ORDER_DIVISION = 5.2; // /
Blockly.PythonGenerator.ORDER_MODULUS = 5.3; // %
Blockly.PythonGenerator.ORDER_SUBTRACTION = 6.1; // -
Blockly.PythonGenerator.ORDER_ADDITION = 6.2; // +
Blockly.PythonGenerator.ORDER_BITWISE_SHIFT = 7; // << >> >>>
Blockly.PythonGenerator.ORDER_RELATIONAL = 8; // < <= > >=
Blockly.PythonGenerator.ORDER_IN = 8; // in
Blockly.PythonGenerator.ORDER_INSTANCEOF = 8; // instanceof
Blockly.PythonGenerator.ORDER_EQUALITY = 9; // == != === !==
Blockly.PythonGenerator.ORDER_BITWISE_AND = 10; // &
Blockly.PythonGenerator.ORDER_BITWISE_XOR = 11; // ^
Blockly.PythonGenerator.ORDER_BITWISE_OR = 12; // |
Blockly.PythonGenerator.ORDER_LOGICAL_AND = 13; // &&
Blockly.PythonGenerator.ORDER_LOGICAL_OR = 14; // ||
Blockly.PythonGenerator.ORDER_CONDITIONAL = 15; // ?:
Blockly.PythonGenerator.ORDER_ASSIGNMENT = 16; // = += -= **= *= /= %= <<= >>= ...
Blockly.PythonGenerator.ORDER_YIELD = 16.5; // yield
Blockly.PythonGenerator.ORDER_COMMA = 17; // ,
Blockly.PythonGenerator.ORDER_NONE = 99;

Blockly.PythonGenerator.init = function (workspace) {
    // Create a dictionary of definitions to be printed before the code.
    this.definitions_ = Object.create(null);
    // Create a dictionary mapping desired function names in definitions_
    // to actual function names (to avoid collisions with user functions).
    this.functionNames_ = Object.create(null);
    // Create a dictionary of all the libraries which would be needed
    this.imports_ = Object.create(null);
    // Dictionary of any extra classes to output
    this.classes_ = Object.create(null);
    // Dictionary of all the globals
    this.globals_ = Object.create(null);
    // Start with the defaults that all the code depends on
    // for(var i = 0; i < this.needImports_.length; i++) {
    //   this.addImport(this.needImports_[i]);
    // }
    if (!this.variableDB_) {
        this.variableDB_ = new Blockly.Names(this.RESERVED_WORDS_);
    } else {
        this.variableDB_.reset();
    }

    var defvars = [];
    // Blockly.VariableTypeEquivalence['Colour'] = ['String'];
    var variables = Blockly.Variables.allVariables(workspace);
    var variables2 = Blockly.Variables.allVariables(workspace);
    //this.blocklyTypes_ = Blockly.Variables.allVariablesTypes(workspace);
    // Make sure all the type variables are pushed.  This is because we
    // Don't return the special function parameters in the allVariables list
    for (var name in variables2) {
        variables.push(name);
    }
    // for (var x = 0; x < variables.length; x++) {
    //   var key = variables[x];
    //   this.variableTypes_[key] = this.mapType(variables2[key]);
    // }
};

//MOTION BLOCKS       ///////////////////////////////////////////////////////////////////////////////////////////
//Move steps
Blockly.PythonGenerator["motion_movesteps"] = function (block) {
    var duration =
        Blockly.PythonGenerator.valueToCode(
            block,
            "STEPS",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `move(${duration})`;
    return code;
};

//Turn right
Blockly.PythonGenerator["motion_turnright"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "DEGREES",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `direction(${value})`;
    return code;
};

// Turn Left
Blockly.PythonGenerator["motion_turnleft"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "DEGREES",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    if (value == 0) {
        var code =
            Blockly.PythonGenerator.INDENT + `direction(${value})`;
    } else {
        var code =
            Blockly.PythonGenerator.INDENT + `direction(-${value})`;
    }

    return code;
};

// Go to target
Blockly.PythonGenerator["motion_goto"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "TO",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `goto(${value})`;
    return code;
};

// Go to x y
Blockly.PythonGenerator["motion_gotoxy"] = function (block) {
    var X =
        Blockly.PythonGenerator.valueToCode(
            block,
            "X",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var Y =
        Blockly.PythonGenerator.valueToCode(
            block,
            "Y",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    // return `x(${X}) \n this.y(${Y}) \n`
    var code = Blockly.PythonGenerator.INDENT + `goto(${X},${Y})`;
    return code;
};

// Glide to target
Blockly.PythonGenerator["motion_glideto"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "SECS",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "TO",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    // return `x(${X}) \n this.y(${Y}) \n`
    var code =
        Blockly.PythonGenerator.INDENT + `glide(${value1},${value2})`;
    return code;
};

// Glide to x y
Blockly.PythonGenerator["motion_glidesecstoxy"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "SECS",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "X",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var value3 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "Y",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";

    var code =
        Blockly.PythonGenerator.INDENT +
        `glide(${value1},${value2},${value3})`;
    return code;
};

// Point in direction
Blockly.PythonGenerator["motion_pointindirection"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "DIRECTION",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `direction(${value})`;
    return code;
};

// Point towards
Blockly.PythonGenerator["motion_pointtowards"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "TOWARDS",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `direction(${value})`;
    return code;
};

// Change x by
Blockly.PythonGenerator["motion_changexby"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "DX",
        Blockly.PythonGenerator.ORDER_ATOMIC
    );
    var field;
    if (value > 0) {
        field = `+= ${value}`;
    }
    if (value < 0) {
        var num = value.toString().split("-");
        field = `-= ${num[1]}`;
    }
    if (value == 0 || null) {
        field = `+= 0`;
    }
    var code = Blockly.PythonGenerator.INDENT + `x ${field}`;
    return code;
};

// Set x to
Blockly.PythonGenerator["motion_setx"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "X",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `x = ${value}`;
    return code;
};

// Change y by
Blockly.PythonGenerator["motion_changeyby"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "DY",
        Blockly.PythonGenerator.ORDER_ATOMIC
    );
    var field;
    if (value > 0) {
        field = `+= ${value}`;
    }
    if (value < 0) {
        var num = value.toString().split("-");
        field = `-= ${num[1]}`;
    }
    if (value == 0 || null) {
        field = `+= 0`;
    }
    var code = Blockly.PythonGenerator.INDENT + `y ${field}`;
    return code;
};

// Set y to
Blockly.PythonGenerator["motion_sety"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "Y",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `y = ${value}`;
    return code;
};

// If on edge, bounce
Blockly.PythonGenerator["motion_ifonedgebounce"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `bounceOnEdge()`;
    return code;
};

// Set rotation style
Blockly.PythonGenerator["motion_setrotationstyle"] = function (block) {
    var value = block.getFieldValue("STYLE");
    var text;
    if (value == "left-right") {
        text = "LEFT_RIGHT";
    } else if (value == "don't rotate") {
        text = "DONT_ROTATE";
    } else if (value == "all around") {
        text = "ALL_AROUND";
    }
    var code =
        Blockly.PythonGenerator.INDENT +
        `rotationStyle = Sprite.RotationStyle.${text}`;
    return code;
};

// X position
Blockly.PythonGenerator["motion_xposition"] = function (block) {
    var code = `x`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Y position
Blockly.PythonGenerator["motion_yposition"] = function (block) {
    var code = `y`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Direction
Blockly.PythonGenerator["motion_direction"] = function (block) {
    var code = `direction`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//MOTION SHadow blocks
Blockly.PythonGenerator["motion_pointtowards_menu"] = function (block) {
    var code;
    var value = block.getFieldValue("TOWARDS");
    if (value == "_mouse_") {
        code = "mouse.x , mouse.y";
    } else {
        code = `sprite.${value}.x ,sprite.${value}.y`;
    }
    var code1 = code;
    return [code1, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["motion_goto_menu"] = function (block) {
    var code;
    var value = block.getFieldValue("TO");
    if (value == "_mouse_") {
        code = "mouse.x , mouse.y";
    } else if (value == "_random_") {
        code = "random(-240, 240), random(-150, 150)";
    } else {
        code = `sprite.${value}.x ,sprite.${value}.y`;
    }
    // var code1 =  Blockly.PythonGenerator.INDENT + code
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["motion_glideto_menu"] = function (block) {
    var code;
    var value = block.getFieldValue("TO");
    if (value == "_mouse_") {
        code = "mouse.x , mouse.y";
    } else if (value == "_random_") {
        code = "random(-240, 240), random(-150, 150)";
    } else {
        code = `sprite.${value}.x ,sprite.${value}.y`;
    }
    // var code1 =  Blockly.PythonGenerator.INDENT + code
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//LOOKS BLOCKS    ////////////////////////////////////////////////////////////////////////////////

// Say for seconds
Blockly.PythonGenerator["looks_sayforsecs"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "MESSAGE",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "SECS",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code =
        Blockly.PythonGenerator.INDENT +
        `sayAndWait("${value}", ${value2})`;
    return code;
};

//Say
Blockly.PythonGenerator["looks_say"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "MESSAGE",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var code = Blockly.PythonGenerator.INDENT + `say("${value}")`;
    return code;
};

// Think for seconds
Blockly.PythonGenerator["looks_thinkforsecs"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "MESSAGE",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "SECS",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code =
        Blockly.PythonGenerator.INDENT +
        `thinkAndWait("${value}", ${value2})`;
    return code;
};

// Think
Blockly.PythonGenerator["looks_think"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "MESSAGE",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var code = Blockly.PythonGenerator.INDENT + `think("${value}")`;
    return code;
};

// Switch costume
Blockly.PythonGenerator["looks_switchcostumeto"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "COSTUME",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `costume=${value}`;

    return code;
};

// Next costume
Blockly.PythonGenerator["looks_nextcostume"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `costumeNumber++ `;

    return code;
};

// Switch backdrop
Blockly.PythonGenerator["looks_switchbackdropto"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "BACKDROP",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var backdrop;
    if (value == `"next backdrop"`) {
        backdrop = "costumeNumber++";
    } else if (value == `"previous backdrop"`) {
        backdrop = "costumeNumber--";
    } else {
        backdrop = `costume = ${value}`;
    }
    var code = Blockly.PythonGenerator.INDENT + backdrop;
    return code;
};

//Next Backdrop
Blockly.PythonGenerator["looks_nextbackdrop"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + "costumeNumber++ ";
    return code;
};

//Change size
Blockly.PythonGenerator["looks_changesizeby"] = function (block) {
    var field;
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "CHANGE",
        Blockly.PythonGenerator.ORDER_NONE
    );
    if (value > 0) {
        field = `+= ${value}`;
    }
    if (value < 0) {
        var num = value.toString().split("-");
        field = `-= ${num[1]}`;
    }
    if (value == 0 || null) {
        field = `+= 0`;
    }
    var code = Blockly.PythonGenerator.INDENT + `size ${field}`;
    return code;
};

// Set size
Blockly.PythonGenerator["looks_setsizeto"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "SIZE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `size = ${value}%`;

    return code;
};

// Change graphic effect
Blockly.PythonGenerator["looks_changeeffectby"] = function (block) {
    var dropdown = block.getFieldValue("EFFECT").toLowerCase();
    var field;
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "CHANGE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    if (value > 0) {
        field = `+= ${value}`;
    }
    if (value < 0) {
        var num = value.toString().split("-");
        field = `-= ${num[1]}`;
    }
    if (value == 0 || null) {
        field = `+= 0`;
    }
    var code =
        Blockly.PythonGenerator.INDENT +
        `effect.${dropdown} ${field}`;
    return code;
};

// Set graphic effect
Blockly.PythonGenerator["looks_seteffectto"] = function (block) {
    var dropdown = block.getFieldValue("EFFECT").toLowerCase();

    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code =
        Blockly.PythonGenerator.INDENT +
        `effect.${dropdown} = ${value}`;
    return code;
};

// Clear graphic effects
Blockly.PythonGenerator["looks_cleargraphiceffects"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + "effects.clear()";
    return code;
};

//Show
Blockly.PythonGenerator["looks_show"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + "visible = true";
    return code;
};

//Hide
Blockly.PythonGenerator["looks_hide"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + "visible = false";
    return code;
};

//Go to layer
Blockly.PythonGenerator["looks_gotofrontback"] = function (block) {
    var dropdown = block.getFieldValue("FRONT_BACK");
    var text;
    if (dropdown == "front") {
        text = "Ahead";
    }
    if (dropdown == "back") {
        text = "Behind";
    }
    var code = Blockly.PythonGenerator.INDENT + `move${text}()`;
    return code;
};

// Go forward/backward layers
Blockly.PythonGenerator["looks_goforwardbackwardlayers"] = function (
    block
) {
    var dropdown = block.getFieldValue("FORWARD_BACKWARD");
    var text;
    if (dropdown == `forward`) {
        text = "Ahead";
    }
    if (dropdown == "backward") {
        text = "Behind";
    }
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code =
        Blockly.PythonGenerator.INDENT + `move${text}(${value})`;

    return code;
};

// Costume number/name
Blockly.PythonGenerator["looks_costumenumbername"] = function (block) {
    var code;
    var value = block.getFieldValue("NUMBER_NAME");
    if (value == "number") {
        value = "Number";
    } else if (value == "name") {
        value = "";
    } else {
        value = value;
    }
    code = `costume${value}`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Backdrop number/name
Blockly.PythonGenerator["looks_backdropnumbername"] = function (block) {
    var code;
    var value = block.getFieldValue("NUMBER_NAME");
    if (value == "number") {
        value = "Number";
    } else if (value == "name") {
        value = "";
    } else {
        value = value;
    }
    code = `costume${value}`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Size
Blockly.PythonGenerator["looks_size"] = function (block) {
    var code = "size";
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//Looks Shadow

Blockly.PythonGenerator["looks_costume"] = function (block) {
    var dropdown = block.getFieldValue("COSTUME");
    var code = `"${dropdown}"`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["looks_backdrops"] = function (block) {
    var dropdown = block.getFieldValue("BACKDROP");
    var code = `"${dropdown}"`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// SOUND
// PLAY UNTIL DONE
Blockly.PythonGenerator['sound_playuntildone'] = function (block) {
    var code = "";
    try {
        var value = Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_NONE
        );
        if (!value) {
            value =
                block.svgPath_.tooltip.childBlocks_[0].inputList[0].fieldRow[0]
                    .value_;
        }
        code =
            Blockly.PythonGenerator.INDENT +
            `playSoundUntilDone("${value}")`;
    } catch (e) {
        code = `playSoundUntilDone("Pop")`;
    }
    return code;

};
// PLAY SOUND
Blockly.PythonGenerator['sound_play'] = function (block) {
    var code = "";
    try {
        var value = Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_NONE
        );
        if (!value) {
            value =
                block.svgPath_.tooltip.childBlocks_[0].inputList[0].fieldRow[0]
                    .value_;
        }
        code =
            Blockly.PythonGenerator.INDENT +
            `startSound("${value}")`;
    } catch (e) {
        code = `startSound("Pop")`;
    }
    return code;
};

// STOP ALL SOUNDS
Blockly.PythonGenerator["sound_stopallsounds"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + "stopAllSounds()";
    return code;
};

// CHANGE EFFECT TO
Blockly.PythonGenerator["sound_changeeffectby"] = function (block) {
    var dropdown = block.getFieldValue("EFFECT").toLowerCase();
    var field;
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    if (value > 0) {
        field = `+= ${value}`;
    }
    if (value < 0) {
        var num = value.toString().split("-");
        field = `-= ${num[1]}`;
    }
    if (value == 0 || null) {
        field = `+= 0`;
    }
    var code =
        Blockly.PythonGenerator.INDENT +
        `audioEffects.${dropdown} ${field}`;
    return code;
};

// SET EFFECT TO
Blockly.PythonGenerator["sound_seteffectto"] = function (block) {
    var dropdown = block.getFieldValue("EFFECT").toLowerCase();
    var field;
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    if (value > 0) {
        field = `+= ${value}`;
    }
    if (value < 0) {
        var num = value.toString().split("-");
        field = `-= ${num[1]}`;
    }
    if (value == 0 || null) {
        field = `+= 0`;
    }
    var code =
        Blockly.PythonGenerator.INDENT +
        `audioEffects.${dropdown} ${field}`;
    return code;
};

// CLEAR SOUND EFFECT
Blockly.PythonGenerator["sound_cleareffects"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + "audioEffects.clear()";
    return code;
};

// CHANGE VOLUME BY
Blockly.PythonGenerator["sound_changevolumeby"] = function (block) {
    var field;
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "VOLUME",
        Blockly.PythonGenerator.ORDER_NONE
    );
    if (value > 0) {
        field = `+= ${value}`;
    }
    if (value < 0) {
        var num = value.toString().split("-");
        field = `-= ${num[1]}`;
    }
    if (value == 0 || null) {
        field = `+= 0`;
    }
    var code =
        Blockly.PythonGenerator.INDENT +
        `audioEffects.volume ${field}`;
    return code;
};

// SET VOLUME TO
Blockly.PythonGenerator["sound_setvolumeto"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "VOLUME",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code =
        Blockly.PythonGenerator.INDENT +
        `audioEffects.volume = ${value}%`;
    return code;
};

Blockly.PythonGenerator["sound_volume"] = function (block) {
    var code = `audioEffects.volume`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//SENSING BLOCKS
Blockly.PythonGenerator["sensing_touchingobject"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "TOUCHINGOBJECTMENU",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var code = `touching(${value})`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_touchingcolor"] = function (block) {
    var value = block.childBlocks_[0]?.inputList[0]?.fieldRow[0]?.colour_;
    // var value = Blockly.PythonGenerator.valueToCode(
    //     block,
    //     "COLOR",
    //     Blockly.PythonGenerator.ORDER_NONE
    // );
    var code = `touching(Color.hex("${value}"))`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_coloristouchingcolor"] = function (block) {
    var color1 = block.childBlocks_[0]?.inputList[0]?.fieldRow[0]?.colour_;
    var color2 = block.childBlocks_[1]?.inputList[0]?.fieldRow[0]?.colour_;
    var code = `colorTouching(Color.hex("${color1}"), Color.hex("${color2}"))`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_distanceto"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "DISTANCETOMENU",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var code =
        Blockly.PythonGenerator.INDENT + `distanceTo(${value})`;
    return code;
};

Blockly.PythonGenerator["sensing_askandwait"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "QUESTION",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var code =
        Blockly.PythonGenerator.INDENT + `askAndWait("${value}")`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_answer"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `answer`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_of"] = function (block) {
    var data;
    var field = block.getFieldValue("PROPERTY");
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "OBJECT",
        Blockly.PythonGenerator.ORDER_NONE
    );

    switch (field) {
        case "y position":
            data = "y";
            break;
        case "x position":
            data = "x";
            break;
        case "direction":
            data = "direction";
            break;
        case "costume #":
            data = "costume";
            break;
        case "costume name":
            data = "costumeName";
            break;
        case "size":
            data = "size";
            break;
        case "volume":
            data = "volume";
            break;
        case "backdrop #":
            data = "backdrop";
            break;
        case "backdrop name":
            data = "backdropName";
            break;
        default:
            data = field;
            break;
    }
    var code = `${value}.${data}`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//

Blockly.PythonGenerator["sensing_touchingobjectmenu"] = function (block) {
    var code;
    var value = block.getFieldValue("TOUCHINGOBJECTMENU");
    if (value == "_mouse_") {
        code = "mouse.x , mouse.y";
    } else if (value == "_edge_") {
        code = "edge";
    } else {
        code = `sprite.${value}`;
    }
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_distancetomenu"] = function (block) {
    var code;
    var value = block.getFieldValue("DISTANCETOMENU");
    if (value == "_mouse_") {
        code = "mouse.x , mouse.y";
    } else {
        code = `sprite.${value}`;
    }
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_mousex"] = function (block) {
    var code = `mouse.x`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_mousey"] = function (block) {
    var code = `mouse.y`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_mousedown"] = function (block) {
    var code = `mouse.down`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_resettimer"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `restartTimer()`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_loudness"] = function (block) {
    var code = `loudness`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_timer"] = function (block) {
    var code = `timer`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_keypressed"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "KEY_OPTION",
        Blockly.PythonGenerator.ORDER_ATOMIC
    );
    var code = `keyPressed("${value}")`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
//Sensing Shadow
Blockly.PythonGenerator["sensing_of_object_menu"] = function (block) {
    var value = block.getFieldValue("OBJECT");
    if (value == "_stage_") {
        value = "stage";
    } else {
        value = `sprite("${value}")`;
    }
    var code = `${value}`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_keyoptions"] = function (block) {
    var value = block.getFieldValue("KEY_OPTION");
    var code = `${value}`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["sensing_dayssince2000"] = function (block) {
    var code = `new Date().getTime()`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_setdragmode"] = function (block) {
    var value = block.inputList[0]?.fieldRow[1]?.value_;
    var code =
        Blockly.PythonGenerator.INDENT +
        `setDragMode("${value === "draggable"}")`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

Blockly.PythonGenerator["sensing_username"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `userName`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//CONTROL BLOCKS
Blockly.PythonGenerator["control_wait"] = function (block) {
    var duration = Blockly.PythonGenerator.valueToCode(
        block,
        "DURATION",
        Blockly.PythonGenerator.ORDER_ATOMIC
    );
    var code = Blockly.PythonGenerator.INDENT + `wait(${duration})`;
    return code;
};
Blockly.PythonGenerator["control_repeat"] = function (block) {
    // Repeat n times.
    var repeats =
        Blockly.PythonGenerator.valueToCode(
            block,
            "TIMES",
            Blockly.PythonGenerator.ORDER_ASSIGNMENT
        ) || "0";

    var branch = Blockly.PythonGenerator.statementToCode(block, "SUBSTACK");
    branch = Blockly.PythonGenerator.addLoopTrap(branch, block.id);

    var code =
        Blockly.PythonGenerator.INDENT +
        "for i" +
        " in range() " +
        repeats +
        "): " +
        "\n" +
        branch +
        "\n";
    return code;
};
Blockly.PythonGenerator["control_forever"] = function (block) {
    // var branch;
    // var define_blocks =  block.getInputTargetBlock('SUBSTACK');
    // console.log("defineblock",define_blocks)
    // if (define_blocks)
    //     do {
    //        branch =  Blockly.PythonGenerator.blockToCode(define_blocks);
    //     } while (define_blocks = define_blocks.getNextBlock());

    var branch = Blockly.PythonGenerator.statementToCode(block, "SUBSTACK");
    branch = Blockly.PythonGenerator.addLoopTrap(branch, block.id);
    var code =
        Blockly.PythonGenerator.INDENT +
        "while(true):\n" +
        branch +
        Blockly.PythonGenerator.INDENT +
        "\n";
    return code;
};
Blockly.PythonGenerator["control_repeat_until"] = function (block) {
    let n = 0;
    let code = "";
    if (Blockly.PythonGenerator.STATEMENT_PREFIX) {
        // Automatic prefix insertion is switched off for this block.  Add manually.
        code += Blockly.PythonGenerator.injectId(
            Blockly.PythonGenerator.STATEMENT_PREFIX,
            block
        );
    }
    do {
        const conditionCode =
            Blockly.PythonGenerator.valueToCode(
                block,
                "CONDITION",
                Blockly.PythonGenerator.ORDER_NONE
            ) || "false";
        let branchCode = Blockly.PythonGenerator.statementToCode(
            block,
            "SUBSTACK"
        );
        if (Blockly.PythonGenerator.STATEMENT_SUFFIX) {
            branchCode =
                Blockly.PythonGenerator.prefixLines(
                    Blockly.PythonGenerator.injectId(
                        Blockly.PythonGenerator.STATEMENT_SUFFIX,
                        block
                    ),
                    Blockly.PythonGenerator.INDENT
                ) + branchCode;
        }
        code +=
            Blockly.PythonGenerator.INDENT +
            "while (" +
            conditionCode +
            "):\n" +
            branchCode +
            "\n";
        n++;
    } while (block.getInput("CONDITION" + n));

    // if (block.getInput('ELSE') || Blockly.PythonGenerator.STATEMENT_SUFFIX) {
    //   let branchCode = Blockly.PythonGenerator.statementToCode(block, 'ELSE');
    //   if (Blockly.PythonGenerator.STATEMENT_SUFFIX) {
    //     branchCode = Blockly.PythonGenerator.prefixLines(
    //         Blockly.PythonGenerator.injectId(Blockly.PythonGenerator.STATEMENT_SUFFIX, block),
    //         Blockly.PythonGenerator.INDENT) +
    //         branchCode;
    //   }
    //   code += ' else {\n' + branchCode + '}';
    // }
    return code;
};
Blockly.PythonGenerator["control_if"] = function (block) {
    let n = 0;
    let code = "";

    do {
        const conditionCode = Blockly.PythonGenerator.valueToCode(
            block,
            "CONDITION",
            Blockly.PythonGenerator.ORDER_NONE
        );
        let branchCode = Blockly.PythonGenerator.statementToCode(
            block,
            "SUBSTACK"
        );
        code +=
            Blockly.PythonGenerator.INDENT +
            "if " +
            conditionCode +
            ":\n" +
            branchCode +
            "\n";
        if (Blockly.PythonGenerator.STATEMENT_PREFIX) {
            // Automatic prefix insertion is switched off for this block.  Add manually.
            code += Blockly.PythonGenerator.injectId(
                Blockly.PythonGenerator.STATEMENT_PREFIX,
                block
            );
        }
        if (Blockly.PythonGenerator.STATEMENT_SUFFIX) {
            branchCode =
                Blockly.PythonGenerator.prefixLines(
                    Blockly.PythonGenerator.injectId(
                        Blockly.PythonGenerator.STATEMENT_SUFFIX,
                        block
                    ),
                    Blockly.PythonGenerator.INDENT
                ) + branchCode;
            code +=
                Blockly.PythonGenerator.INDENT +
                "if " +
                conditionCode +
                ":\n" +
                branchCode +
                "\n";
        }

        n++;
    } while (block.getInput("CONDITION" + n));
    return code;
};

Blockly.PythonGenerator["control_if_else"] = function (block) {
    let n = 0;
    let code = "";

    do {
        const conditionCode = Blockly.PythonGenerator.valueToCode(
            block,
            "CONDITION",
            Blockly.PythonGenerator.ORDER_NONE
        );
        let branchCode1 = Blockly.PythonGenerator.statementToCode(
            block,
            "SUBSTACK"
        );
        let branchCode2 = Blockly.PythonGenerator.statementToCode(
            block,
            "SUBSTACK2"
        );
        code +=
            Blockly.PythonGenerator.INDENT +
            "if " +
            conditionCode +
            ":\n" +
            branchCode1 +
            "\n \n else" +
            "\n" +
            branchCode2 +
            "\n";
        if (Blockly.PythonGenerator.STATEMENT_PREFIX) {
            // Automatic prefix insertion is switched off for this block.  Add manually.
            code += Blockly.PythonGenerator.injectId(
                Blockly.PythonGenerator.STATEMENT_PREFIX,
                block
            );
        }
        if (Blockly.PythonGenerator.STATEMENT_SUFFIX) {
            branchCode1 =
                Blockly.PythonGenerator.prefixLines(
                    Blockly.PythonGenerator.injectId(
                        Blockly.PythonGenerator.STATEMENT_SUFFIX,
                        block
                    ),
                    Blockly.PythonGenerator.INDENT
                ) + branchCode1;
            branchCode2 =
                Blockly.PythonGenerator.prefixLines(
                    Blockly.PythonGenerator.injectId(
                        Blockly.PythonGenerator.STATEMENT_SUFFIX,
                        block
                    ),
                    Blockly.PythonGenerator.INDENT
                ) + branchCode2;
            code +=
                Blockly.PythonGenerator.INDENT +
                "if " +
                conditionCode +
                ":\n" +
                branchCode1 +
                "\n \n else" +
                "\n" +
                branchCode2 +
                "\n";
        }

        n++;
    } while (block.getInput("CONDITION" + n));

    return code;
};

Blockly.PythonGenerator["control_wait_until"] = function (block) {
    const conditionCode = Blockly.PythonGenerator.valueToCode(
        block,
        "CONDITION",
        Blockly.PythonGenerator.ORDER_NONE
    );

    var code =
        Blockly.PythonGenerator.INDENT +
        `while (!(${conditionCode})):  wait(0.1) `;
    return code;
};

Blockly.PythonGenerator["control_start_as_clone"] = function (block) {
    // var value = Blockly.PythonGenerator.valueToCode(block, 'CLONE_OPTION',Blockly.PythonGenerator.ORDER_NONE);
    var code = Blockly.PythonGenerator.INDENT + `onClone()`;
    return code;
};

Blockly.PythonGenerator["control_create_clone_of"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "CLONE_OPTION",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var code =
        Blockly.PythonGenerator.INDENT +
        `sprite${value}.createClone()`;
    return code;
};
//clone shadow
Blockly.PythonGenerator["control_create_clone_of_menu"] = function (block) {
    var code;
    var value = block.getFieldValue("CLONE_OPTION");
    if (value == "_myself_") {
        code = "";
    } else {
        code = `["${value}"]`;
    }
    return [code, Blockly.PythonGenerator.ORDER_NONE];
};
Blockly.PythonGenerator["control_delete_this_clone"] = function (block) {
    var value = Blockly.PythonGenerator.valueToCode(
        block,
        "CLONE_OPTION",
        Blockly.PythonGenerator.ORDER_NONE
    );
    var code = Blockly.PythonGenerator.INDENT + `deleteThisClone()`;
    return code;
};
Blockly.PythonGenerator["control_stop"] = function (block) {
    var value = block.getFieldValue("STOP_OPTION");
    var code =
        Blockly.PythonGenerator.INDENT + `terminate("${value}")`;
    return code;
};

//EVENTS BLOCK
Blockly.PythonGenerator["event_whenflagclicked"] = function (block) {
    var code = "def flagClicked ():";
    return code;
};
Blockly.PythonGenerator["event_whenkeypressed"] = function (block) {
    // Trigger(Trigger.KEY_PRESSED, { key: "space" }, this.myScript)
    var value = block.inputList[0]?.fieldRow[1]?.value_;
    var code = `function ${value.replaceAll(" ", "_")}_pressed ():`;
    // var code = `Trigger(Trigger.KEY_PRESSED, { key: ${value}},{`;
    return code;
};
Blockly.PythonGenerator["event_whenthisspriteclicked"] = function (block) {
    var code = "function spriteClicked ():";
    return code;
};
Blockly.PythonGenerator["event_whenbackdropswitchesto"] = function (block) {
    // Trigger(Trigger.KEY_PRESSED, { key: "space" }, this.myScript)
    var value = block.inputList[0]?.fieldRow[1]?.value_;
    var code = `function when_backdrop_swithes_to_${value.replaceAll(
        " ",
        "_"
    )} ():`;
    // var code = `Trigger(Trigger.KEY_PRESSED, { key: ${value}},{`;
    return code;
};
Blockly.PythonGenerator["event_whengreaterthan"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_ATOMIC
        ) || "0";
    var key = block.inputList[0]?.fieldRow[1]?.value_;
    var code = `if(${key} > ${value}): \n function greaterThan():`;
    return code;
};
Blockly.PythonGenerator["event_whenbroadcastreceived"] = function (block) {
    // Trigger(Trigger.KEY_PRESSED, { key: "space" }, this.myScript)
    var value = block.inputList[0]?.fieldRow[1]?.text_;
    var code = `function when__${value.replaceAll(" ", "_")}_recieved ():`;
    // var code = `Trigger(Trigger.KEY_PRESSED, { key: ${value}},{`;
    return code;
};
Blockly.PythonGenerator["event_broadcast"] = function (block) {
    var value = block.childBlocks_[0]?.inputList[0]?.fieldRow[0]?.text_;
    var code =
        Blockly.PythonGenerator.INDENT + `broadcast("${value}")`;
    return code;
};
Blockly.PythonGenerator["event_broadcastandwait"] = function (block) {
    var value = block.childBlocks_[0]?.inputList[0]?.fieldRow[0]?.text_;
    var code =
        Blockly.PythonGenerator.INDENT +
        `broadcastAndWait("${value}")`;
    return code;
};

//OPERATORS      ////////////////////////////////////////////////////////////////////////////////////

// Add (+)
Blockly.PythonGenerator["operator_add"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1}+${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Subtract (-)
Blockly.PythonGenerator["operator_subtract"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1}-${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Multiply (×)
Blockly.PythonGenerator["operator_multiply"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1}*${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Divide (÷)
Blockly.PythonGenerator["operator_divide"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1}/${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Pick random
Blockly.PythonGenerator["operator_random"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "FROM",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "TO",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `random(${value1},${value2})`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Greater than (>)
Blockly.PythonGenerator["operator_gt"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1} > ${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Less than (<)
Blockly.PythonGenerator["operator_lt"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1} < ${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Equal (=)
Blockly.PythonGenerator["operator_equals"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1}==${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// And
Blockly.PythonGenerator["operator_and"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1} && ${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Pen
Blockly.PythonGenerator["pen_clear"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `clearPen()`;
    return code;
};

Blockly.PythonGenerator["pen_stamp"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `stamp()`;
    return code;
};
Blockly.PythonGenerator["pen_penDown"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `penDown = true`;
    return code;
};
Blockly.PythonGenerator["pen_penUp"] = function (block) {
    var code = Blockly.PythonGenerator.INDENT + `penDown = false`;
    return code;
};
Blockly.PythonGenerator["pen_changePenSizeBy"] = function (block) {

    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "SIZE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = Blockly.PythonGenerator.INDENT + `penSize = penSize + ${value}`;
    return code;
};
Blockly.PythonGenerator["pen_setPenSizeTo"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "SIZE",
            Blockly.PythonGenerator.ORDER_NONE
        );
    var code =
        Blockly.PythonGenerator.INDENT +
        `penSize = ${value}`;
    return code;
};

Blockly.PythonGenerator["pen_setPenColorToColor"] = function (block) {
    var value = block.childBlocks_[0]?.inputList[0]?.fieldRow[0]?.colour_;
    var code = Blockly.JavaScriptGenerator.INDENT + `penColor = Color.hex("${value}")`;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};
Blockly.PythonGenerator["pen_changePenColorParamBy"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var dropdown = block.childBlocks_[0]?.inputList[0]?.fieldRow[0]?.text_;
    var code =
        Blockly.PythonGenerator.INDENT +
        `penColor.${dropdown} = penColor.${dropdown} + ${value}`;
    return code;
};
Blockly.PythonGenerator["pen_setPenColorParamTo"] = function (block) {
    var value =
        Blockly.PythonGenerator.valueToCode(
            block,
            "VALUE",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var dropdown = block.childBlocks_[0]?.inputList[0]?.fieldRow[0]?.text_;
    var code =
        Blockly.PythonGenerator.INDENT +
        `penColor.${dropdown} = ${value}`;
    return code;
};

// Or
Blockly.PythonGenerator["operator_or"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1} | ${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Not
Blockly.PythonGenerator["operator_not"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "OPERAND",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `!${value1}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//join
Blockly.PythonGenerator["operator_join"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "STRING1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "STRING2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "";
    var code = `"${value1}" + "${value2}"`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Letterof string
Blockly.PythonGenerator["operator_letter_of"] = function (block) {
    var num;
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "LETTER",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    if (value1 > 0) {
        num = value1 - 1;
    } else {
        num = value1;
    }
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "STRING",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "";
    var code = `"${value2}"[${num}]`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//Length of strings
Blockly.PythonGenerator["operator_length"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "STRING",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "";
    var code = `"${value1}".length`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//COntains string
Blockly.PythonGenerator["operator_contains"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "STRING1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "STRING2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "";
    var code = `"${value1}".includes("${value2}")`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//operator mod
Blockly.PythonGenerator["operator_mod"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM1",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var value2 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM2",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `${value1} % ${value2}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//Round
Blockly.PythonGenerator["operator_round"] = function (block) {
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var code = `Math.round(${value1})`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//Math of
Blockly.PythonGenerator["operator_mathop"] = function (block) {
    var value = block.getFieldValue("OPERATOR");
    var value1 =
        Blockly.PythonGenerator.valueToCode(
            block,
            "NUM",
            Blockly.PythonGenerator.ORDER_NONE
        ) || "0";
    var func;
    switch (value) {
        case "sin":
            func = `math.sin(degToRad(${value1}))`;
            break;
        case "cos":
            func = `math.cos(degToRad(${value1}))`;
            break;
        case "tan":
            func = `math.tan(degToRad(${value1}))`;
            break;
        case "asin":
            func = `radToDeg(math.asin(${value1}))`;
            break;
        case "acos":
            func = `radToDeg(math.acos(${value1}))`;
            break;
        case "atan":
            func = `radToDeg(math.atan(${value1}))`;
            break;
        case "ceiling":
            func = `math.ceil(${value1})`;
            break;
        case "ln":
            func = `math.log(${value1})`;
            break;
        case "log":
            func = `math.log10(${value1})`;
            break;
        case "e ^":
            func = `math.E ** (${value1})`;
            break;
        case "10 ^":
            func = `math.10 ** (${value1})`;
            break;
        default:
            func = `math.${value}(${value1})`;
            break;
    }

    var code = `${func}`;

    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

//SHADOW type TEXT       ////////////////////////////////////////////
Blockly.PythonGenerator["text"] = function (block) {
    var code;
    var value = block.getFieldValue("TEXT");
    code = value;
    return [code, Blockly.PythonGenerator.ORDER_ATOMIC];
};

// Math Block to code    //////////////////////////////////////
Blockly.PythonGenerator["math_number"] = function (block) {
    // Numeric value.
    var code = parseFloat(block.getFieldValue("NUM"));
    var order;
    if (code == Infinity) {
        code = 'float("inf")';
        order = Blockly.PythonGenerator.ORDER_FUNCTION_CALL;
    } else if (code == -Infinity) {
        code = '-float("inf")';
        order = Blockly.PythonGenerator.ORDER_UNARY_SIGN;
    } else {
        order =
            code < 0
                ? Blockly.PythonGenerator.ORDER_UNARY_SIGN
                : Blockly.PythonGenerator.ORDER_ATOMIC;
    }
    return [code, order];
};
Blockly.PythonGenerator["math_positive_number"] = function (block) {
    // Numeric value.
    var code = parseFloat(block.getFieldValue("NUM"));
    var order;
    if (code == Infinity) {
        code = 'float("inf")';
        order = Blockly.PythonGenerator.ORDER_FUNCTION_CALL;
    } else if (code == -Infinity) {
        code = '-float("inf")';
        order = Blockly.PythonGenerator.ORDER_UNARY_SIGN;
    } else {
        order =
            code < 0
                ? Blockly.PythonGenerator.ORDER_UNARY_SIGN
                : Blockly.PythonGenerator.ORDER_ATOMIC;
    }
    return [code, order];
};
Blockly.PythonGenerator["math_integer"] = function (block) {
    // Numeric value.
    var code = parseFloat(block.getFieldValue("NUM"));
    var order;
    if (code == Infinity) {
        code = 'float("inf")';
        order = Blockly.PythonGenerator.ORDER_FUNCTION_CALL;
    } else if (code == -Infinity) {
        code = '-float("inf")';
        order = Blockly.PythonGenerator.ORDER_UNARY_SIGN;
    } else {
        order =
            code < 0
                ? Blockly.PythonGenerator.ORDER_UNARY_SIGN
                : Blockly.PythonGenerator.ORDER_ATOMIC;
    }
    return [code, order];
};
Blockly.PythonGenerator["math_whole_number"] = function (block) {
    // Numeric value.
    var code = parseFloat(block.getFieldValue("NUM"));
    var order;
    if (code == Infinity) {
        code = 'float("inf")';
        order = Blockly.PythonGenerator.ORDER_FUNCTION_CALL;
    } else if (code == -Infinity) {
        code = '-float("inf")';
        order = Blockly.PythonGenerator.ORDER_UNARY_SIGN;
    } else {
        order =
            code < 0
                ? Blockly.PythonGenerator.ORDER_UNARY_SIGN
                : Blockly.PythonGenerator.ORDER_ATOMIC;
    }
    return [code, order];
};
Blockly.PythonGenerator["math_angle"] = function (block) {
    // Numeric value.
    var code = parseFloat(block.getFieldValue("NUM"));
    var order;
    if (code == Infinity) {
        code = 'float("inf")';
        order = Blockly.PythonGenerator.ORDER_FUNCTION_CALL;
    } else if (code == -Infinity) {
        code = '-float("inf")';
        order = Blockly.PythonGenerator.ORDER_UNARY_SIGN;
    } else {
        order =
            code < 0
                ? Blockly.PythonGenerator.ORDER_UNARY_SIGN
                : Blockly.PythonGenerator.ORDER_ATOMIC;
    }
    return [code, order];
};
Blockly.PythonGenerator.scrub_ = function (block, code, opt_thisOnly) {
    const nextBlock =
        block.nextConnection && block.nextConnection.targetBlock();
    if (nextBlock && !opt_thisOnly) {
        return code + "\n" + Blockly.PythonGenerator.blockToCode(nextBlock);
    }
    return code;
};
Blockly.PythonGenerator.INFINITE_LOOP_TRAP = null;

export default Blockly.PythonGenerator;
