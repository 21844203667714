import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import {
    injectIntl,
    intlShape,
    defineMessages,
} from "react-intl";
import classNames from "classnames";
import omit from "lodash.omit";
import MediaQuery from "react-responsive";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import tabStyles from "react-tabs/style/react-tabs.css";
import VM from "scratch-vm";
import Renderer from "scratch-render";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
    googlecode as theme,
    tomorrowNight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button, Tooltip } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import SettingsIcon from "@mui/icons-material/Settings";
import { Loop, SwitchAccount, VolumeUp } from "@mui/icons-material";

import Blocks from "../../containers/blocks.jsx";
import CostumeTab from "../../containers/costume-tab.jsx";
import SoundTab from "../../containers/sound-tab.jsx";
import StageWrapper from "../../containers/stage-wrapper.jsx";
import Loader from "../loader/loader.jsx";
import Box from "../box/box.jsx";
import MenuBar from "../menu-bar/menu-bar.jsx";
import CostumeLibrary from "../../containers/costume-library.jsx";
import BackdropLibrary from "../../containers/backdrop-library.jsx";
import Watermark from "../../containers/watermark.jsx";
import WebGlModal from "../../containers/webgl-modal.jsx";
import TipsLibrary from "../../containers/tips-library.jsx";
import Cards from "../../containers/cards.jsx";
import Alerts from "../../containers/alerts.jsx";
import DragLayer from "../../containers/drag-layer.jsx";
import ConnectionModal from "../../containers/connection-modal.jsx";
import ThemeButton from "../theme-button/ThemeButton.js";
import TelemetryModal from "../telemetry-modal/telemetry-modal.jsx";

import layout, { STAGE_SIZE_MODES } from "../../lib/layout-constants";
import { resolveStageSize } from "../../lib/screen-utils";
import { getAssets } from "../../reducers/assets-data.js";
import { getCode } from "../../reducers/code-editor.js";
import { useDriver } from "./useDriver";

import styles from "./gui.css";
import addExtensionIcon from "./icon--extensions.svg";
import codeIcon from "../../img/01.svg";
import codeIconBlue from "../../img/11.svg";
import block from "../../img/code.svg";
import blockBlue from "../../img/codeblue.svg";
import CommonTargetpane from "./common-target-pane.js";

const messages = defineMessages({
    addExtension: {
        id: "gui.gui.addExtension",
        description: "Button to add an extension in the target pane",
        defaultMessage: "Add Extension",
    },
});

const projectHost = window.location.host.toString().split(":");
const hostlink = projectHost[1]
    ? window.location.host
    : window.location.hostname;

// Cache this value to only retrieve it once the first time.
// Assume that it doesn't change for a session.
let isRendererSupported = null;
let t = undefined;
let timer = undefined;

const GUIComponent = (props) => {
    const dispatch = useDispatch();
    const currentLang = useSelector(
        (state) => state.scratchGui.assets.currentLang
    );
    const jsLang = useSelector(
        (state) => state.scratchGui.assets.javascriptCode
    );
    const pythonLang = useSelector(
        (state) => state.scratchGui.assets.pythonCode
    );
    const cSharpLang = useSelector(
        (state) => state.scratchGui.assets.cSharpCode
    );
    const luaLang = useSelector((state) => state.scratchGui.assets.luaCode);
    const goLang = useSelector((state) => state.scratchGui.assets.goCode);
    const [code, setCode] = useState("");
    const [isCodeClick, setIsCodeClick] = useState(false);
    const [tourCompleted, setTourCompleted] = useState(false);
    const [onCostumeClick, setOnCostumeClick] = useState(false);
    const [onVolumeClick, setOnVolumeClick] = useState(false);
    const resizeEditor = useSelector(
        (state) => state.scratchGui.codeEditor.resizeEditor
    );
    const gameObjective = useSelector(
        (state) => state.scratchGui?.gameInfo?.gameObjective
    );
    const gameHintAndTips = useSelector(
        (state) => state.scratchGui?.gameInfo?.gameHintAndTips
    );
    const selectedLang = useSelector((state) => state.locales.locale);

    const {
        accountNavOpen,
        activeTabIndex,
        alertsVisible,
        authorId,
        authorThumbnailUrl,
        authorUsername,
        basePath,
        backdropLibraryVisible,
        backpackHost,
        backpackVisible,
        blocksTabVisible,
        cardsVisible,
        canChangeLanguage,
        canCreateNew,
        canEditTitle,
        canManageFiles,
        canRemix,
        canSave,
        canCreateCopy,
        canShare,
        canUseCloud,
        children,
        connectionModalVisible,
        costumeLibraryVisible,
        costumesTabVisible,
        enableCommunity,
        intl,
        isCreating,
        isFullScreen,
        isPlayerOnly,
        isRtl,
        isShared,
        isTelemetryEnabled,
        loading,
        logo,
        renderLogin,
        onClickAbout,
        onClickAccountNav,
        onCloseAccountNav,
        onLogOut,
        onOpenRegistration,
        onToggleLoginOpen,
        onActivateCostumesTab,
        onActivateSoundsTab,
        onActivateTab,
        onClickLogo,
        onExtensionButtonClick,
        onProjectTelemetryEvent,
        onRequestCloseBackdropLibrary,
        onRequestCloseCostumeLibrary,
        onRequestCloseTelemetryModal,
        onSeeCommunity,
        onShare,
        onShowPrivacyPolicy,
        onStartSelectingFileUpload,
        onTelemetryModalCancel,
        onTelemetryModalOptIn,
        onTelemetryModalOptOut,
        showComingSoon,
        soundsTabVisible,
        stageSizeMode,
        targetIsStage,
        telemetryModalVisible,
        tipsLibraryVisible,
        vm,
        ...componentProps
    } = omit(props, "dispatch");

    if (children) {
        return <Box {...componentProps}>{children}</Box>;
    }

    const driverInstance = useDriver();
    const tabClassNames = {
        tabs: styles.tabs,
        tab: classNames(tabStyles.reactTabsTab, styles.tab),
        tabList: classNames(tabStyles.reactTabsTabList, styles.tabList),
        tabPanel: classNames(tabStyles.reactTabsTabPanel, styles.tabPanel),
        tabPanelSelected: classNames(
            tabStyles.reactTabsTabPanelSelected,
            styles.isSelected
        ),
        tabSelected: classNames(
            tabStyles.reactTabsTabSelected,
            styles.isSelected
        ),
    };

    if (isRendererSupported === null) {
        isRendererSupported = Renderer.isSupported();
    }

    // #region Resizing Panel
    const [isResizingHoriz, setIsResizingHoriz] = useState(false);
    const [isResizingVertical, setResizingVertical] = useState(false);
    const [xMouseLast, setXMouseLast] = useState(-1);
    const [yMouseLast, setYMouseLast] = useState(-1);
    const [xMouseNew, setXMouseNew] = useState(-1);
    const [yMouseNew, setYMouseNew] = useState(-1);
    const [newLeftWidthPct, setNewLeftWidthPct] = useState("");
    const [newTopHeightPct, setNewTopHeightPct] = useState("");
    const [showBlock, setShowBlock] = useState(true);
    const [currentLanguage, setCurrentLanguage] = useState("javascript");
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const [stageOpen, setStageOpen] = useState();
    const stageToggle = () => setStageOpen(!stageOpen);

    const [isOpen, setIsOpen] = useState(true);
    const [isDarkMode, setDarkMode] = useState(false);
    const [flip, setFlip] = useState(false);

    const resizeToggle = () => {
        dispatch(getCode("setResizeEditor", !resizeEditor));
    };

    const handleMouseDown = (evt) => {
        setIsResizingHoriz(true);
        setXMouseLast(evt.clientX);
        setXMouseNew(evt.clientX);
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseDownVertical = (evt) => {
        setResizingVertical(true);
        setYMouseLast(evt.clientY);
        setYMouseNew(evt.clientY);
        window.addEventListener("mousemove", handleMouseMoveUp);
        window.addEventListener("mouseup", handleMousDown);
    };

    useEffect(() => {
        const bZoom = document.getElementsByClassName("blocklyZoom");
        const wScroll = document.getElementsByClassName(
            "blocklyMainWorkspaceScrollbar"
        );

        const v = wScroll[0].style.transform;
        const vy = v.split(",")[1].split("px")[0].trim();

        const h = wScroll[1].style.transform;
        const hy = h.split(",")[1].split("px")[0].trim();

        const trans = bZoom[0].getAttribute("transform").split(",");
        const y = trans[1].split(")")[0];

        if (bZoom.length && xMouseNew != -1) {
            clearTimeout(t);
            t = setTimeout(() => {
                bZoom[0].setAttribute(
                    "transform",
                    `translate(${newLeftWidthPct * window.innerWidth * 0.01 - 125
                    },${y})`
                );
                wScroll[0].style.transform = `translate(${newLeftWidthPct * window.innerWidth * 0.01 - 80
                    }px,${vy}px)`;
                wScroll[1].style.transform = `translate(310.5px,${hy}px)`;
            }, -10000);
        }
    }, [newLeftWidthPct]);

    useEffect(() => {
        if (isResizingHoriz) {
            // Could we set these somewhere else so we don't have to re-retrieve every mouseDown..??
            const leftSide = document.getElementById("stage1");
            const fullLayout = document.getElementById("flex-stages-layout");

            let dx = xMouseNew - xMouseLast; // this.state.
            let fullWidth = fullLayout.getBoundingClientRect().width; // or  leftSide.parentNode.
            let leftWidth = leftSide.getBoundingClientRect().width;
            let newLeftWidthPct = ((leftWidth + dx) / fullWidth) * 100;
            setNewLeftWidthPct(newLeftWidthPct);
            leftSide.style.width = `${newLeftWidthPct}%`;
            setXMouseLast(xMouseNew);
        }
    }, [isResizingHoriz, xMouseNew, xMouseLast]);

    useEffect(() => {
        const topSide = document.getElementById("stage1_vertical");
        const bottomSide = document.getElementById("stage2_vertical");

        let fullHeight = topSide.getBoundingClientRect().height;

        bottomSide.style.height = `calc(${100}% - ${fullHeight + 20}px)`;
    }, []);

    useEffect(() => {
        console.log("isResizingVertical", isResizingVertical);
        if (isResizingVertical) {
            // Could we set these somewhere else so we don't have to re-retrieve every mouseDown..??
            const topSide = document.getElementById("stage1_vertical");
            const bottomSide = document.getElementById("stage2_vertical");
            const fullLayout = document.getElementById(
                "flex-stages-layout-vertical"
            );
            const outputCanvas = document.getElementById("outputCanvas");

            let dx = yMouseNew - yMouseLast; // this.state.
            let fullHeight = fullLayout.getBoundingClientRect().height; // or  leftSide.parentNode.
            let topHeight = topSide.getBoundingClientRect().height;
            let newTopHeightPct = ((topHeight + dx) / fullHeight) * 100;
            setNewTopHeightPct(newTopHeightPct);

            topSide.style.height = `${newTopHeightPct}%`;
            bottomSide.style.height = `calc(${100 - newTopHeightPct}% - 20px)`;
            if (outputCanvas) {
                let height = `${fullHeight * (newTopHeightPct / 100) - 65}px`;

                let width = `${fullHeight * (newTopHeightPct / 100) + 85}px`;
                clearTimeout(timer);
                timer = setTimeout(() => {
                    outputCanvas.setAttribute("height", height);
                    outputCanvas.setAttribute("width", width);
                    outputCanvas.style.height = height;
                    outputCanvas.style.width = width;
                }, 100);
            }
            setYMouseLast(yMouseNew); // same ane okay
        }
    }, [isResizingVertical, yMouseNew, yMouseLast]);

    useEffect(() => {
        if (!isFullScreen) {
            setResizingVertical(true);
            // handleMouseDownVertical({ clientY: yMouseNew + 1 })
            setYMouseNew(yMouseNew + 0.1);
        }
    }, [isFullScreen]);

    useEffect(() => {
        // Check localStorage for a key to determine if the tour has been completed
        const hasCompletedTour = localStorage.getItem("tourCompleted") === "true";

        if (!hasCompletedTour && !tourCompleted && !loading) {
            // If tour is not completed and loading is false, start the tour
            driverInstance?.drive();

            const intervalId = setInterval(() => {
                // Check periodically if the last step of driver.js is reached
                if (driverInstance.isLastStep()) {
                    console.log("Onboarding completed.");
                    clearInterval(intervalId);
                    driverInstance.destroy();
                    localStorage.setItem("tourCompleted", "true");
                    console.log("tourCompleted set in localStorage.");
                    setTourCompleted(true);
                }
            }, 5000); // Adjust interval timing to match your steps

            return () => {
                clearInterval(intervalId);
                driverInstance.destroy();
            };
        }
    }, [loading, tourCompleted]);

    const handleMouseMove = (evt) => {
        setXMouseNew(evt.clientX);
    };
    const handleMouseMoveUp = (evt) => {
        setYMouseNew(evt.clientY);
    };

    const handleMouseUp = () => {
        setIsResizingHoriz(false);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
    };
    const handleMousDown = () => {
        setResizingVertical(false);
        window.removeEventListener("mousemove", handleMouseMoveUp);
        window.removeEventListener("mouseup", handleMousDown);
    };

    // #endregion

    const FormatTextContent = ({ content }) => {
        return content?.map((text, index) => (
            <div key={index}>
                <p>{text}</p>
            </div>
        ));
    };

    const removeQuotes = (text) => text.replace(/"([^"]+(?="))"/g, "$1");

    const formattedContent = (data) => {
        const rawContent = JSON.parse(data || "{}");

        // Access the content specific to the current language and split it by new lines
        const content = rawContent?.[selectedLang === 'el' ? 'gr' : selectedLang]?.split("\n") || [];

        // Remove quotes from each line of text
        const cleanedContent = content.map(removeQuotes);

        // Return the formatted content if it exists, otherwise display a 'No content available' message
        return cleanedContent.length ? (
            <FormatTextContent content={cleanedContent} />
        ) : (
            <p>No content available</p>
        );
    };

    return (
        <MediaQuery minWidth={layout.fullSizeMinWidth}>
            {(isFullSize) => {
                const stageSize = resolveStageSize(stageSizeMode, isFullSize);

                return isPlayerOnly ? (
                    <StageWrapper
                        isFullScreen={isFullScreen}
                        isRendererSupported={isRendererSupported}
                        isRtl={isRtl}
                        loading={loading}
                        stageSize={STAGE_SIZE_MODES.large}
                        vm={vm}
                    >
                        {alertsVisible ? (
                            <Alerts className={styles.alertsContainer} />
                        ) : null}
                    </StageWrapper>
                ) : (
                    <Box
                        className={styles.pageWrapper}
                        dir={isRtl ? "rtl" : "ltr"}
                        {...componentProps}
                    >
                        {telemetryModalVisible ? (
                            <TelemetryModal
                                isRtl={isRtl}
                                isTelemetryEnabled={isTelemetryEnabled}
                                onCancel={onTelemetryModalCancel}
                                onOptIn={onTelemetryModalOptIn}
                                onOptOut={onTelemetryModalOptOut}
                                onRequestClose={onRequestCloseTelemetryModal}
                                onShowPrivacyPolicy={onShowPrivacyPolicy}
                            />
                        ) : null}
                        {loading ? <Loader /> : null}
                        {isCreating ? (
                            <Loader messageId="gui.loader.creating" />
                        ) : null}
                        {isRendererSupported ? null : (
                            <WebGlModal isRtl={isRtl} />
                        )}
                        {tipsLibraryVisible ? <TipsLibrary /> : null}
                        {cardsVisible ? <Cards /> : null}
                        {alertsVisible ? (
                            <Alerts className={styles.alertsContainer} />
                        ) : null}
                        {connectionModalVisible ? (
                            <ConnectionModal vm={vm} />
                        ) : null}
                        {costumeLibraryVisible ? (
                            <CostumeLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseCostumeLibrary}
                            />
                        ) : null}
                        {backdropLibraryVisible ? (
                            <BackdropLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseBackdropLibrary}
                            />
                        ) : null}

                        {/* Display Body Area */}
                        <Box className={`${styles.bodyWrapper}`}>
                            <Box className={styles.bodyContainer}>
                                <MenuBar
                                    accountNavOpen={accountNavOpen}
                                    authorId={authorId}
                                    authorThumbnailUrl={authorThumbnailUrl}
                                    authorUsername={authorUsername}
                                    canChangeLanguage={canChangeLanguage}
                                    canCreateCopy={canCreateCopy}
                                    canCreateNew={canCreateNew}
                                    canEditTitle={canEditTitle}
                                    canManageFiles={canManageFiles}
                                    canRemix={canRemix}
                                    canSave={canSave}
                                    canShare={canShare}
                                    className={styles.menuBarPosition}
                                    enableCommunity={enableCommunity}
                                    isShared={isShared}
                                    logo={logo}
                                    renderLogin={renderLogin}
                                    showComingSoon={showComingSoon}
                                    onClickAbout={onClickAbout}
                                    onClickAccountNav={onClickAccountNav}
                                    onClickLogo={onClickLogo}
                                    onCloseAccountNav={onCloseAccountNav}
                                    onLogOut={onLogOut}
                                    onOpenRegistration={onOpenRegistration}
                                    onProjectTelemetryEvent={
                                        onProjectTelemetryEvent
                                    }
                                    onSeeCommunity={onSeeCommunity}
                                    onShare={onShare}
                                    onStartSelectingFileUpload={
                                        onStartSelectingFileUpload
                                    }
                                    onToggleLoginOpen={onToggleLoginOpen}
                                    flip={flip}
                                    setFlip={setFlip}
                                />
                                <Box
                                    id="flex-stages-layout"
                                    className={`${styles.flexWrapper} ${styles.resize_container}`}
                                >
                                    <Box
                                        id="stage1"
                                        className={styles.stage1}
                                        style={{
                                            width: resizeEditor
                                                ? "100% "
                                                : " 70%",
                                        }}
                                    >
                                        <Box className={styles.editorWrapper}>
                                            <Tabs
                                                forceRenderTabPanel
                                                className={tabClassNames.tabs}
                                                selectedIndex={activeTabIndex}
                                                selectedTabClassName={
                                                    tabClassNames.tabSelected
                                                }
                                                selectedTabPanelClassName={
                                                    tabClassNames.tabPanelSelected
                                                }
                                                onSelect={onActivateTab}
                                                style={{
                                                    position: "relative",
                                                    background:
                                                        "rgb(203 223 255)",
                                                    width: "calc(100% + 1px)",
                                                }}
                                            >
                                                <TabList
                                                    className={
                                                        tabClassNames.tabList
                                                    }
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        margin: "0",
                                                        padding: "0",
                                                    }}
                                                >
                                                    <Tab
                                                        className={tabClassNames.tab}
                                                        style={{
                                                            backgroundColor: !showBlock ? "#142b60" : "#ffffff",
                                                            margin: "10px 0",
                                                            padding: "0",
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "2px solid #142b60",
                                                            borderRadius: '6px',
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            setOnCostumeClick(false);
                                                            setShowBlock(true);
                                                            setStageOpen(false);
                                                            setOpen(false);
                                                            setOnVolumeClick(false);
                                                            setIsCodeClick(false);
                                                            dispatch(
                                                                getCode(
                                                                    "setResizeEditor",
                                                                    resizeEditor ===
                                                                        true
                                                                        ? !resizeEditor
                                                                        : false
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <Tooltip title="Blocks" placement="right">
                                                            <div
                                                                style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                                className="blocks"
                                                            >
                                                                {showBlock ? (
                                                                    <img
                                                                        style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                        }}
                                                                        draggable={false}
                                                                        src={codeIconBlue}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                        }}
                                                                        draggable={false}
                                                                        src={codeIcon}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    </Tab>
                                                    <div
                                                        className={tabClassNames.tab}
                                                        style={{
                                                            backgroundColor: !stageOpen ? "#142b60" : "#ffffff",
                                                            margin: "10px 0",
                                                            padding: "0",
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "2px solid #142b60",
                                                            borderRadius: '6px',
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Tooltip title="Stage" placement="right">
                                                            <div
                                                                style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                                className="stage"
                                                            >
                                                                {!stageOpen ? (
                                                                    <SettingsIcon
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize:
                                                                                "25px",
                                                                        }}
                                                                        onClick={() => {
                                                                            setOpen(false);
                                                                            stageToggle();
                                                                            setOnVolumeClick(false);
                                                                            setShowBlock(false);
                                                                            setOnCostumeClick(false);
                                                                            setIsCodeClick(false);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <SettingsIcon
                                                                        style={{
                                                                            color: "#142b60",
                                                                            fontSize:
                                                                                "25px",
                                                                        }}
                                                                        onClick={() => {
                                                                            setStageOpen(false);
                                                                            setOpen(false);
                                                                            setShowBlock(false);
                                                                            setOnCostumeClick(false);
                                                                            setOnVolumeClick(false);
                                                                            setIsCodeClick(false);
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                    <div
                                                        className={tabClassNames.tab}
                                                        onClick={() => {
                                                            setIsCodeClick(false);
                                                            setOnVolumeClick(false);
                                                            setOnCostumeClick(false);
                                                            setShowBlock(false);
                                                            setStageOpen(false);
                                                            toggle();
                                                            setIsCodeClick(false);
                                                        }}
                                                        style={{
                                                            backgroundColor: !open ? "#142b60" : "#ffffff",
                                                            margin: "10px 0",
                                                            padding: "0",
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "2px solid #142b60",
                                                            borderRadius: '6px',
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Tooltip title="Sprite" placement="right">
                                                            <div
                                                                style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                                className="sprite"
                                                            >
                                                                {!open ? (
                                                                    <SwitchAccount
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize:
                                                                                "25px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <SwitchAccount
                                                                        style={{
                                                                            color: "#142b60",
                                                                            fontSize:
                                                                                "25px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                    <Tab
                                                        className={
                                                            tabClassNames.tab
                                                        }
                                                        onClick={() => {
                                                            setIsCodeClick(false);
                                                            setOnVolumeClick(false);
                                                            setStageOpen(false);
                                                            setShowBlock(false);
                                                            setOpen(false);
                                                            setOnCostumeClick(true);
                                                            onActivateCostumesTab;
                                                            dispatch(
                                                                getCode(
                                                                    "setResizeEditor",
                                                                    resizeEditor ===
                                                                        true
                                                                        ? !resizeEditor
                                                                        : false
                                                                )
                                                            );
                                                        }}
                                                        style={{
                                                            backgroundColor: !onCostumeClick ? "#142b60" : "#ffffff",
                                                            margin: "10px 0",
                                                            padding: "0px",
                                                            width: "50px",
                                                            height: "50px",
                                                            cursor: "pointer",
                                                            border: "2px solid #142b60",
                                                            borderRadius: "6px",
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title="Costumes"
                                                            placement="right"
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                                className="costumes"
                                                            >
                                                                {onCostumeClick ? (
                                                                    <Loop
                                                                        style={{
                                                                            color: "#142b60",
                                                                            fontSize:
                                                                                "25px",
                                                                            rotate: "150deg",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Loop
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize:
                                                                                "25px",
                                                                            rotate: "150deg",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    </Tab>
                                                    <Tab
                                                        className={
                                                            tabClassNames.tab
                                                        }
                                                        onClick={() => {
                                                            setIsCodeClick(false);
                                                            setOnVolumeClick(true);
                                                            setStageOpen(false);
                                                            setShowBlock(false);
                                                            setOnCostumeClick(false);
                                                            setOpen(false);
                                                            onActivateSoundsTab;
                                                            dispatch(
                                                                getCode(
                                                                    "setResizeEditor",
                                                                    resizeEditor ===
                                                                        true
                                                                        ? !resizeEditor
                                                                        : false
                                                                )
                                                            );
                                                        }}
                                                        style={{
                                                            backgroundColor: !onVolumeClick ? "#142b60" : "#ffffff",
                                                            margin: "10px 0",
                                                            padding: "0px",
                                                            width: "50px",
                                                            height: "50px",
                                                            cursor: "pointer",
                                                            border: "2px solid #142b60",
                                                            borderRadius: "6px",
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title="Sound"
                                                            placement="right"
                                                        >
                                                            <div style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                                className="sound"
                                                            >
                                                                {onVolumeClick ? (
                                                                    <VolumeUp
                                                                        style={{
                                                                            color: "#142b60",
                                                                            fontSize:
                                                                                "25px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <VolumeUp
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize:
                                                                                "25px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    </Tab>

                                                    <Tab
                                                        className={
                                                            tabClassNames.tab
                                                        }
                                                        style={{
                                                            backgroundColor: !isCodeClick ? "#142b60" : "#ffffff",
                                                            margin: "10px 0",
                                                            padding: "0",
                                                            height: "50px",
                                                            width: "50px",
                                                            border: "2px solid #142b60",
                                                            borderRadius: "6px",
                                                        }}
                                                        onClick={() => {
                                                            setIsCodeClick(true);
                                                            setOnVolumeClick(false);
                                                            setStageOpen(false);
                                                            setOnCostumeClick(false);
                                                            setShowBlock(false);
                                                            setOpen(false);
                                                            dispatch(
                                                                getCode(
                                                                    "setResizeEditor",
                                                                    resizeEditor ===
                                                                        true
                                                                        ? !resizeEditor
                                                                        : false
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {/* Code */}
                                                        <Tooltip
                                                            title="Code"
                                                            placement="right"
                                                        >
                                                            <div style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",

                                                            }}
                                                                className="code"
                                                            >
                                                                {isCodeClick ? (
                                                                    <img
                                                                        draggable={
                                                                            false
                                                                        }
                                                                        src={blockBlue}
                                                                        style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        draggable={
                                                                            false
                                                                        }
                                                                        src={block}
                                                                        style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    </Tab>
                                                </TabList>
                                                {/* </Box> */}

                                                <TabPanel
                                                    className={
                                                        tabClassNames.tabPanel
                                                    }
                                                >
                                                    <>
                                                        <Box
                                                            className={
                                                                styles.blocksWrapper
                                                            }
                                                        >
                                                            <CommonTargetpane
                                                                stageOpen={
                                                                    stageOpen
                                                                }
                                                                open={open}
                                                                stageSize={
                                                                    stageSize
                                                                }
                                                                vm={vm}
                                                            />
                                                            <Blocks
                                                                setCode={
                                                                    setCode
                                                                }
                                                                canUseCloud={
                                                                    canUseCloud
                                                                }
                                                                grow={1}
                                                                isVisible={
                                                                    blocksTabVisible
                                                                }
                                                                options={{
                                                                    media: `${basePath}static/blocks-media/`,
                                                                }}
                                                                stageSize={
                                                                    stageSize
                                                                }
                                                                vm={vm}
                                                            />
                                                        </Box>
                                                        <Box
                                                            className={`${styles.extensionButtonContainer} extension`}
                                                        >
                                                            <button
                                                                className={
                                                                    styles.extensionButton
                                                                }
                                                                title={intl.formatMessage(
                                                                    messages.addExtension
                                                                )}
                                                                onClick={
                                                                    onExtensionButtonClick
                                                                }
                                                            >
                                                                <img
                                                                    className={
                                                                        styles.extensionButtonIcon
                                                                    }
                                                                    draggable={
                                                                        false
                                                                    }
                                                                    src={
                                                                        addExtensionIcon
                                                                    }
                                                                />
                                                            </button>
                                                        </Box>

                                                        <Box
                                                            className={
                                                                styles.watermark
                                                            }
                                                        >
                                                            <Watermark />
                                                        </Box>
                                                    </>
                                                </TabPanel>

                                                <TabPanel
                                                    className={
                                                        tabClassNames.tabPanel
                                                    }
                                                >
                                                    <CommonTargetpane
                                                        stageOpen={stageOpen}
                                                        open={open}
                                                        stageSize={stageSize}
                                                        vm={vm}
                                                    />
                                                    {/* {costumesTabVisible ? ( */}
                                                    <CostumeTab vm={vm} />
                                                    {/* ) : null} */}
                                                </TabPanel>
                                                <TabPanel
                                                    className={
                                                        tabClassNames.tabPanel
                                                    }
                                                >
                                                    <CommonTargetpane
                                                        stageOpen={stageOpen}
                                                        open={open}
                                                        stageSize={stageSize}
                                                        vm={vm}
                                                    />
                                                    {soundsTabVisible ? (
                                                        <SoundTab vm={vm} />
                                                    ) : null}
                                                </TabPanel>

                                                <TabPanel
                                                    className={
                                                        tabClassNames.tabPanel
                                                    }
                                                >
                                                    <CommonTargetpane
                                                        stageOpen={stageOpen}
                                                        open={open}
                                                        stageSize={stageSize}
                                                        vm={vm}
                                                    />

                                                    <main
                                                        style={{
                                                            fontSize: 16,
                                                            width: "100%",
                                                            background:
                                                                "#262e39",
                                                            overflow: "auto",
                                                            height: "calc(100vh - 59px)",
                                                            position:
                                                                "relative",
                                                            paddingRight:
                                                                "10px",
                                                            overflowY: "hidden",
                                                            borderTopRightRadius:
                                                                "5px",
                                                            borderBottomRightRadius:
                                                                "5px",
                                                        }}
                                                        className={
                                                            styles.code_main
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                padding:
                                                                    "10px 20px",
                                                            }}
                                                        >
                                                            <select
                                                                style={{
                                                                    width: "111px",
                                                                    height: "30px",
                                                                    visibility:
                                                                        "hidden",
                                                                }}
                                                                name=""
                                                                id=""
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    dispatch(
                                                                        getAssets(
                                                                            "setCurrentLang",
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <option value="javascript">
                                                                    Javascript
                                                                </option>
                                                                <option value="python">
                                                                    Python
                                                                </option>
                                                                <option value="lua">
                                                                    Lua
                                                                </option>
                                                                <option value="csharp">
                                                                    C#
                                                                </option>
                                                                <option value="go">
                                                                    Go
                                                                </option>
                                                            </select>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <ThemeButton
                                                                    setDarkMode={
                                                                        setDarkMode
                                                                    }
                                                                    isDarkMode={
                                                                        isDarkMode
                                                                    }
                                                                />
                                                                <AspectRatioIcon
                                                                    style={{
                                                                        color: "white",
                                                                        marginLeft:
                                                                            "22px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={
                                                                        resizeToggle
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <SyntaxHighlighter
                                                            // eslint-disable-next-line react/no-children-prop
                                                            children={
                                                                currentLang ===
                                                                    "javascript"
                                                                    ? jsLang
                                                                    : currentLang ===
                                                                        "python"
                                                                        ? pythonLang
                                                                        : currentLang ===
                                                                            "csharp"
                                                                            ? cSharpLang
                                                                            : currentLang ===
                                                                                "lua"
                                                                                ? luaLang
                                                                                : currentLang ===
                                                                                    "go"
                                                                                    ? goLang
                                                                                    : jsLang
                                                            }
                                                            language="javascript"
                                                            style={
                                                                isDarkMode
                                                                    ? tomorrowNight
                                                                    : theme
                                                            }
                                                            customStyle={{
                                                                margin: "1px 0px 0px",
                                                                height: "calc(100% - 1px)",
                                                            }}
                                                            showLineNumbers={
                                                                true
                                                            }
                                                        />
                                                    </main>
                                                </TabPanel>
                                            </Tabs>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="stage2"
                                        className={styles.stage2}
                                        style={{
                                            display: resizeEditor
                                                ? "none"
                                                : "block",
                                        }}
                                    >
                                        <Box
                                            className={classNames(
                                                styles.stageAndTargetWrapper,
                                                styles[stageSize]
                                            )}
                                        >
                                            <Box
                                                id="flex-stages-layout-vertical"
                                                className={`${styles.flexWrapperRight} ${styles.resize_container_right}`}
                                            >
                                                <Box
                                                    id="stage1_vertical"
                                                    className={
                                                        styles.stage1_vertical
                                                    }
                                                >
                                                    <StageWrapper
                                                        isFullScreen={
                                                            isFullScreen
                                                        }
                                                        isRendererSupported={
                                                            isRendererSupported
                                                        }
                                                        isRtl={isRtl}
                                                        stageSize={stageSize}
                                                        vm={vm}
                                                        toggle={toggle}
                                                        open={open}
                                                    />
                                                </Box>
                                                <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                    <div className={styles.brows} style={{ display: 'flex' }}>
                                                        <Button
                                                            onClick={() => setIsOpen(true)}
                                                            style={{
                                                                flex: 1,
                                                                backgroundColor: !isOpen ? "#142b60" : "white",
                                                                color: !isOpen ? "white" : "#142b60",
                                                                borderTopLeftRadius: 8,
                                                                borderBottomLeftRadius: 8,
                                                                border: isOpen ? "2px solid #142b60" : "2px solid transparent",
                                                            }}
                                                            className={`${styles.btnObj} objective`}
                                                        >
                                                            Objective
                                                        </Button>
                                                        <Button
                                                            onClick={() => setIsOpen(false)}
                                                            style={{
                                                                backgroundColor: isOpen ? "#142b60" : "white",
                                                                flex: 1,
                                                                borderTopRightRadius: 8,
                                                                borderBottomRightRadius: 8,
                                                                color: isOpen ? "white" : "#142b60",
                                                                border: !isOpen ? "2px solid #142b60" : "2px solid transparent",
                                                            }}
                                                            className="hints"
                                                        >
                                                            Hints & Tips
                                                        </Button>
                                                    </div>

                                                    <div
                                                        style={{
                                                            backgroundColor: "#142b60",
                                                            borderRadius: 8,
                                                            display: isOpen ? "block" : "none",
                                                            flex: 1,
                                                            overflow: "auto",
                                                        }}
                                                        className={styles.browsContent}
                                                    >
                                                        <div style={{ overflow: "auto", maxHeight: "42vh", }}>
                                                            {formattedContent(gameObjective)}
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            backgroundColor: "#142b60",
                                                            borderRadius: 8,
                                                            display: !isOpen ? "block" : "none",
                                                            flex: 1,
                                                            overflow: "auto",
                                                        }}
                                                        className={styles.browsContent}
                                                    >
                                                        <div style={{ overflow: "auto", maxHeight: "42vh", }}>
                                                            {formattedContent(gameHintAndTips)}
                                                        </div>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <DragLayer />
                    </Box>
                );
            }}
        </MediaQuery>
    );
};

GUIComponent.propTypes = {
    accountNavOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    backdropLibraryVisible: PropTypes.bool,
    backpackHost: PropTypes.string,
    backpackVisible: PropTypes.bool,
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    cardsVisible: PropTypes.bool,
    children: PropTypes.node,
    costumeLibraryVisible: PropTypes.bool,
    costumesTabVisible: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    loading: PropTypes.bool,
    logo: PropTypes.string,
    onActivateCostumesTab: PropTypes.func,
    onActivateSoundsTab: PropTypes.func,
    onActivateTab: PropTypes.func,
    onClickAccountNav: PropTypes.func,
    onClickLogo: PropTypes.func,
    onCloseAccountNav: PropTypes.func,
    onExtensionButtonClick: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onRequestCloseBackdropLibrary: PropTypes.func,
    onRequestCloseCostumeLibrary: PropTypes.func,
    onRequestCloseTelemetryModal: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onShowPrivacyPolicy: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onTabSelect: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    renderLogin: PropTypes.func,
    showComingSoon: PropTypes.bool,
    soundsTabVisible: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    targetIsStage: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    tipsLibraryVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
};
GUIComponent.defaultProps = {
    backpackHost: null,
    backpackVisible: false,
    basePath: "./",
    canChangeLanguage: true,
    canCreateNew: false,
    canEditTitle: false,
    canManageFiles: true,
    canRemix: false,
    canSave: false,
    canCreateCopy: false,
    canShare: false,
    canUseCloud: false,
    enableCommunity: false,
    isCreating: false,
    isShared: false,
    loading: false,
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large,
};

const mapStateToProps = (state) => ({
    // This is the button's mode, as opposed to the actual current state
    currentLocale: state.locales.locale,
    stageSizeMode: state.scratchGui.stageSize.stageSize,
});

export default injectIntl(connect(mapStateToProps)(GUIComponent));
