const initialState = {
    code: '',
    resizeEditor: ''
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case 'setCode':
            console.log("calledddddd");
            return Object.assign({}, state, {
                code: action.data
            });
        case 'setResizeEditor':
            return Object.assign({}, state, {
                resizeEditor: action.data
            });
        default:
            return state;
    }
};

const getCode = function (type, data) {
    switch (type) {
        case 'setCode':
            return {
                type: type,
                data: data
            };
        case 'setResizeEditor':
            return {
                type: type,
                data: data
            };
        default:
            break;
    }

};

export {
    reducer as default,
    initialState as codeInitialState,
    getCode
};
