import classNames from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
    intlShape,
} from "react-intl";
import PropTypes from "prop-types";
import bindAll from "lodash.bindall";
import bowser from "bowser";
import React from "react";
import VM from "scratch-vm";
import projectData from "../../lib/default-project/project-data";
import Box from "../box/box.jsx";
import Button from "../button/button.jsx";

import { ComingSoonTooltip } from "../coming-soon/coming-soon.jsx";
import LanguageSelector from "../../containers/language-selector.jsx";
import SaveStatus from "./save-status.jsx";
import MenuBarMenu from "./menu-bar-menu.jsx";
import { MenuItem } from "../menu/menu.jsx";
import AuthorInfo from "./author-info.jsx";
import AccountNav from "../../containers/account-nav.jsx";
import MenuBarHOC from "../../containers/menu-bar-hoc.jsx";
import { setProjectTitle } from "../../reducers/project-title";
import { GrPowerReset } from "react-icons/gr";
import { openTipsLibrary } from "../../reducers/modals";
import { setPlayer } from "../../reducers/mode";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';


import {
    LoadingStates,
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy,
    setProjectId,
    createProject,
} from "../../reducers/project-state";
import {
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
} from "../../reducers/menus";

import collectMetadata from "../../lib/collect-metadata";

import styles from "./menu-bar.css";


import remixIcon from "./icon--remix.svg";
import aboutIcon from "./icon--about.svg";

import scratchLogo from "./scratch-logo.svg";

import sharedMessages from "../../lib/shared-messages";
import axios from "../../baseurl";

const studentImage = localStorage.getItem("StudentImage");
const userId = localStorage.getItem("UserId");
const userRole = localStorage.getItem("UserRole");
const userToken = localStorage.getItem("UserToken");
const projectHost = window.location.host.toString().split(":");
const hostlink = projectHost[1]
    ? window.location.host
    : window.location.hostname;
const ariaMessages = defineMessages({
    language: {
        id: "gui.menuBar.LanguageSelector",
        defaultMessage: "language selector",
        description: "accessibility text for the language selection menu",
    },
    tutorials: {
        id: "gui.menuBar.tutorialsLibrary",
        defaultMessage: "Tutorials",
        description: "accessibility text for the tutorials button",
    },
});
const studentName = localStorage.getItem("StudentName");

const sessionId = localStorage.getItem("classRoomSessionId");
const gameId = localStorage.getItem("gameId");
const studentId = localStorage.getItem("UserId");

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = "bottom",
}) => {
    if (enable) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};

MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

const MenuItemTooltip = ({ id, isRtl, children, className }) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? "left" : "right"}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool,
};

const AboutButton = (props) => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const defaultProjectJson = projectData();
const sessionCode = localStorage.getItem("SessionCode");
class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleClickNew",
            "handleClickRemix",
            "handleClickSave",
            "handleClickSaveAsCopy",
            "handleClickSeeCommunity",
            "handleClickShare",
            "handleKeyPress",
            "handleLanguageMouseUp",
            "handleRestoreOption",
            "getSaveToComputerHandler",
            "restoreOptionMessage",
        ]);
        this.state = {
            isLoading: false,
            isRotating: false,
        };
        this.resetStudentGame = this.resetStudentGame.bind(this);
    }
    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
    }

    getTitlefromServer() {
        if (userRole == "student") {
            URL = "/student/u_game";
        }
        if (userRole == "admin") {
            URL = "/master/game";
        }

        axios
            .get(`${URL}/${this.props.reduxProjectId}/data`, {
                headers: {
                    Authorization: userToken,
                },
            })
            .then((res) => {
                if (res.data.success == true) {
                    this.props.setTitle(res.data.game.name);
                }
            })
            .catch((err) => console.log(err));
    }
    handleClickNew() {
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.

        const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
            this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        );
        this.props.onRequestCloseFile();
        if (readyToReplaceProject) {
            this.props.onClickNew(
                this.props.canSave && this.props.canCreateNew
            );
        }
        this.props.onRequestCloseFile();
    }
    handleClickRemix() {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave() {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }

    handleClickSaveAsCopy() {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity(waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); // save before transitioning to project page
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare(waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) {
                // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) {
                // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleRestoreOption(restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress(event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === "s") {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    getSaveToComputerHandler(downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(
                    this.props.vm,
                    this.props.projectTitle,
                    this.props.locale
                );
                this.props.onProjectTelemetryEvent("projectDidSave", metadata);
            }
        };
    }
    handleLanguageMouseUp(e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    restoreOptionMessage(deletedItem) {
        switch (deletedItem) {
            case "Sprite":
                return (
                    <FormattedMessage
                        defaultMessage="Restore Sprite"
                        description="Menu bar item for restoring the last deleted sprite."
                        id="gui.menuBar.restoreSprite"
                    />
                );
            case "Sound":
                return (
                    <FormattedMessage
                        defaultMessage="Restore Sound"
                        description="Menu bar item for restoring the last deleted sound."
                        id="gui.menuBar.restoreSound"
                    />
                );
            case "Costume":
                return (
                    <FormattedMessage
                        defaultMessage="Restore Costume"
                        description="Menu bar item for restoring the last deleted costume."
                        id="gui.menuBar.restoreCostume"
                    />
                );
            default: {
                return (
                    <FormattedMessage
                        defaultMessage="Restore"
                        description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                        id="gui.menuBar.restore"
                    />
                );
            }
        }
    }
    buildAboutMenu(onClickAbout) {
        if (!onClickAbout) {
            // hide the button
            return null;
        }
        if (typeof onClickAbout === "function") {
            // make a button which calls a function
            return <AboutButton onClick={onClickAbout} />;
        }
        // assume it's an array of objects
        // each item must have a 'title' FormattedMessage and a 'handleClick' function
        // generate a menu with items for each object in the array
        return (
            <div
                className={classNames(styles.menuBarItem, styles.hoverable, {
                    [styles.active]: this.props.aboutMenuOpen,
                })}
                onMouseUp={this.props.onRequestOpenAbout}
            >
                <img className={styles.aboutIcon} src={aboutIcon} />
                <MenuBarMenu
                    className={classNames(styles.menuBarMenu)}
                    open={this.props.aboutMenuOpen}
                    place={this.props.isRtl ? "right" : "left"}
                    onRequestClose={this.props.onRequestCloseAbout}
                >
                    {onClickAbout.map((itemProps) => (
                        <MenuItem
                            key={itemProps.title}
                            isRtl={this.props.isRtl}
                            onClick={this.wrapAboutMenuCallback(
                                itemProps.onClick
                            )}
                        >
                            {itemProps.title}
                        </MenuItem>
                    ))}
                </MenuBarMenu>
            </div>
        );
    }
    wrapAboutMenuCallback(callback) {
        return () => {
            callback();
            this.props.onRequestCloseAbout();
        };
    }
    logout() {
        localStorage.clear();
        window.location.href = `http://${hostlink}/student.html`;
    }

    resetStudentGame() {
        this.setState({ isRotating: true });

        axios
            .get(`/master/game/gameonreset/${gameId}/${studentId}/${sessionId}`)
            .then((res) => {
                if (res && res?.data?.success) {
                    console.log("REspobnse", res);
                    this.setState({ isRotating: true });
                    window.location.reload();
                }
            })
            .catch((err) => console.log(err));
    }

    render() {
        const {
            reduxProjectId,
            projectId,
            setProjectId: setProjectIdProp,
            loadingState,
            onCreateProject,
        } = this.props;

        console.log("this.props#######", this.props);
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const remixButton = (
            <Button
                className={classNames(styles.menuBarButton, styles.remixButton)}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        // Show the About button only if we have a handler for it (like in the desktop app)
        const aboutButton = this.buildAboutMenu(this.props.onClickAbout);

        const localData = localStorage.getItem("gameName")

        return (
            <Box className={classNames(this.props.className, styles.menuBar)}>
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        <div className={classNames(styles.menuBarItem)}>
                            <h2
                                onClick={() => {
                                    window.location.href = `http://${hostlink}/tiles`;
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    border: "1px solid #fff",
                                    padding: "10px 15px",
                                    borderRadius: "8px",
                                    marginLeft: "10px",
                                    gap: "10px",
                                }}
                            >
                                <KeyboardDoubleArrowLeftIcon /> Back to courses
                            </h2>
                        </div>
                    </div>
                    {this.props.canEditTitle ? (
                        <div
                            style={{ marginRight: 10 }}
                            className={classNames(
                                styles.menuBarItem,

                            )}
                        >
                            <MenuBarItemTooltip enable id="title-field">
                                <span
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "capitalize",

                                    }}
                                >
                                    {localData}
                                </span>
                            </MenuBarItemTooltip>
                            <GrPowerReset
                                style={{ cursor: "pointer", marginLeft: 10 }}
                                onClick={this.resetStudentGame}
                                color="#ffffff"
                                size={20}
                                // className={
                                //     this.state.isRotating && styles.rotate
                                // }
                                className={`${this.state.isRotating ? styles.rotate : ""
                                    } reloadPage`}
                            />
                        </div>
                    ) : this.props.authorUsername &&
                        this.props.authorUsername !== this.props.username ? (
                        <AuthorInfo
                            className={styles.authorInfo}
                            imageUrl={this.props.authorThumbnailUrl}
                            projectTitle={this.props.projectTitle}
                            userId={this.props.authorId}
                            username={this.props.authorUsername}
                        />
                    ) : null}
                    {sessionCode && sessionCode?.length > 0 && (
                        <div style={{ marginRight: "20px", display: "flex" }}>
                            {sessionCode?.split("")?.map((e, index) => {
                                return (
                                    <span
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "2px solid #fff",
                                            borderRadius: "7px",
                                            margin: "0px 3px",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            color: "#142b60",
                                            background: "#fff",
                                        }}
                                        key={index}
                                    >
                                        {e}
                                    </span>
                                );
                            })}
                        </div>
                    )}
                    {this.state.isLoading && <span>Resetting Game...</span>}
                </div>

                <div className={`${styles.accountInfoGroup} profile`}>
                    <h2
                        onClick={() => {
                            localStorage.setItem('tourCompleted', false)
                            window.location.reload()
                        }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: "500",
                            border: "1px solid #fff",
                            padding: "10px 15px",
                            borderRadius: "8px",
                            marginLeft: "10px",
                            gap: "10px",
                        }}
                    >
                        Take a Tour
                    </h2>
                    {this.props.canChangeLanguage && (
                        <LanguageSelector
                            label={this.props.intl.formatMessage(
                                ariaMessages.language
                            )}

                        />
                    )}
                    <div className={styles.menuBarItem}>
                        {this.props.canSave && (
                            <SaveStatus />
                            //onClickSave={this.save()}
                        )}
                    </div>

                    <span
                        style={{
                            fontSize: "16px",
                            textTransform: "capitalize",

                        }}
                    >
                        {studentName?.toLowerCase()}
                    </span>
                    {studentImage !== "undefined" ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "10px",
                                borderRadius: "100%",
                                background: "silver",
                            }}
                        >
                            <img
                                src={studentImage}
                                alt=""
                                style={{
                                    border: "1px solid white",
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "100%",
                                    mixBlendMode: "color-dodge",
                                }}
                            />
                        </div>
                    ) : null}
                    <React.Fragment>
                        <AccountNav
                            className={classNames(
                                styles.menuBarItem,
                                styles.hoverable,
                                { [styles.active]: this.props.accountMenuOpen }
                            )}
                            isOpen={this.props.accountMenuOpen}
                            isRtl={this.props.isRtl}
                            menuBarMenuClassName={classNames(
                                styles.menuBarMenu
                            )}
                            onClick={this.props.onClickAccount}
                            onClose={this.props.onRequestCloseAccount}
                            onLogOut={this.logout}
                        />
                    </React.Fragment>
                </div>
            </Box>
        );
    }
}

MenuBar.propTypes = {
    aboutMenuOpen: PropTypes.bool,
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    onClickAbout: PropTypes.oneOfType([
        PropTypes.func, // button mode: call this callback when the About button is clicked
        PropTypes.arrayOf(
            // menu mode: list of items in the About menu
            PropTypes.shape({
                title: PropTypes.string, // text for the menu item
                onClick: PropTypes.func, // call this callback when the menu item is clicked
            })
        ),
    ]),
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestOpenAbout: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    userOwnsProject: PropTypes.bool,
    username: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    loadingState: PropTypes.oneOf(LoadingStates),
    reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setProjectId: PropTypes.func,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onCreateProject: PropTypes.func,
    setTitle: PropTypes.func,
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => { },
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user =
        state.session && state.session.session && state.session.session.user;
    return {
        aboutMenuOpen: aboutMenuOpen(state),
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        loadingState: loadingState,
        projectTitle: state.scratchGui.projectTitle,
        sessionExists:
            state.session && typeof state.session.session !== "undefined",
        username: user ? user.username : null,
        userOwnsProject:
            ownProps.authorUsername &&
            user &&
            ownProps.authorUsername === user.username,
        vm: state.scratchGui.vm,
        reduxProjectId: state.scratchGui.projectState.projectId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onRequestOpenAbout: () => dispatch(openAboutMenu()),
    onRequestCloseAbout: () => dispatch(closeAboutMenu()),
    onClickNew: (needSave) => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onCreateProject: () => dispatch(createProject()),
    setProjectId: (projectId) => {
        dispatch(setProjectId(projectId));
    },
    setTitle: (title) => {
        dispatch(setProjectTitle(title));
    },
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(mapStateToProps, mapDispatchToProps)
)(MenuBar);
