import React, { useState, useEffect } from "react";
import LOGO from "../img/logo_f1.svg";
import MYACCOUNT from "../classroomManager/Images/MYACCOUNT.png";

const Header = () => {
    const [parentName, setParentName] = useState("");

    useEffect(() => {
        const storedName = localStorage.getItem("parentName");
        console.log("Stored name:", storedName);
        if (storedName) {
            setParentName(storedName);
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = async () => {
            const storedName = await localStorage.getItem("parentName");
            setParentName(storedName);
        };

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [parentName]);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "40px",
                }}
            >
                <div className="col-md-3">
                    <div className="logo">
                        <img src={LOGO} alt="" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div
                        style={{
                            background: "#93BFCF",
                            height: "80px",
                            width: " 100%",
                            color: "white",
                            fontSize: "22px",
                            fontWeight: "bold",
                            paddingTop: "5px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            textAlign: "center",
                            borderRadius: "6px",
                        }}
                    >
                        Happy Easter Holdiay! Please remember our sessions will
                        resume after Easter holidays on 16th April
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "18px",
                            marginLeft: "120px",
                        }}
                    >
                        {parentName ? <p>{parentName}</p> : " "}
                    </div>

                    <div className="icon m-auto">
                        <img src={MYACCOUNT} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
