import React from "react";
import ReactDOM from "react-dom";
import ParentPortal from "../parent/ParentPortal";
import { useNavigate } from "react-router-dom";

const LogOut = () => {
    const projectHost = window.location.host.toString().split(":");
    const hostlink = projectHost[1]
        ? window.location.host
        : window.location.hostname;
    const navigate = useNavigate();

    return (
        <div style={{ marginLeft: "40px", marginTop: "-10px" }}>
            <p style={{ fontSize: "30px", marginBottom: "0px" }}>Log Out</p>
            <p style={{ fontSize: "20px" }}>
                Are you sure you want to log out?
            </p>
            <div style={{ display: "flex", gap: "20px", marginTop: "100px" }}>
                <button
                    style={{
                        color: "white",
                        width: "10vw",
                        background: "rgb(20, 43, 96)",
                        borderRadius: "5px",
                        textAlign: "center",
                        padding: "10px 0px",
                        fontSize: "17px",
                    }}
                    onClick={() => {
                        const keyToRetain = 'tourCompleted';
                        const valueToRetain = localStorage.getItem(keyToRetain);

                        Object.keys(localStorage).forEach((key) => {
                            if (key !== keyToRetain) {
                                localStorage.removeItem(key);
                            }
                        });

                        if (valueToRetain !== null) {
                            localStorage.setItem(keyToRetain, valueToRetain);
                        }
                        // window.location.href = `http://${hostlink}/parentLogin.html`;
                        navigate("/parent");
                    }}
                >
                    OK
                </button>
                <button
                    onClick={() => {
                        // window.location.href = `http://${hostlink}/parentPortal.html`;
                        navigate("/parent-courses");
                    }}
                    style={{
                        color: "white",
                        width: "10vw",
                        background: "rgb(20, 43, 96)",
                        borderRadius: "5px",
                        textAlign: "center",
                        padding: "10px 0px",
                        fontSize: "17px",
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default LogOut;
