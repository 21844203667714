const initialState = {
    gameObjective: "",
    gameHintAndTips: "",
};

const reducer = function (state, action) {
    if (typeof state === "undefined") state = initialState;
    switch (action.type) {
        case "set_game_objective":
            return Object.assign({}, state, {
                gameObjective: action.data,
            });
        case "set_game_hint_and_tips":
            return Object.assign({}, state, {
                gameHintAndTips: action.data,
            });
        default:
            return state;
    }
};

const setGameInfo = function (type, data) {
    switch (type) {
        case "set_game_objective":
            return {
                type: type,
                data: data,
            };
        case "set_game_hint_and_tips":
            return {
                type: type,
                data: data,
            };
        default:
            break;
    }
};

export {
    reducer as default,
    initialState as gameInfoInitialState,
    setGameInfo,
};
