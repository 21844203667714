import bindAll from "lodash.bindall";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    defaultProjectId,
    getIsFetchingWithoutId,
    setProjectId,
} from "../reducers/project-state";

/* Higher Order Component to get the project id from location.hash
 * @param {React.Component} WrappedComponent: component to render
 * @returns {React.Component} component with hash parsing behavior
 */
const projectHost = window.location.host.toString().split(":");
const hostlink = projectHost[1]
    ? window.location.host
    : window.location.hostname;
const HashParserHOC = function (WrappedComponent) {
    class HashParserComponent extends React.Component {
        constructor(props) {
            super(props);
            bindAll(this, ["handleHashChange"]);
        }
        componentDidMount() {
            window.addEventListener("hashchange", this.handleHashChange);
            this.handleHashChange();
        }
        componentDidUpdate(prevProps) {
            // if we are newly fetching a non-hash project...
            if (
                this.props.isFetchingWithoutId &&
                !prevProps.isFetchingWithoutId
            ) {
                // ...clear the hash from the url
                // console.log("window.location.pathname + window.location.search",window.location.pathname + window.location.search)
                history.pushState(
                    "new-project",
                    "new-project",
                    window.location.pathname + window.location.search,
                );
                //this.handleHashChange()
                window.addEventListener("hashchange", this.handleHashChange);
            }
        }
        componentWillUnmount() {
            window.removeEventListener("hashchange", this.handleHashChange);
        }
        handleHashChange() {
            const loc = window.location.href.toString();
            const projectid = loc.replace(
                `http://${hostlink}/editor?project_file=`,
                "",
            );
            console.log("id", projectid);
            //const hashMatch = window.location.hash.match(/#(\d+)/);
            const hashProjectId =
                projectid === null ? defaultProjectId : projectid;
            if (hashProjectId === defaultProjectId) {
                this.props.setProjectId(projectid);
            }
            this.props.setProjectId(hashProjectId);
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                isFetchingWithoutId: isFetchingWithoutIdProp,
                reduxProjectId,
                setProjectId: setProjectIdProp,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return <WrappedComponent {...componentProps} />;
        }
    }
    HashParserComponent.propTypes = {
        isFetchingWithoutId: PropTypes.bool,
        reduxProjectId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        setProjectId: PropTypes.func,
    };
    const mapStateToProps = (state) => {
        const loadingState = state.scratchGui.projectState.loadingState;
        return {
            isFetchingWithoutId: getIsFetchingWithoutId(loadingState),
            reduxProjectId: state.scratchGui.projectState.projectId,
        };
    };
    const mapDispatchToProps = (dispatch) => ({
        setProjectId: (projectId) => {
            dispatch(setProjectId(projectId));
        },
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) =>
        Object.assign({}, stateProps, dispatchProps, ownProps);
    return connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    )(HashParserComponent);
};

export { HashParserHOC as default };
