import React, { useState, useEffect } from "react";
import "./ThemeButton.css";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';


export default function ThemeButton({ setDarkMode, isDarkMode }) {
    return (
        <>
            <style>
                {
                    `
                * {
                box-sizing: border-box;
            }            
            .checkbox {
                opacity: 0;
                position: absolute;
            }
            
            .label {
                width: 39px;
                height: 20px;
                background-color: #111;
                display: flex;
                border-radius: 50px;
                align-items: center;
                justify-content: space-between;
                padding: 5px;
                position: relative;
                transform: scale(1.5);
                cursor: pointer;
                border: 1px solid white;
            }
            
            .ball {
                width: 14px;
                height: 14px;
                background-color: white;
                position: absolute;
                top: 2px;
                left: 2px;
                border-radius: 50%;
                transition: transform 0.2s linear;
            }
            
            /*  target the elemenent after the label*/
            .checkbox:checked+.label .ball {
                transform: translateX(19px);
            }
            
            .fa-moon {
                color: pink;
                font-size: 12px;
            }
            
            .fa-sun {
                color: yellow;
                font-size: 11px;
            }
                `
                }
            </style>
            <div className="container">
                <input type="checkbox" className="checkbox" id="checkbox" onChange={() => { setDarkMode(!isDarkMode) }} />
                <label htmlFor="checkbox" className="label">
                    <WbSunnyIcon className="fa-sun" />
                    <DarkModeIcon className="fa-moon" />
                    <div className='ball' />
                </label>
            </div>
        </>
    )
}
