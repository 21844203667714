import React, { useState, useEffect, useRef } from "react";
import axios from "../baseurl";
import ReactLoading from "react-loading";
import { GrSearch } from "react-icons/gr";
import { Parallax } from "react-parallax";
import "./parentTiles.css";
import $ from "jquery";
import Swal from "sweetalert2";
import { DensitySmall, Close, Lock } from "@mui/icons-material";
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ParallaxEffect from "./parallax-effect/parallax-effect.jsx";
const userToken = localStorage.getItem("UserToken");
const parentId = localStorage.getItem("UserId");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
        minWidth: "440px",
        maxWidth: "500px",
        maxHeight: "65vh",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close style={{ fontSize: "2rem" }} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ChildProgress() {
    const [view, setView] = useState("default");
    const [selectedChild, setSelectedChild] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPath, setSelectedPath] = useState();
    const [gameId, setGameId] = useState();
    const [sessionIdMap, setSessionIdMap] = useState();
    return (
        <div>
            {switcher(
                view,
                setView,
                selectedChild,
                setSelectedChild,
                isLoading,
                setIsLoading,
                selectedPath,
                setSelectedPath,
                gameId,
                setGameId,
                sessionIdMap,
                setSessionIdMap,
            )}
        </div>
    );
}

const switcher = (
    view,
    setView,
    selectedChild,
    setSelectedChild,
    isLoading,
    setIsLoading,
    selectedPath,
    setSelectedPath,
    gameId,
    setGameId,
    sessionIdMap,
    setSessionIdMap,
) => {
    switch (view) {
        case "progress":
            return (
                <Progress
                    setView={setView}
                    selectedChild={selectedChild}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setSelectedPath={setSelectedPath}
                    selectedPath={selectedPath}
                    setGameId={setGameId}
                    gameId={gameId}
                    setSessionIdMap={setSessionIdMap}
                />
            );
        case "course":
            return (
                <Course
                    setView={setView}
                    selectedChild={selectedChild}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    gameId={gameId}
                    selectedPath={selectedPath}
                    sessionIdMap={sessionIdMap}
                />
            );
        case "observe":
            return (
                <ChildObserv
                    setView={setView}
                    selectedChild={selectedChild}
                    setSelectedChild={setSelectedChild}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                />
            );

        default:
            return (
                <ChildInfo
                    view={view}
                    setView={setView}
                    selectedChild={selectedChild}
                    setSelectedChild={setSelectedChild}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            );
    }
};

const ChildInfo = ({
    setView,
    view,
    selectedChild,
    setSelectedChild,
    isLoading,
    setIsLoading,
}) => {
    const [children, setChildren] = useState([]);

    const [searchValue, setSearchValue] = useState("");

    const handleChildSelect = (child) => {
        setSelectedChild(child);
    };

    const filteredChildren = children.filter((child) => {
        const fullName = `${child.firstName} ${child.lastName}`.toLowerCase();
        return fullName.includes(searchValue.toLowerCase());
    });

    useEffect(() => {
        if (parentId) {
            setIsLoading(true);

            axios
                .get(`/classroom/manager/getParentsChild/${parentId}`, {
                    headers: {
                        Authorization: userToken,
                    },
                })
                .then((response) => {
                    const children =
                        response.data.childrenByParentId[0].children;
                    console.log(response.data, "parent");
                    setChildren(children);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            alert("There is no parent");
        }
    }, [parentId, userToken]);

    useEffect(() => {
        if (view !== "course" && view !== "observe") {
            setSelectedChild(null);
        }
    }, [view]);

    if (isLoading) {
        return (
            <div
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10%",
                }}
                className="w-100 gap-6"
            >
                <ReactLoading
                    type="spin"
                    color="#000000"
                    height={21}
                    width={21}
                />
            </div>
        );
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "30px",
                }}
            >
                <h5>Child Portal</h5>
                <div
                    style={{
                        width: "35vw",
                        display: "flex",
                        height: "30px",
                        border: "2px solid #9d9d9d",
                        borderRadius: "6px",
                    }}
                    className="srarchBar"
                >
                    <input
                        style={{
                            width: "100%",
                            borderRadius: "3px",
                            padding: "2px 10px 2px 5px",
                            border: "none",
                            outline: "none",
                        }}
                        type="text"
                        name="find"
                        placeholder="Type name here"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <p style={{ padding: "0px 5px 0px 0px" }}>
                        <GrSearch />
                    </p>
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "35vw",
                        flexDirection: "column",
                        gap: "2px",
                    }}
                >
                    {filteredChildren.map((child, index) => (
                        <div
                            style={{
                                background: "#F5F5F5",
                                padding: "2px",
                                marginTop: "2px",
                                marginLeft: "2px",
                                borderRadius: "3px",
                                display: "flex",
                                gap: "5px",
                            }}
                            key={index}
                        >
                            <input
                                type="radio"
                                name="children"
                                onClick={() => handleChildSelect(child)}
                            />
                            {`${child.firstName} ${child.lastName}`}
                        </div>
                    ))}
                </div>
            </div>

            <div
                className="parent-button"
                style={{
                    display: "flex",
                    gap: "20px",
                    position: "fixed",
                    marginLeft: "30px",
                }}
            >
                <button
                    onClick={() => {
                        if (selectedChild) {
                            setView("progress");
                        }
                    }}
                    disabled={!selectedChild}
                    style={{
                        marginTop: "21px",
                        width: "10vw",
                        textAlign: "center",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid black",
                        background: "rgb(38, 113, 139)",
                        color: "white",
                        cursor: selectedChild ? "pointer" : "not-allowed",
                    }}
                    data-tooltip={selectedChild ? null : "Select Child First!"}
                >
                    View Progress
                </button>
                <button
                    onClick={() => {
                        if (selectedChild) {
                            setView("observe");
                        }
                    }}
                    disabled={!selectedChild}
                    style={{
                        marginTop: "21px",
                        width: "10vw",
                        textAlign: "center",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid black",
                        background: "rgb(38, 113, 139",
                        color: "white",
                        cursor: selectedChild ? "pointer" : "not-allowed",
                    }}
                    data-tooltip={selectedChild ? null : "Select Child First!"}
                >
                    Observation
                </button>
            </div>
        </>
    );
};

const ChildObserv = ({ setView, selectedChild, isLoading, setIsLoading }) => {
    console.log(selectedChild, "selected child");
    const [message, setMessage] = useState();

    const handleSubmit = () => {
        if (!message) {
            alert("Please add feedback.");
            return;
        }
        setIsLoading(true);
        const childId = selectedChild.id;
        const childName = `${selectedChild.firstName} ${selectedChild.lastName}`;
        console.log(childName);

        axios
            .post(
                `/classroom/manager/sendFeedbackToTeacher`,
                { childId: childId, childName: childName, message: message },
                {
                    headers: {
                        Authorization: userToken,
                    },
                },
            )
            .then((responses) => {
                console.log(responses);
                setIsLoading(false);
                Swal.fire({
                    title: "Feedback",
                    text: "Sent Feedback successfully",
                    confirmButtonText: "OK",
                });
                window.history.replaceState(setView("progess"));
            })
            .catch((error) => {
                console.log(error);
            });

        setMessage(" ");
    };

    if (isLoading) {
        return (
            <div
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10%",
                }}
                className="w-100 gap-6"
            >
                <ReactLoading
                    type="spin"
                    color="#000000"
                    height={21}
                    width={21}
                />
            </div>
        );
    }
    return (
        <>
            <div
                className="d-flex flex-column w-100 gap-6"
                style={{ marginLeft: "30px" }}
            >
                <h5>
                    {`${selectedChild.firstName} ${selectedChild.lastName}`} -
                    Observation
                </h5>
                <label
                    htmlFor="child1"
                    style={{
                        fontSize: "13px",
                        marginBottom: "5px",
                        marginTop: "15px",
                    }}
                >
                    {`${selectedChild.firstName} ${selectedChild.lastName}`}
                </label>
                <textarea
                    placeholder="Please provide your observations."
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    style={{
                        width: "37vw",
                        height: "100px",
                        resize: "none",
                        border: "1px solid black",
                        background: "#F2F2F2",
                        borderRadius: "5px",
                        fontSize: "14px",
                        padding: "2px 10px",
                        marginBottom: "10px",
                    }}
                ></textarea>
            </div>
            <div
                style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "20px",
                    marginLeft: "20px",
                }}
            >
                <button
                    onClick={() => setView("")}
                    style={{
                        marginTop: "21px",
                        width: "10vw",
                        textAlign: "center",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid black",
                        background: "rgb(38, 113, 139)",
                        color: "white",
                    }}
                >
                    Back
                </button>
                <button
                    onClick={handleSubmit}
                    style={{
                        marginTop: "21px",
                        width: "10vw",
                        textAlign: "center",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid black",
                        background: "rgb(38, 113, 139)",
                        color: "white",
                    }}
                >
                    Send
                </button>
            </div>
        </>
    );
};

const Progress = ({
    setView,
    selectedChild,
    isLoading,
    setIsLoading,
    gameId,
    setGameId,
    selectedPath,
    setSelectedPath,
    setSessionIdMap,
}) => {
    const handleInfo = (path, game) => {
        setSelectedPath(path);
        setGameId(game);
    };

    const [percent, setPercent] = useState(1);
    const isScrollActive = useRef();
    const projectHost = window.location.host.toString().split(":");
    const [sessionId, setSessionId] = useState(" ");
    const [sessionCode, setSessionCode] = useState(" ");
    const [path, setPath] = useState({});
    const [imagePath, setImagePath] = useState({});

    const [prevPlayedGameIds, setPrevPlayedGameIds] = useState("");
    const hostlink = projectHost[1]
        ? window.location.host
        : window.location.hostname;
    const [gameList, setGameList] = useState([]);
    const userId = selectedChild.id;

    const callBothApis = async (getDetails, getSession) => {
        const id = userRole == "student" ? getDetails.path.id : CouserId;
        const urls = [
            {
                type: "post",
                url: `${URL}/${id}`,
                headers: { Authorization: `${userToken}` },
                payload: {
                    student_id: userId,
                    sessionCode:
                        getSession.gameAccessAssociation[0].classRoomSession.id,
                },
            },
            {
                type: "get",
                url: "/student/belts",
                payload: {},
                headers: {},
            },
        ];

        const requests = urls.map((url) => {
            return axios({
                url: url.url,
                method: url.type,
                data: url.payload,
                headers: url.headers,
            });
        });
        const result = await Promise.all(requests);
        const belts = result
            .find((e) => Object.hasOwn(e.data, "belt"))
            .data.belt.map((e) => e);
        const games = result.find((e) => Object.hasOwn(e.data, "games")).data
            .games;
        setBeltList(() => belts);
        setGameList(games);
        console.log(games, "games data");
        setIsLoading(false);
    };

    const studentDetail = (data) => {
        axios
            .post(
                `/classroom/manager/getStudentDetails`,
                {
                    firstname: selectedChild.firstName,
                    lastname: selectedChild.lastName,
                    sessionCode:
                        data.gameAccessAssociation[0].classRoomSession
                            .sessionCode,
                },
                {
                    headers: {
                        Authorization: userToken,
                    },
                },
            )
            .then((response) => {
                console.log(response.data.path, "api path");

                const prevPlayedGameId = response.data.path.activeGameId;
                setPrevPlayedGameIds(prevPlayedGameId);

                setPath(response.data.path);

                setImagePath(response.data.path.image);
                console.log(response.data.path.image, "image path");
                callBothApis(response.data, data);
                localStorage.setItem(
                    "imageOfPath",
                    JSON.stringify(response?.data?.path?.image),
                );
            });
    };

    useEffect(() => {
        if (userId) {
            setIsLoading(true);
            axios
                .get(`/classroom/manager/getLatestSession/${userId}`, {
                    headers: {
                        Authorization: userToken,
                    },
                })
                .then((response) => {
                    if (!response.data.gameAccessAssociation.length) {
                        Swal.fire({
                            title: "No Session Found",
                            text: "There is no session for selected student",
                            confirmButtonText: "OK",
                        });
                        window.history.replaceState(setView("progess"));
                    } else {
                        const sessionId =
                            response.data.gameAccessAssociation[0]
                                .classRoomSession.id;
                        setSessionIdMap(
                            response.data.gameAccessAssociation.map(
                                (el) => el.classRoomSession.id,
                            ),
                        );
                        const sessionCode =
                            response.data.gameAccessAssociation[0]
                                .classRoomSession.sessionCode;
                        const gameId =
                            response.data.gameAccessAssociation[0].gameIds[0];
                        console.log(gameId, "game ID");
                        setSessionCode(sessionCode);
                        console.log(sessionId, "classroom id");
                        setSessionId(sessionId);
                        studentDetail(response.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, []);

    console.log(sessionId, "id of sesssion");

    //   useEffect(() =>{
    //   if(sessionCode){
    //   axios.post(`/classroom/manager/getStudentDetails`,{
    //   firstname: selectedChild.firstName,
    //   lastname: selectedChild.lastName,
    //   sessionCode: sessionCode
    //   },
    //   {
    //     headers: {
    //         Authorization: userToken,
    //     },
    // }
    //   ).then((response) =>{

    //     console.log(response.data.path, "api path")
    //     const path = response.data.path;
    //     const prevPlayedGameId = response.data.path.activeGameId
    //     setPrevPlayedGameIds(prevPlayedGameId)
    //     setPath(path)

    //   })
    // }
    // }, [sessionCode ])

    // hardcoded

    console.log(userId, "userId");
    const userRole = "student";
    console.log(userRole, "userRole");
    const CouserId = localStorage.getItem("CourseId");
    console.log(CouserId, "CouserId");
    const classRoomSessionId = sessionId;
    console.log(classRoomSessionId, "classRoomSessionId");

    const [beltlist, setBeltList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [parallaxScroll, setParallaxScroll] = useState(0);
    //  const Paths = {
    //      id: "67f75289-1bcb-4be5-8955-cf33e0ff8f23",
    //      activeGameId: "e7206505-2de6-4de9-b10e-a3a3066274b6",
    //      classRoomSessionId: "0afbcaeb-956d-446b-95a8-e81923072734",
    //      created_on: "2023-02-09T12:03:57.202Z",
    //      image: {
    //          baseString: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABKM",
    //          name: "Path of Ninjas",
    //      },
    //      schoolSessionIdId: "0c907d72-bda0-4227-8ad2-ac4485a79794",
    //      sessionId: "2f14d43f-0d01-4a6c-af2c-94a17d7976f0",
    //      updated_on: "2023-02-13T02:38:21.935Z",
    //  };

    const Paths = path;
    console.log(Paths, "Paths");

    const prevPlayedGameId = prevPlayedGameIds;

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    var URL;
    if (userRole == "student") {
        URL = "/student/paths";
    }
    if (userRole == "admin") {
        URL = "/master/game";
    }

    const scrollProgress = () => {
        const scrollPx = document.documentElement.scrollTop;
        const winHeightPx =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;

        document.getElementsByClassName("bgImageParallax")[0].style.transform =
            `translate(-50%, -${50}%)`;
        setParallaxScroll(parallaxScroll + 80);
    };

    // useEffect(() => {
    //   // window.addEventListener("scroll", scrollProgress);
    //   callBothApis();
    // }, []);

    function createProject(item, nextItem) {
        localStorage.setItem("gameId", item.id);
        localStorage.setItem("nextGameId", nextItem?.id);
        localStorage.removeItem("prevPlayedGameStatus");
        if (item && userRole == "student") {
            axios
                .post(
                    `/student/u_game/${item.id}`,
                    {
                        student_id: userId,
                        json: item.json,
                        name: item.name,
                        sessionCode: classRoomSessionId,
                    },
                    {
                        headers: {
                            Authorization: `${userToken}`,
                        },
                    },
                )
                .then((response) => {
                    if (response.data.success == true) {
                        const id = response.data.update.id;
                        window.location.href = `http://${hostlink}/editor?project_file=${id}`;
                    }
                })
                .catch((err) => {
                    // reject(err);
                    console.log(err);
                });
        } else {
            window.location.href = `http://${hostlink}/editor?project_file=${item.id}`;
        }
    }

    // from here

    const TableOfContent = ({ belt, index }) => {
        let totalLevels = gameList.filter(
            (game, i) => game?.belt_id === belt?.id,
        );
        const beltWiseArray = gameList.filter(
            (game) => game?.belt_id === belt?.id,
        );
        let sortedBeltLevels = beltWiseArray.sort(function (a, b) {
            return a.level - b.level;
        });
        let totalLevel = [];
        sortedBeltLevels.forEach((e) => {
            if (!totalLevel.includes(e?.level_name)) {
                totalLevel.push(e?.level_name);
            }
        });

        return (
            <>
                <a
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        color: "#555",
                        textDecoration: "none",
                    }}
                    onClick={() => {
                        const element = document.getElementById(`tile${index}`);
                        window.scrollTo({
                            top: belt.name === "White" ? 0 : element.offsetTop,
                            behavior: "smooth",
                        });
                        setOpen(false);
                        isScrollActive.current = true;
                    }}
                    className="belt-list-overview"
                >
                    <Typography style={{ fontSize: "16px" }}>
                        {belt?.name}
                    </Typography>
                    <Typography style={{ fontSize: "18px" }}>{`${totalLevels?.length > 0 ? "1" : "0"
                        }${totalLevels.length > 1 ? ` - ${totalLevels.length}` : ""
                        }`}</Typography>
                </a>
                <div>
                    {totalLevel.map((level, index) => {
                        const totalGamesInLevel = gameList.filter(
                            (e) => e.level === index + 1,
                        );
                        return (
                            <>
                                <a
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        cursor: "pointer",
                                        color: "#555",
                                        textDecoration: "none",
                                        paddingLeft: "5px",
                                    }}
                                    onClick={() => {
                                        const element = document.getElementById(
                                            `${belt?.name}_${level}`,
                                        );
                                        window.scrollTo({
                                            top: element.offsetTop,
                                            behavior: "smooth",
                                        });
                                        setOpen(false);
                                        isScrollActive.current = true;
                                    }}
                                >
                                    <li
                                        style={{
                                            fontSize: "14px",
                                            listStyle: "none",
                                        }}
                                    >
                                        {level}
                                    </li>
                                    <Typography
                                        style={{ fontSize: "14px" }}
                                    >{`${totalGamesInLevel?.length > 0
                                            ? "1"
                                            : "0"
                                        }${totalGamesInLevel?.length > 1
                                            ? ` - ${totalGamesInLevel?.length}`
                                            : ""
                                        }`}</Typography>
                                </a>
                                <div style={{ paddingLeft: "10px" }}>
                                    {totalGamesInLevel.map((game, index) => {
                                        return (
                                            <>
                                                <li
                                                    style={{
                                                        fontSize: "14px",
                                                        listStyle: "none",
                                                        margin: "3px 0px",
                                                    }}
                                                >
                                                    {game?.name}
                                                </li>
                                            </>
                                        );
                                    })}
                                </div>
                            </>
                        );
                    })}
                </div>
            </>
        );
    };

    const list = [];
    for (var i = 0; i < beltlist.length; i++) {
        const beltWiseArray = gameList.filter(
            (game) => game.belt_id === beltlist[i].id,
        );
        let sortedBeltLevels = beltWiseArray.sort(function (a, b) {
            return a.level - b.level;
        });
        let totalLevels = [];
        sortedBeltLevels.forEach((e) => {
            if (!totalLevels.includes(e.level)) {
                totalLevels.push(e.level);
            }
        });
        list.push(
            // gameList.filter(s => s.stage_number.includes(`0${i}`)).map((item) => (
            <>
                <h2
                    style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "Montserrat",
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        borderRadius: "4px",
                        marginLeft: "21px",
                        textShadow: "0px 1px 10px rgb(255 255 255 / 70%)",
                    }}
                >
                    {beltlist[i].name + " Belt"}
                </h2>
                <div id={`tile${i}`}>
                    {totalLevels.map((level) => {
                        const levelWiseGames = sortedBeltLevels.filter(
                            (item) => item.level === level,
                        );
                        const stageWiseGamesSorted = levelWiseGames.sort(
                            function (a, b) {
                                return a.stage_number - b.stage_number;
                            },
                        );
                        return (
                            <div
                                style={{ marginTop: "20px" }}
                                id={`${beltlist[i].name}_${stageWiseGamesSorted[0]?.level_name}`}
                            >
                                <p
                                    style={{
                                        textAlign: "left",
                                        fontSize: "13px",
                                        fontWeight: "600",
                                        marginTop: "-15px",
                                        marginLeft: "22px",
                                    }}
                                >{`${level < 10 ? "0" + level : level} ${stageWiseGamesSorted[0]?.level_name
                                    }`}</p>
                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns:
                                            "repeat(auto-fill, minmax(160px, 1fr))",
                                        gridGap: "2px",
                                        margin: "0px",
                                        padding: "0px",
                                    }}
                                >
                                    {stageWiseGamesSorted.map((item, index) => {
                                        let nextItem = gameList[index + 1];
                                        return (
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                <div
                                                    className={`box ${prevPlayedGameId ===
                                                            item.id
                                                            ? "highlighted"
                                                            : ""
                                                        }`}
                                                    onClick={() => {
                                                        // gameList.map((el, index) =>{
                                                        //   if(item.id === el[index].id )
                                                        //   return
                                                        //   handleInfo(path, el);
                                                        //   console.log(path, 'student path');
                                                        //   console.log(el, 'game path');
                                                        //   setView("course");
                                                        // })
                                                        handleInfo(path, item);
                                                        console.log(
                                                            item,
                                                            "item",
                                                        );
                                                        setView("course");
                                                    }}
                                                    style={
                                                        item.status ||
                                                            item.id ===
                                                            prevPlayedGameId
                                                            ? {
                                                                cursor: "pointer",
                                                            }
                                                            : {
                                                                cursor: "not-allowed",
                                                                opacity:
                                                                    "0.8",
                                                                backdropFilter:
                                                                    "blur(5px)",
                                                            }
                                                    }
                                                >
                                                    <Lock
                                                        style={{
                                                            display:
                                                                item.status ||
                                                                    item.id ===
                                                                    prevPlayedGameId
                                                                    ? "none"
                                                                    : "unset",
                                                            width: "30px",
                                                            height: "30px",
                                                            color: "rgb(117 117 117)",
                                                            position:
                                                                "absolute",
                                                            right: "4px",
                                                            top: "4px",
                                                        }}
                                                    />
                                                    <p className="stageNumber">{`${item.stage}`}</p>
                                                    <img
                                                        src={item?.thumnail_url}
                                                        style={{
                                                            width: "100px",
                                                            marginTop: "0px",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform:
                                                                "translate(-50%,-50%)",
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            bottom: "0px",
                                                            right: "0px",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <hr
                                                            style={{
                                                                margin: "0",
                                                                padding: "0",
                                                            }}
                                                        />
                                                        <p className="stageName">{`${item.name} `}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>,
        );
    }

    useEffect(() => {
        console.log("percent ==>", percent);
    }, [percent]);

    /* jquery */
    const jcode = (id) => {
        window.scrollTo({
            top: (id / 100) * ($(document).height() - $(window).height()),
            behavior: "smooth",
        });
        isScrollActive.current = false;
    };
    const handleScrollPercentage = (id) => {
        window.scrollTo({
            top: (id / 100) * ($(document).height() - $(window).height()),
            behavior: "smooth",
        });
        isScrollActive.current = false;
    };

    const up = () => {
        let percentage = ($(window).height() / $(document).height()) * 100;
        setPercent(Math.round(Math.max(percent - percentage, 0)));
        handleScrollPercentage(Math.max(percent - percentage, 0));
    };

    const down = () => {
        let percentage = ($(window).height() / $(document).height()) * 100;
        setPercent(Math.round(Math.min(percent + percentage, 100)));
        handleScrollPercentage(Math.min(percent + percentage, 100));
    };

    useEffect(() => {
        var scrollTopp = $(window).scrollTop();
        var docHeightt = $(document).height();
        var winHeightt = $(window).height();
        var scrollPercentt = scrollTopp / (docHeightt - winHeightt);
        console.log(Math.round(scrollPercentt * 100));
        $(window).bind("mousewheel DOMMouseScroll", function (e) {
            isScrollActive.current = true;
        });
        $(window).bind("scroll", function (e) {
            if (
                isScrollActive.current === true ||
                isScrollActive.current === undefined
            ) {
                var scrollTop = $(window).scrollTop();
                var docHeight = $(document).height();
                var winHeight = $(window).height();
                var scrollPercent = scrollTop / (docHeight - winHeight);
                setPercent(Math.round(scrollPercent * 100));
            }
        });
    }, []);

    const myComponent = {
        width: "100px",
        position: "fixed",
        top: "45%",
        transform: "translateY(-20%)",
        right: "24vw",
    };
    const tableOfContent = {
        width: "35px",
        height: "35px",
        marginTop: "0px",
        marginLeft: "37px",
        cursor: "pointer",
        background: "#333333",
        borderRadius: "100%",
        color: "#cccccc",
        padding: "6px",
        marginBottom: "15px",
    };
    const showAlert = () => {
        Swal.fire({
            title: "Stage Locked",
            text: "Complete Previous lesson to unlock",
            // icon: "success",
            confirmButtonText: "OK",
        });
    };

    useEffect(() => {
        setTimeout(() => {
            document.getElementsByClassName(
                "parallXImage",
            )[0].style.transition = "0.6s ease-out transform";
        }, 1100);
    }, []);

    return (
        <>
            <div
                className="d-flex flex-column w-100 gap-6"
                style={{ marginLeft: "30px" }}
            >
                <label
                    htmlFor="progress"
                    style={{ fontSize: "15px", marginBottom: "5px" }}
                >
                    {`${selectedChild.firstName} ${selectedChild.lastName} - View Progress`}
                </label>
                <div className="tiles-space">
                    {isLoading ? (
                        <div
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "10%",
                            }}
                            className="w-100 gap-6"
                        >
                            <ReactLoading
                                type="spin"
                                color="#000000"
                                height={21}
                                width={21}
                            />
                        </div>
                    ) : (
                        <ParallaxEffect image={imagePath}>
                            <div>
                                <div>
                                    <div>
                                        <link
                                            rel="stylesheet"
                                            href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
                                        />
                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
                                        <link
                                            rel="stylesheet"
                                            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                                        />
                                        <link
                                            href="https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:400,300,700,600|Ubuntu:400,300,500,700"
                                            rel="stylesheet"
                                            type="text/css"
                                        />
                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.1.0/react.min.js"></script>
                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.1.0/react-dom.min.js"></script>

                                        <style>
                                            {`  
                  .BsFillArrowUpCircleFill.hover{
                    {
                      background-color:'lightgrey'
                        
                    }
                  }
                  .scrollbar {
                    margin-left: 30px;
                    float: left;
                    height: 300px;
                    width: 65px;
                    background: #fff;
                    overflow-y: scroll;
                    margin-bottom: 25px;
                  }
                  .force-overflow {
                    min-height: 450px;
                  }
                  
                  .scrollbar-info::-webkit-scrollbar {
                    width: 12px;
                    background-color: #F5F5F5;
                  }
                  
                  .scrollbar-info::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                    background-color: #4285F4;
                  }
                  
                  .scrollbar-info {
                    scrollbar-color: #4285F4 #F5F5F5;
                  }
                  body {
                    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
                    font-size: 14px;               
                    color: #333;                
                    // overflow-y: hidden;                
                  }                           
                  #cards_landscape_wrap-2{
                    text-align: center;
                    
                  }
                  #cards_landscape_wrap-2 .containerr{
                    padding-top: 15px; 
                    padding-bottom: 100px;
                    margin: 0 auto;
                    
                  }
                  #cards_landscape_wrap-2 a{
                    text-decoration: none;
                    outline: none;
                  }
                  #cards_landscape_wrap-2 .card-flyer {
                    border-radius: 5px;
                  }
                  #cards_landscape_wrap-2 .card-flyer .image-box{
                    background:  rgba(255,255,255,.2);
                    overflow: hidden;
                    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.50);
                    border-radius: 5px;
                  }
                  #cards_landscape_wrap-2 .card-flyer .image-box img{
                    -webkit-transition:all .9s ease; 
                    -moz-transition:all .9s ease; 
                    -o-transition:all .9s ease;
                    -ms-transition:all .9s ease; 
                    width: 100%;
                    height: 200px;
                  }
                  #cards_landscape_wrap-2 .card-flyer:hover .image-box img{
                    opacity: 0.7;
                    -webkit-transform:scale(1.15);
                    -moz-transform:scale(1.15);
                    -ms-transform:scale(1.15);
                    -o-transform:scale(1.15);
                    transform:scale(1.15);
                  }
                  #cards_landscape_wrap-2 .card-flyer .text-box{
                    text-align: center;
                    width:100%;
                    height:40vh;
                  }
                  #cards_landscape_wrap-2 .card-flyer .text-box .text-container{
                    padding: 8px 18px;
                  }
                  #cards_landscape_wrap-2 .card-flyer{
                    background: rgba(255,255,255,.2);
                    margin-top: 50px;
                    -webkit-transition: all 0.2s ease-in;
                    -moz-transition: all 0.2s ease-in;
                    -ms-transition: all 0.2s ease-in;
                    -o-transition: all 0.2s ease-in;
                    transition: all 0.2s ease-in;
                    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
                  }
                  #cards_landscape_wrap-2 .card-flyer:hover{
                    background: rgba(255,255,255,.2);
                    box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
                    -webkit-transition: all 0.2s ease-in;
                    -moz-transition: all 0.2s ease-in;
                    -ms-transition: all 0.2s ease-in;
                    -o-transition: all 0.2s ease-in;
                    transition: all 0.2s ease-in;
                    margin-top: 50px;
                  }
                  #cards_landscape_wrap-2 .card-flyer .text-box p{
                    margin-top: 10px;
                    margin-bottom: 0px;
                    padding-bottom: 0px; 
                    font-size: 14px;
                    letter-spacing: 1px;
                    color: #000000;
                  }
                  #cards_landscape_wrap-2 .card-flyer .text-box h6{
                    margin-top: 0px;
                    margin-bottom: 4px; 
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-family: 'Roboto Black', sans-serif;
                    letter-spacing: 1px;
                    color: #00acc1;
                  }
                  .sticky{
                    position:'-webkit-sticky',
                    position:'sticky'
                  }
                    $title-font: 500 18px/1.2 'Ubuntu', sans-serif;
                    $cool-grey: #555;
                    $box-shadow-bottom-heavy: 0 15px 20px rgba(0, 0, 0, 0.1);
                    $transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            
                   
            
                    .img {
                      margin: 0 auto;
                      padding: 0;
                    }
            
                    hr {
                      margin-top: 20px;
                      margin-bottom: 20px;
                      border: 0;
                      border-top: 1px solid #b5b5b5;
                  }
            
                    .clearance,
                    .lastfew {
                      position: absolute;
                      top: 6px;
                      left: 123px;
                      width: 35px;
                      height: 35px;
                    }
            
                    .clearance {                   
                      background-repeat: no-repeat;
                    }
            
                    .lastfew {
                      background: url(https://www.dropbox.com/s/11uedn6mffchd9z/lastfew-crcl.png?raw=1);
                    }
            
                    .box h3 {
                      font: $title-font;
                      color: $cool-grey;
                      padding: 1px 0 0 0;
                    }
            
                    .highlighted {                    
                      animation: shake 1s ease-in-out;
                      /* When the animation is finished, start again */
                      animation-iteration-count: infinite;
                      // border: 1px solid #000000bd;
                      // background-color: #fff !important;
                      // box-shadow: 0px 0px 6px 9px #a5744596 !important;
                    }
            
                    @keyframes shake {
                      // 0% { transform:  scale(1); }
                      // 10% { transform: scale(1.02); }
                      // 20% { transform: scale(1); }
                      // 90% { transform: scale(1.02); }
                      // 100% { transform: scale(1); }
            
                      0% {
                        box-shadow: 0 0 0px 2px #52ceba;
                      }
                      50% {
                        box-shadow: 0 0 0px 6px #52ceba;
                      }
                      100% {
                        box-shadow: 0 0 0px 2px #52ceba;
                      }
                    }
                    
                    .row{
                      margin: 0,
                      padding: 0,
                      // display: grid;
                      // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
                      // grid-gap: 12px;
                    }
                    
                    .stageName{
                      font-family: 'Open Sans';
                      color: #555;
                      margin: 0;
                      padding: 0;
                      font-weight: 800;
                      font-size: 9px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      padding: 5px 16px;
                    }
                    .stageNumber{
                      font-family: 'Open Sans';
                      Font-weight: 800;
                      color: #555;
                      text-align: left;
                      margin-left: 13px;                   
                      font-size: 15px;
                      // margin-right: 66px;
                    }
            
                    .box p span {
                      color: #45A8D6;
                    }
            
                    ul.colourswatches {
                      height: 20px;
                      margin: 0;
                      padding: 20px 0 0 0;
                      list-style: none;
                      text-align: center;
                      line-height: 20px;
                    }
            
                    ul.colourswatches li {
                      color: transparent;
                      text-shadow: none;
                      display: inline-block;
                      overflow: hidden;
                      width: 20px;
                      height: 20px;
                      margin: 0 3px;
                      vertical-align: top;
                    }
            
                   
            
                    .box {
                      position: relative;
                      width: 100px;
                      height: 100px;
                      margin-left: 20px;
                      text-align: center;
                      background-color: #eaeaea85;
                      -webkit-transition: $transition;
                      cursor: pointer;
                      border-radius: 3px;
                      background-color: rgba(255,255,255,0.7);
                      box-shadow: 0 1px 4px 0 rgb(0 0 0 / 37%);
                      transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                      margin-bottom: 20px
                    }
            
                    .box::after {
                      // content: "";
                      // border-radius: 5px;
                      // position: absolute;
                      // z-index: -1;
                      // top: 0;
                      // left: 0;
                      // width: 100%;
                      // height: 100%;
                      // box-shadow: $box-shadow-bottom-heavy;
                      // opacity: 0;
                      // -webkit-transition: $transition;
                      // transition: $transition;
                    }
            
                    .box:hover {
                      // -webkit-transform: scale(1.05, 1.05);
                      // transform: scale(1.05, 1.05);
                      box-shadow: 0px 6px 5px 1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 30%);
                    }
            
                    .box:hover::after {
                      opacity: 1;
                    }
                    .center-col {
                      flex: 1;
                      background: #aaa;
                      overflow-y: scroll;
                    }
                    .vertical-slider{
                      width: 18px;
                      height: 118px;
                      background-color: #ffffff75;
                      margin-left: 46px; 
                      border-radius: 20px;       
                    }
                    .example-thumb{
                      background-color: white;
                      border-radius: 20px;
                      /* margin: 4px 4px; */
                      text-align: center;
                      /* width: 30px; */
                      /* height: 30px; */
                      /* border-radius: 100%; */
                      padding: 7px 2px;
                      width: 33px;  
                      left: -8px !important;
                      cursor: pointer;
                  }
                  .mark{
                    display: none !important;
                  }
                  #progress-height{
                   
                    overflow: hidden; 
                    position: relative;
                  }
                  @media screen and (min-width:768px){
                    .containerr{
                      width: 63vw !important;
                    }
                  
            
                  }
                  @media screen and (min-width:992px){
                    .containerr{
                      width:  63vw !important;
                    }
                   
                    
                  }
                    @media screen and (min-width:1470px){
                      .containerr{
                        width: 63vw !important;
                      }
                    }
                    @media only screen and (max-width: 3200px) and (min-width: 2450px){
                      #reactScroller{
                        right: 64vw !important;
                        top: 28% !important;
                      }
                    
                    .sticky{
                      margin-right: 33vw !important;
                    }
                  }
                    @media only screen and (max-width: 3840px) and (min-width: 2750px){
                      #reactScroller{
                        right: 68vw !important;
                        top: 17% !important;
                    
                      }
                     
                    .sticky{
                      margin-right: 37vw !important;
                    }
                  }
                    @media only screen and (max-width: 2200px) and (min-width: 1680px){
                      #reactScroller{
                        right: 32vw !important;
                        top: 33% !important;
                      }
                    
                    .sticky{
                      margin-right: 5vw !important;
                    }
                    }
                    @media only screen and (max-width: 1470px) and (min-width: 1200px){
                      .containerr{
                        width: 80vw !important;
                      }
                      #reactScroller{
                        right: 24vw !important;
                        top: 45% !important;
                      }
                     
                    .sticky{
                      margin-right: 20vw !important;
                    }
                    }
                    @media only screen and (max-width: 1280px) and (min-width: 900px){
                      .containerr{
                        width: 80vw !important;
                      }
                      #reactScroller{
                        right: 20vw !important;
                        top: 60% !important;
                      }
                   
                    .sticky{
                      margin-right: 20vw !important;
                    }
                    }
                    @media only screen and (max-width: 1100px) and (min-width: 1024px){
                      #reactScroller{
                        right: 6vw !important;
                        top: 35% !important;
                      }
                   
                    .sticky{
                      margin-right: 20vw !important;
                    }
                    }
                    
            
                  `}
                                        </style>

                                        <div id="cards_landscape_wrap-2">
                                            <div className="containerr">
                                                <div>
                                                    <div className="sticky">
                                                        <h1
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize:
                                                                    "15px",
                                                                fontFamily:
                                                                    "Montserrat",
                                                                letterSpacing:
                                                                    "7px",
                                                            }}
                                                        >
                                                            {Paths?.name}
                                                        </h1>
                                                    </div>

                                                    <div
                                                        className="main"
                                                        style={{ width: "95%" }}
                                                    >
                                                        {list}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div id="progress-height">
                                        <div
                                            id="reactScroller"
                                            style={myComponent}
                                        >
                                            <DensitySmall
                                                style={tableOfContent}
                                                onClick={handleClickOpen}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ParallaxEffect>
                    )}

                    <div>
                        <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            style={{ borderRadius: "0px" }}
                        >
                            <BootstrapDialogTitle
                                id="customized-dialog-title"
                                onClose={handleClose}
                                style={{ fontSize: "2.25rem", color: "#555" }}
                            >
                                Overview
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                {beltlist.map((belt, i) => {
                                    return (
                                        <TableOfContent belt={belt} index={i} />
                                    );
                                })}
                            </DialogContent>
                        </BootstrapDialog>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "20px",
                    marginLeft: "20px",
                }}
            >
                <button
                    onClick={() => setView("")}
                    style={{
                        marginTop: "15px",
                        width: "10vw",
                        textAlign: "center",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid black",
                    }}
                >
                    Back
                </button>
            </div>
        </>
    );
};

const Course = ({
    setView,
    selectedChild,
    isLoading,
    setIsLoading,
    gameId,
    selectedPath,
    sessionIdMap,
}) => {
    const classroomSessionId = localStorage.getItem("ClassroomSessionId");
    const [comment, setComment] = useState(" ");

    const [game, setGame] = useState(" ");
    const [note, setNote] = useState(" ");

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(
                `/classroom/manager/getDeBriefData`,
                {
                    ClassroomSessionId: sessionIdMap,
                    gameId: gameId.id,
                },
                {
                    headers: {
                        Authorization: userToken,
                    },
                },
            )
            .then((response) => {
                if (!response.data.success) {
                    Swal.fire({
                        title: "No Comment Found",
                        text: "There is no comment for selected session",
                        confirmButtonText: "OK",
                    });
                } else {
                    console.log(response.data, "get brief");
                    setComment(response.data.BriefData[0].comment);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (gameId.id) {
            setIsLoading(true);
            axios
                .get(`/classroom/manager/deBriefData/${gameId.id}`, {
                    headers: {
                        Authorization: userToken,
                    },
                })
                .then((response) => {
                    console.log(response.data.sendDebrief);

                    console.log(gameId, "game id");
                    setGame(response.data.sendDebrief.name);
                    const sendDebrief = response.data.sendDebrief;
                    setNote(
                        JSON.parse(sendDebrief?.whatToSay).whatToSay.map(
                            (el) => `${el}\n`,
                        ),
                    );

                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, []);

    if (isLoading) {
        return (
            <div
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10%",
                }}
                className="w-100 gap-6"
            >
                <ReactLoading
                    type="spin"
                    color="#000000"
                    height={21}
                    width={21}
                />
            </div>
        );
    }

    return (
        <>
            <div>
                <h5>Child Course Debrief</h5>

                <label htmlFor="game" style={{ fontSize: "13px" }}>
                    Game
                </label>

                <div
                    style={{
                        display: "flex",
                        marginRight: "20px",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div className="phSelect">
                            <input
                                disabled
                                className="phSelect"
                                name="path"
                                value={`${selectedChild.firstName} ${selectedChild.lastName} - ${selectedPath.name}- ${game} `}
                            />
                        </div>
                    </div>
                </div>

                <label
                    htmlFor=""
                    style={{ fontSize: "13px", paddingBottom: "5px" }}
                >
                    Debrief Notes
                </label>
                <div
                    className="PHlogo"
                    style={{
                        display: "flex",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        marginRight: "10vw",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            marginRight: "20px",
                            alignItems: "center",
                        }}
                    >
                        <textarea
                            value={note}
                            name="Describe"
                            disabled
                            id=""
                            style={{
                                width: "48vw",
                                height: "100px",
                                resize: "none",
                                border: "1px solid black",
                                background: "#F2F2F2",
                                borderRadius: "3px",
                                fontSize: "14px",
                                padding: "2px 10px",
                            }}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div
                            className="PHline"
                            style={{
                                borderLeft: "3px solid #c1c1c1",
                                marginRight: "10px",
                                borderRadius: "3px",
                                marginLeft: "20px",
                            }}
                        />
                        <div className="phTips">
                            This briefing will list what you need to to say to
                            'prime' the children, lesson objectives etc.
                        </div>
                    </div>
                </div>
                <label
                    htmlFor=""
                    style={{ fontSize: "13px", paddingBottom: "5px" }}
                >
                    Teacher Comments
                </label>
                <div
                    className="PHlogo"
                    style={{
                        display: "flex",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        marginRight: "10vw",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            marginRight: "20px",
                            alignItems: "center",
                        }}
                    >
                        <textarea
                            name="Describe"
                            disabled
                            value={comment}
                            id=""
                            style={{
                                width: "48vw",
                                height: "100px",
                                resize: "none",
                                border: "1px solid black",
                                background: "#F2F2F2",
                                borderRadius: "3px",
                                fontSize: "14px",
                                padding: "2px 10px",
                            }}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div
                            className="PHline"
                            style={{
                                borderLeft: "3px solid #c1c1c1",
                                marginRight: "10px",
                                borderRadius: "3px",
                                marginLeft: "20px",
                            }}
                        />
                        <div className="phTips">
                            This section will contain the guide to carry out
                            this lesson.
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "20px",
                        marginTop: "20px",
                        marginLeft: "20px",
                    }}
                >
                    <button
                        onClick={() => setView(" ")}
                        style={{
                            width: "7vw",
                            borderRadius: "5px",
                            textAlign: "center",
                            padding: "5px 0px",
                            background: "rgb(38, 113, 139)",
                            color: "white",
                            border: "1px solid black",
                        }}
                    >
                        Back
                    </button>
                </div>
            </div>
        </>
    );
};
