const initialState = {
    code: '',
    globalSpritesData: [],
    globalBackdropsData: [],
    currentLang: 'javascript',
    javascriptCode: "",
    luaCode: "",
    cSharpCode: "",
    goCode: "",
    pythonCode: "",
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case 'setSpritesState':
            console.log("--------------------------------------->", action.data);
            return Object.assign({}, state, {
                globalSpritesData: action.data
            });
        case 'setBackdropsState':
            return Object.assign({}, state, {
                globalBackdropsData: action.data
            });
        case 'setCurrentLang':
            return Object.assign({}, state, {
                currentLang: action.data
            });
        case 'setJavascriptLang':
            return Object.assign({}, state, {
                javascriptCode: action.data
            });
        case 'setPythonLang':
            return Object.assign({}, state, {
                pythonCode: action.data
            });
        case 'setGoLang':
            return Object.assign({}, state, {
                goCode: action.data
            });
        case 'setLuaLang':
            return Object.assign({}, state, {
                luaCode: action.data
            });
        case 'setCSharpLang':
            return Object.assign({}, state, {
                cSharpCode: action.data
            });
        case 'setCode':
            return Object.assign({}, state, {
                code: action.data
            });
        default:
            return state;
    }
};

const getAssets = function (type, data) {
    switch (type) {
        case 'setSpritesState':
            return {
                type: type,
                data: data
            };
        case 'setBackdropsState':
            return {
                type: type,
                data: data
            };
        case 'setCurrentLang':
            return {
                type: type,
                data: data
            };
        case 'setJavascriptLang':
            return {
                type: type,
                data: data
            };
        case 'setPythonLang':
            return {
                type: type,
                data: data
            };
        case 'setGoLang':
            return {
                type: type,
                data: data
            };
        case 'setLuaLang':
            return {
                type: type,
                data: data
            };
        case 'setCSharpLang':
            return {
                type: type,
                data: data
            };
        case 'setCode':
            return {
                type: type,
                data: data
            };
        default:
            break;
    }

};

export {
    reducer as default,
    initialState as assetsDataInitialState,
    getAssets
};
