import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useDriverSteps } from './useDriverSteps';
import "./gui.css";
import styles from "./gui.css";
export const useDriver = () => {
    const steps = useDriverSteps();

    const driverInstance = driver({
        showProgress: false,
        popoverClass: styles.popoverTheme,
        nextBtnText: 'Next',
        prevBtnText: 'Previous',
        doneBtnText: 'Done',
        showButtons: ['next', 'previous'],
        onPopoverRender: () => {
            const popover = document.querySelector('.driver-popover');

            if (popover) {
                popover.style.position = 'fixed';
                popover.style.zIndex = '99999';
            }
            const navButtonContainer = document.querySelector(
                '.driver-popover-navigation-btns'
            );
            if (navButtonContainer) {
                navButtonContainer.style.display = 'flex';
                navButtonContainer.style.columnGap = '10px';
            }

            const prevButton = document.querySelector('.driver-popover-prev-btn');
            if (prevButton) {
                prevButton.style.backgroundColor = '#527dff';
                prevButton.style.border = 'none';
                prevButton.style.color = 'white';
                prevButton.style.borderRadius = '5px';
                prevButton.style.padding = '8px 15px';
                prevButton.style.outline = 'none';
            }

            const nextButton = document.querySelector('.driver-popover-next-btn');
            if (nextButton) {
                nextButton.style.backgroundColor = '#527dff';
                nextButton.style.border = 'none';
                nextButton.style.color = 'white';
                nextButton.style.borderRadius = '5px';
                nextButton.style.padding = '8px 15px';
                nextButton.style.outline = 'none';
            }

            const disabledButtons = document.querySelectorAll(
                '.driver-popover-btn-disabled'
            );
            disabledButtons.forEach(button => {
                button.style.backgroundColor = '#d3d3d3';
                button.style.cursor = 'not-allowed';
                button.style.opacity = '0.5';
            });
        },
        steps,
        onDestroyStarted: () => {
            if (!driverInstance.hasNextStep()) {
                driverInstance.destroy();
                localStorage.setItem('tourCompleted', 'true');
            }
        }
    });

    return driverInstance;
};
