import { defineMessages, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import VM from 'scratch-vm';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import Controls from '../../containers/controls.jsx';
import { getStageDimensions } from '../../lib/screen-utils';
import { STAGE_SIZE_MODES } from '../../lib/layout-constants';
import { getUrl } from '../../lib/get-url/get-url.js';

import fullScreenIcon from './18.png';
import unFullScreenIcon from './icon--unfullscreen.svg';

import scratchLogo from '../menu-bar/scratch-logo.svg';
import styles from './stage-header.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const isDemo = getUrl();

const messages = defineMessages({
    largeStageSizeMessage: {
        defaultMessage: 'Switch to large stage',
        description: 'Button to change stage size to large',
        id: 'gui.stageHeader.stageSizeLarge'
    },
    smallStageSizeMessage: {
        defaultMessage: 'Switch to small stage',
        description: 'Button to change stage size to small',
        id: 'gui.stageHeader.stageSizeSmall'
    },
    fullStageSizeMessage: {
        defaultMessage: 'Enter full screen mode',
        description: 'Button to change stage size to full screen',
        id: 'gui.stageHeader.stageSizeFull'
    },
    unFullStageSizeMessage: {
        defaultMessage: 'Exit full screen mode',
        description: 'Button to get out of full screen mode',
        id: 'gui.stageHeader.stageSizeUnFull'
    },
    fullscreenControl: {
        defaultMessage: 'Full Screen Control',
        description: 'Button to enter/exit full screen mode',
        id: 'gui.stageHeader.fullscreenControl'
    }
});

const StageHeaderComponent = function (props) {
    const {
        isFullScreen,
        isPlayerOnly,
        onKeyPress,
        isObjectiveOpen,
        setIsObjectiveOpen,
        showObjectiveTab,
        setShowObjectiveTab,
        onSetStageLarge,
        onSetStageSmall,
        onSetStageFull,
        onSetStageUnFull,
        showBranding,
        stageSizeMode,
        vm
    } = props;

    const [canvasHW, setCanvasHW] = useState({});

    let header = null;
    const width = window.innerWidth;

    if (isFullScreen) {
        const stageDimensions = getStageDimensions(null, true);
        const stageButton = showBranding ? (
            <div className={styles.embedScratchLogo}>
                <a
                    // href="https://scratch.mit.edu"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Scratch"
                        src={scratchLogo}
                    />
                </a>
            </div>
        ) : (
            <Button
                className={styles.stageButton}
                onClick={() => {
                    const outputCanvas =
                        document.getElementById('outputCanvas');
                    if (outputCanvas) {
                        outputCanvas.setAttribute(
                            'height',
                            `${canvasHW.height}`
                        );
                        outputCanvas.setAttribute('width', `${canvasHW.width}`);
                        // outputCanvas.style.height = `${canvasHW.height}px`;
                        // outputCanvas.style.width = `${canvasHW.width}px`;
                    }
                    onSetStageUnFull();
                    document.getElementsByTagName('body')[0].style.overflow =
                        'auto';
                }}
                onKeyPress={onKeyPress}
            >
                <img
                    alt={props.intl.formatMessage(
                        messages.unFullStageSizeMessage
                    )}
                    className={styles.stageButtonIcon}
                    draggable={false}
                    src={unFullScreenIcon}
                    title={props.intl.formatMessage(messages.fullscreenControl)}
                />
            </Button>
        );
        header = (
            <Box
                className={styles.stageHeaderWrapperOverlay}
            >
                <Box
                    className={styles.stageMenuWrapper}
                    style={{ width: stageDimensions.width }}
                >
                    <Controls vm={vm} />
                    {stageButton}
                </Box>
            </Box>
        );
    } else {
        const stageControls = isPlayerOnly ? (
            []
        ) : (
            <div className={styles.stageSizeToggleGroup}>

            </div>
        );
        header = (
            <Box
                style={{ backgroundColor: '#142B60' }}
                className={styles.stageHeaderWrapper}
            >
                <Box className={styles.stageMenuWrapper}>
                    <Controls vm={vm} />
                    <div className={styles.stageSizeRow}>
                        {stageControls}
                        {width <= 1280 ? (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', height: 'fit-content', alignItems: 'center', justifyContent: 'center', backgroundColor: showObjectiveTab ? 'rgba(0,0,0,0.5)' : 'transparent', borderRadius: '8px', padding: '10px', marginRight: '10px' }}>
                                    <InfoOutlinedIcon
                                        sx={{ fontSize: '24px', color: '#ffffff', cursor: 'pointer' }}
                                        onClick={() => setShowObjectiveTab(!showObjectiveTab)}
                                        className='stageInfoIcon'
                                    />
                                </div>
                            </div>
                        ) : null}

                        <div style={isDemo ? { display: 'none' } : {}}>
                            {/* <Button
                                className={styles.stageButton}

                            > */}
                            <img
                                alt={props.intl.formatMessage(
                                    messages.fullStageSizeMessage
                                )}
                                className="fullscreen"
                                draggable={false}
                                src={fullScreenIcon}
                                title={props.intl.formatMessage(
                                    messages.fullscreenControl
                                )}
                                style={{ width: '60px', cursor: 'pointer' }}
                                onClick={() => {
                                    const outputCanvas =
                                        document.getElementById('outputCanvas');
                                    const height =
                                        outputCanvas.getAttribute('height');
                                    const width =
                                        outputCanvas.getAttribute('width');
                                    setCanvasHW({
                                        width: width,
                                        height: height
                                    });
                                    if (outputCanvas) {
                                        // outputCanvas.setAttribute("height", "806")
                                        // outputCanvas.setAttribute("width", `1100`)
                                    }
                                    document.getElementsByTagName(
                                        'body'
                                    )[0].style.overflow = 'hidden';
                                    onSetStageFull();
                                }}
                            />
                            {/* </Button> */}
                        </div>
                    </div>
                </Box>
            </Box>
        );
    }

    return header;
};

const mapStateToProps = state => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize
});

StageHeaderComponent.propTypes = {
    intl: intlShape,
    isFullScreen: PropTypes.bool.isRequired,
    isPlayerOnly: PropTypes.bool.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    onSetStageFull: PropTypes.func.isRequired,
    onSetStageLarge: PropTypes.func.isRequired,
    onSetStageSmall: PropTypes.func.isRequired,
    onSetStageUnFull: PropTypes.func.isRequired,
    isObjectiveOpen: PropTypes.bool.isRequired,
    setIsObjectiveOpen: PropTypes.func.isRequired,
    showObjectiveTab: PropTypes.bool.isRequired,
    setShowObjectiveTab: PropTypes.func.isRequired,
    showBranding: PropTypes.bool.isRequired,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    vm: PropTypes.instanceOf(VM).isRequired
};

StageHeaderComponent.defaultProps = {
    stageSizeMode: STAGE_SIZE_MODES.large
};

export default injectIntl(connect(mapStateToProps)(StageHeaderComponent));
