import PropTypes from 'prop-types';
import React from 'react';

import styles from './language-selector.css';
import eng from "../../playground/img/english.png";
import spanish from "../../playground/img/spanish.jpg";
import greek from "../../playground/img/greek.png";
import Dropdown from 'react-bootstrap/Dropdown';

// supported languages to exclude from the menu, but allow as a URL option
const ignore = [];

const languageOptions = {
    en: { code: "en", image: eng, label: "English" },
    es: { code: "es", image: spanish, label: "Español" },
    el: { code: "gr", image: greek, label: "Ελληνικά" },
};

const LanguageSelector = ({ currentLocale, label, onChange }) => (

    <Dropdown>
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
            integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
            crossorigin="anonymous"
        />
        <Dropdown.Toggle
            style={{
                background: "#f0f0f02b",
                // border: "2px solid rgb(38, 113, 139)",
                border: "none",
                height: "45px",
                padding: "10px 20px",
                marginLeft: "30px",
            }}
        >
            <img
                src={languageOptions[currentLocale].image}
                alt="Language"
                style={{ width: "35px", height: "25px" }}
            />
            <span
                style={{
                    marginLeft: "5px",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                }}
            >
                {languageOptions[currentLocale].code.toUpperCase()}
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {Object.entries(languageOptions).map(
                ([langCode, lang]) => (
                    <Dropdown.Item
                        key={langCode}
                        onClick={() => onChange(langCode)}
                    >
                        {lang.label}
                    </Dropdown.Item>
                )
            )}
        </Dropdown.Menu>
    </Dropdown>
);

LanguageSelector.propTypes = {
    currentLocale: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
};

export default LanguageSelector;
