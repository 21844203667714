exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap);", ""]);

// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    /* Setting min height/width makes the UI scroll below those sizes */\n    /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* {\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n    font-family: 'Montserrat', sans-serif;\n    z-index: 0;\n}", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};