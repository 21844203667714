/* eslint-disable require-jsdoc */
/* eslint-disable func-style */
/* eslint-disable react/prop-types, no-invalid-this, react/jsx-no-bind, no-unused-vars  */
import React, { useEffect, useState } from 'react';
import LogOut from './LogOut.js';
import ChildProgress from './ChildProgress.js';
import ParentPortal from './ParentPortal.js';

const Body = () => {
    const [childProgress, setChildProgress] = useState(false);

    const [logOut, setLogOut] = useState(false);
    const [parentPortal, setParentPortal] = useState(true);

    const projectHost = window.location.host.toString().split(':');
    const hostlink = projectHost[1] ?
        window.location.host :
        window.location.hostname;
    const userToken = localStorage.getItem('UserToken');

    useEffect(() => {
        const menuBtn = document.querySelectorAll('#menu button');
        function setMenuActive() {
            menuBtn.forEach(n => n.classList.remove('active'));
            this.classList.add('active');
        }
        menuBtn.forEach(n => n.addEventListener('click', setMenuActive));

    }, []);

    if (userToken) {
        return (
            <div>
                <div className="body">
                    <div
                        style={{ marginBottom: '40px' }}
                        className="sub-title"
                    ><h3
                        className="mt-3"
                        style={{ fontSize: '1.5rem', marginLeft: '-10px' }}
                    >{'Parent Portal'}</h3></div>
                    <div className="row pt-0 menu">
                        <div
                            className="col-md-2 mb-5 left"
                            id="menu"
                        >
                            <button
                                className={`btn-class active`}
                                onClick={() => {
                                    setParentPortal(true);
                                    setChildProgress(false) ||

                                        setLogOut(false);
                                }
                                }
                            >
                                {'My Portal'}
                            </button>
                            <button
                                className={`btn-class`}
                                onClick={() => {
                                    setParentPortal(false);
                                    setChildProgress(true) ||
                                        setLogOut(false);
                                }
                                }
                            >
                                {'Child Portal'}
                            </button>

                            <button
                                className={`btn-class`}
                                onClick={() => {
                                    setParentPortal(false);
                                    setChildProgress(false) ||
                                        setLogOut(true);

                                }
                                }
                            >
                                {'Log Out'}
                            </button>
                        </div>
                        <div className="col-md-9 right pe-0">
                            <div>
                                {parentPortal ? (<><ParentPortal /> </>) : null}
                            </div>
                            <div>
                                {childProgress ? (<><ChildProgress /> </>) : null}
                            </div>
                            <div>
                                {logOut ? (<><LogOut /> </>) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    window.location.href = `http://${hostlink}/parentLogin.html`;

};

export default Body;
