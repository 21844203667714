import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import VM from "scratch-vm";
import { connect, useSelector } from "react-redux";
import Box from "../box/box.jsx";
import { STAGE_DISPLAY_SIZES } from "../../lib/layout-constants.js";
import { getUrl } from "../../lib/get-url/get-url.js";
import StageHeader from "../../containers/stage-header.jsx";
import Stage from "../../containers/stage.jsx";
import Loader from "../loader/loader.jsx";
import {
    injectIntl,
} from "react-intl";

import styles from "./stage-wrapper.css";
import { Button } from "@mui/material";
import { setObjectiveOpen } from "../../reducers/showObjective.js";
import Markdown from "react-markdown";

const isDemo = getUrl();

const StageWrapperComponent = function (props) {
    const { isFullScreen, isRtl, isRendererSupported, loading, stageSize, vm, showObjectiveTab, isObjectiveOpen, setIsObjectiveOpen } =
        props;

    const gameObjective = useSelector(
        (state) => state.scratchGui?.gameInfo?.gameObjective
    );

    const gameHintAndTips = useSelector(
        (state) => state.scratchGui?.gameInfo?.gameHintAndTips
    );
    const selectedLang = useSelector((state) => state.locales.locale);

    const removeQuotes = (text) => text.replace(/"([^"]+(?="))"/g, "$1");

    const FormatTextContent = ({ content }) => {
        return content?.map((text, index) => (
            <div className={styles.markdownContent} key={index}>
                <Markdown>{text}</Markdown>
            </div>
        ));
    };


    const formattedContent = (data) => {
        const rawContent = JSON.parse(data || "{}");

        // Access the content specific to the current language and split it by new lines
        const content = rawContent?.[selectedLang === 'el' ? 'gr' : selectedLang]?.split("\n") || [];

        // Remove quotes from each line of text
        const cleanedContent = content.map(removeQuotes);

        // Return the formatted content if it exists, otherwise display a 'No content available' message
        return cleanedContent.length ? (
            <FormatTextContent content={cleanedContent} />
        ) : (
            <p>No content available</p>
        );
    };

    return (
        <Box
            className={classNames(styles.stageWrapper, {
                [styles.fullScreen]: isFullScreen,
            })}
            dir={isRtl ? "rtl" : "ltr"}
        >
            <Box
                className={styles.stageMenuWrapper}
                style={{
                    width: "100%",
                    padding: "0px 8px",
                }}
            >
                <StageHeader stageSize={stageSize} vm={vm} />
            </Box>
            {showObjectiveTab ? (
                <Box style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', gap: '10px' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            onClick={() => setIsObjectiveOpen(true)}
                            style={{
                                flex: 1,
                                backgroundColor: !isObjectiveOpen ? "#142b60" : "white",
                                color: !isObjectiveOpen ? "white" : "#142b60",
                                borderTopLeftRadius: 8,
                                borderBottomLeftRadius: 8,
                                border: isObjectiveOpen ? "2px solid #142b60" : "2px solid transparent",
                            }}
                            className={`objective`}
                        >
                            Objective
                        </Button>
                        <Button
                            onClick={() => setIsObjectiveOpen(false)}
                            style={{
                                backgroundColor: isObjectiveOpen ? "#142b60" : "white",
                                flex: 1,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                color: isObjectiveOpen ? "white" : "#142b60",
                                border: !isObjectiveOpen ? "2px solid #142b60" : "2px solid transparent",
                            }}
                            className="hints"
                        >
                            Hints & Tips
                        </Button>
                    </div>

                    <div
                        style={{
                            backgroundColor: "#ffffff",
                            color: "#142b60",
                            border: '2px solid #142b60',
                            borderRadius: 8,
                            display: isObjectiveOpen ? "block" : "none",
                            overflow: "auto",
                            padding: '10px',
                            height: "400px",
                        }}

                    >
                        {formattedContent(gameObjective)}
                    </div>

                    <div
                        style={{
                            backgroundColor: "#ffffff",
                            color: "#142b60",
                            border: '2px solid #142b60',
                            borderRadius: 8,
                            display: !isObjectiveOpen ? "block" : "none",
                            overflow: "auto",
                            padding: '10px',
                            height: "400px",

                        }}
                        className={styles.browsContent}
                    >
                        {formattedContent(gameHintAndTips)}
                    </div>
                </Box>
            ) : (
                <Box
                    className={styles.stageCanvasWrapper}

                >
                    {isRendererSupported ? (
                        <Stage stageSize={stageSize} vm={vm} />
                    ) : null}
                </Box>
            )}

            {loading ? <Loader isFullScreen={isFullScreen} /> : null}
        </Box>
    );
};

StageWrapperComponent.propTypes = {
    isFullScreen: PropTypes.bool,
    isRendererSupported: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    showObjectiveTab: PropTypes.bool,
    setIsObjectiveOpen: PropTypes.func.isRequired,
    isObjectiveOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
    showObjectiveTab: state.scratchGui.showObjective.showObjectiveTab,
    isObjectiveOpen: state.scratchGui.showObjective.isObjectiveOpen,
});

const mapDispatchToProps = dispatch => ({
    setIsObjectiveOpen: isObjectiveOpen => dispatch(setObjectiveOpen(isObjectiveOpen)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(StageWrapperComponent));
