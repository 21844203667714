import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import axios from '../baseurl';
import Swal from 'sweetalert2';
const userToken = localStorage.getItem('UserToken');
const parentId = localStorage.getItem('UserId');

function ParentPortal () {
    const [view, setView] = useState('default');
    const [parentInfo, setParentInfo] = useState({});
    const [child, setChild] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [updatedParentInfo, setUpdatedParentInfo] = useState({});

    useEffect(() => {
        if (parentId) {
            setIsLoading(true);

            axios
                .get(`/classroom/manager/getParentInfo/${parentId}`, {
                    headers: {
                        Authorization: userToken
                    }
                })
                .then(response => {
                    const parentInfo = response.data.parentsInfo[0];
                    setParentInfo(parentInfo);
                    setUpdatedParentInfo(parentInfo);
                    setChild(response.data.parentsInfo[0].children);
                    console.log(response.data.parentsInfo[0].children);

                    const fullName = `${parentInfo.firstname} ${parentInfo.lastname}`;
                    localStorage.setItem('parentName', fullName);
                    window.dispatchEvent(new Event('storage'));
                    setIsLoading(false);
                    console.log(parentInfo);

                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            alert('There is no parent');
        }

    }, [parentId, userToken]);
    if (userToken){
        return (
            <div>
                {switcher(
                    view,
                    setView,
                    child,
                    isLoading,
                    parentInfo,
                    updatedParentInfo,
                    setIsLoading,
                    setUpdatedParentInfo,
                    setParentInfo
                )}
            </div>
        );
    }
    window.location.href = `http://${hostlink}/parentLogin.html`;
}

const switcher = (
    view,
    setView,
    child,
    isLoading,
    parentInfo,
    updatedParentInfo,
    setIsLoading,
    setUpdatedParentInfo,
    setParentInfo
) => {
    switch (view) {
    case 'subscriptions':
        return (
            <Subscription
                setView={setView}
                child={child}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
            />
        );

    default:
        return (
            <ParentInfo
                setView={setView}
                isLoading={isLoading}
                parentInfo={parentInfo}
                updatedParentInfo={updatedParentInfo}
                setIsLoading={setIsLoading}
                setUpdatedParentInfo={setUpdatedParentInfo}
                setParentInfo={setParentInfo}
            />
        );
    }
};

const ParentInfo = ({
    setView,
    isLoading,
    parentInfo,
    updatedParentInfo,
    setIsLoading,
    setUpdatedParentInfo,
    setParentInfo
}) => {


    const handleInputChange = event => {
        const {name, value} = event.target;
        setUpdatedParentInfo(prevParentInfo => ({
            ...prevParentInfo,
            [name]: value
        }));
        localStorage.setItem(
            'parentName',
            `${updatedParentInfo.firstname} ${updatedParentInfo.lastname}`
        );


    };


    const handleSubmit = () => {

        if (!updatedParentInfo.firstname || !updatedParentInfo.lastname || !updatedParentInfo.email || !updatedParentInfo.phone) {
            alert('Please fill in all fields.');
            return;
        }

        if (updatedParentInfo.phone.length < 10) {
            alert('Please add 10 digit number.');
            return;
        }

        setIsLoading(true);

        axios
            .post(`/classroom/manager/updateParentInfo`, updatedParentInfo, {
                headers: {
                    Authorization: userToken
                }
            })
            .then(response => {
                const updatedParentInfo = response.data.parentsInfo;
                setParentInfo(updatedParentInfo);

                setIsLoading(false);
                console.log(updatedParentInfo, 'update');

                Swal.fire({
                    title: 'Updated',
                    text: 'Details updated successfully',
                    confirmButtonText: 'OK'
                });

                // window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
        localStorage.setItem(
            'parentName',
            `${updatedParentInfo.firstname} ${updatedParentInfo.lastname}`
        );
        window.dispatchEvent(new Event('storage'));


    };

    if (isLoading) {
        return (
            <div
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10%'
                }}
                className="w-100 gap-6"
            >
                <ReactLoading
                    type="spin"
                    color="#000000"
                    height={21}
                    width={21}
                />
            </div>
        );
    }

    return (
        <>
            <div style={{marginLeft: '30px'}}>
                <h4>My Portal</h4>
                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '20px'
                    }}
                >
                    <div>
                        <label>First Name </label>

                        <input
                            value={updatedParentInfo.firstname}
                            name="firstname"
                            onChange={handleInputChange}
                            style={{
                                width: '18vw',
                                borderRadius: '3px',
                                padding: '2px 10px 2px 5px',
                                border: '1px solid',
                                marginTop: '10px',
                                marginLeft: '19px',
                                background: '#F5F5F5'
                            }}
                            type="text"
                            placeholder="Parent First Name"
                            required
                        />
                    </div>
                    <div className="last-name">
                        <label style={{marginTop: '5px'}}>Last Name </label>

                        <input
                            value={updatedParentInfo.lastname}
                            name="lastname"
                            onChange={handleInputChange}
                            style={{
                                width: '18vw',
                                borderRadius: '3px',
                                padding: '2px 10px 2px 5px',
                                border: '1px solid',
                                marginTop: '10px',
                                marginLeft: '19px',
                                background: '#F5F5F5'
                            }}
                            type="text"
                            placeholder="Parent Last Name"
                            required
                        />
                    </div>
                    <div>
                        <label>Email </label>
                        <input
                            value={updatedParentInfo.email}
                            name="email"
                            onChange={handleInputChange}
                            style={{
                                width: '18vw',
                                borderRadius: '3px',
                                padding: '2px 10px 2px 5px',
                                border: '1px solid',
                                marginTop: '10px',
                                marginLeft: '57px',
                                background: '#F5F5F5'
                            }}
                            type="email"
                            placeholder="Parent Email Adress"
                            required
                        />
                    </div>
                    <div style={{display: 'flex'}}>
                        <label>Mobile </label>
                        <input

                            value={updatedParentInfo.phone}
                            onChange={handleInputChange}
                            style={{
                                width: '18vw',
                                borderRadius: '3px',
                                padding: '2px 10px 2px 5px',
                                border: '1px solid',
                                marginTop: '10px',
                                marginLeft: '46px',
                                background: '#F5F5F5'

                            }}
                            type="tel"
                            minLength="10"
                            maxLength="10"
                            pattern="[0-9]{10}"
                            required
                            placeholder="+44 786543420"
                            name="phone"
                            title="Please enter a 10-digit phone number"
                        />
                        {updatedParentInfo.phone && updatedParentInfo.phone.length !== 10 && (
                            <div style={{color: 'red', marginLeft: '23px', marginTop: '10px'}}>Mobile number should be 10 digits</div>
                        )}
                    </div>
                </form>
                <div
                    style={{
                        display: 'flex',
                        gap: '20px',
                        marginTop: '30px'
                    }}
                >
                    <button
                        id="updated-button"
                        onClick={handleSubmit}

                        style={{
                            marginTop: '21px',
                            width: '10vw',
                            textAlign: 'center',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid black',
                            color: 'white',
                            background: 'rgb(38, 113, 139)'
                        }}
                    >
                        Update
                    </button>
                    <button
                        onClick={() => setView('subscriptions')}
                        style={{
                            marginTop: '21px',
                            width: '13vw',
                            textAlign: 'center',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid black',
                            color: 'white',
                            background: 'rgb(38, 113, 139)'
                        }}
                    >
                        View Subscription
                    </button>
                </div>
            </div>
        </>
    );
};

const Subscription = ({setView, child, isLoading, setIsLoading}) => {
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const childIds = child.map(c => c.id);
        const promises = childIds.map(childId => axios.post(
            `/classroom/manager/getChildSubscriptions`,
            {childId: childId},
            {
                headers: {
                    Authorization: userToken
                }
            }
        ));
        Promise.all(promises)
            .then(responses => {
                const subscriptions = responses.map(
                    response => response.data.bookedfor
                );
                console.log(subscriptions);
                setSubscriptions(subscriptions);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }, [child, userToken]);

    if (isLoading) {
        return (
            <div
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10%'
                }}
                className="w-100 gap-6"
            >
                <ReactLoading
                    type="spin"
                    color="#000000"
                    height={21}
                    width={21}
                />
            </div>
        );
    }

    return (
        <div
            className="d-flex flex-column w-100 gap-6"
            style={{marginLeft: '30px'}}
        >
            <h4>View Subscription</h4>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                {child.map((child, index) => (
                    <>
                        <label
                            key={index}
                            style={{
                                fontSize: '13px',
                                marginBottom: '5px',
                                marginTop: '15px'
                            }}
                        >
                            {`${child.firstname} ${child.lastname}`}
                        </label>
                        <textarea
                            value={
                                subscriptions.length > 0 ?
                                    subscriptions[index]
                                        .map(
                                            subscription =>
                                                `${
                                                    subscription.classroomSession ?
                                                        `${
                                                                subscription
                                                                    .classroomSession
                                                                    .path?.name
                                                        }\n ${
                                                                subscription
                                                                    .classroomSession
                                                                    .schoolId
                                                                    ?.name
                                                        }${
                                                            subscription
                                                                .classroomSession
                                                                .session ?
                                                                ` - ${subscription.classroomSession.session.from} to ${subscription.classroomSession.session.to}` :
                                                                ''
                                                        }` :
                                                        ''
                                                }`
                                        )
                                        .filter(text => text !== '')
                                        .join(', ') :
                                    ''
                            }
                            disabled
                            style={{
                                width: '37vw',
                                height: '100px',
                                resize: 'none',
                                border: '1px solid black',
                                background: '#F2F2F2',
                                borderRadius: '5px',
                                fontSize: '14px',
                                padding: '2px 10px',
                                marginBottom: '10px'
                            }}
                        />
                    </>
                ))}

                <button

                    onClick={() => setView('')}
                    style={{

                        marginTop: '21px',
                        marginBottom: '10px',
                        width: '12vw',
                        textAlign: 'center',
                        padding: '10px',
                        background: '#26718B',
                        borderRadius: '5px',
                        color: 'white'
                    }}
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default ParentPortal;
