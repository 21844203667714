export const errorMessages = {
    emailError: "Sorry! Enter the correct email address",
    resetEmailError: "Sorry! email account doesn't exist.",
    passwordError:
        "Sorry! Password incorrect, click 'Help with Login' to reset the password.",
    otpError: "Invalid OTP",
    matchingError: "Password does not match",
    passwordInvalid:
        "Sorry, this password does not meet our security standard. Your password must be at least 8 characters long, contain atleast one number and have a mixture of uppercase and lowercase letters",
    leaving: "Select the Game to get the lesson guide",
    sessionCodeError: "Enter a valid Session Code!",
    studentNameError: "Whoops! check spelling.",
};

export const infoMessages = {
    greeting:
        "Happy Easter Holidays! Please remember our sessions will resume after Easter holidays on 16th April",
    discontinue:
        "Once you click Good Bye! The school will be marked to be removed from system in 90 days. You can Enable the school from the modify screen.",
    leaving:
        "Once you click Good Bye! Teacher will be set to de-board in 7 days. you can enable the Teacher from the Modify screen.",
    studnetNameTip: "Enter your first or last name",
    studentFirstName: "Enter your first name",
    studentLastName: "Enter your last",
    studentSessionCodeTip: "Classroom Session code provided by the teacher",
    // debriefnotes:
    //     "Where this sits on the overall belt what this reinforces and link to preivous lesson, to reinforce progession what this lesson will check the kids. how will they know the lesson they have learnt the lesson, etc.",
    studentleaving:
        "Once you click Good Bye! The Student will be set to delete in 30 days. you can enable the Student from the Modify Enable screen.",
};
