exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "* {\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n}\n\n.ThemeButton_container_3ss1j {\n    position: relative;\n}\n\n.ThemeButton_checkbox_KfOpw {\n    opacity: 0;\n    position: absolute;\n}\n\n.ThemeButton_label_2cLHt {\n    width: 50px;\n    height: 26px;\n    background-color: #111;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    border-radius: 50px;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: justify;\n    -webkit-justify-content: space-between;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n    padding: 5px;\n    position: relative;\n    -webkit-transform: scale(1.5);\n        -ms-transform: scale(1.5);\n            transform: scale(1.5);\n}\n\n.ThemeButton_ball_2eP_d {\n    width: 20px;\n    height: 20px;\n    background-color: white;\n    position: absolute;\n    top: 2px;\n    left: 2px;\n    border-radius: 50%;\n    -webkit-transition: -webkit-transform 0.2s linear;\n    transition: -webkit-transform 0.2s linear;\n    transition: transform 0.2s linear;\n    transition: transform 0.2s linear, -webkit-transform 0.2s linear;\n}\n\n/*  target the elemenent after the label*/\n\n.ThemeButton_checkbox_KfOpw:checked+.ThemeButton_label_2cLHt .ThemeButton_ball_2eP_d {\n    -webkit-transform: translateX(24px);\n        -ms-transform: translateX(24px);\n            transform: translateX(24px);\n}\n\n.ThemeButton_fa-moon_3EgpT {\n    color: pink;\n}\n\n.ThemeButton_fa-sun_3pN1J {\n    color: yellow;\n}", ""]);

// exports
exports.locals = {
	"container": "ThemeButton_container_3ss1j",
	"checkbox": "ThemeButton_checkbox_KfOpw",
	"label": "ThemeButton_label_2cLHt",
	"ball": "ThemeButton_ball_2eP_d",
	"fa-moon": "ThemeButton_fa-moon_3EgpT",
	"faMoon": "ThemeButton_fa-moon_3EgpT",
	"fa-sun": "ThemeButton_fa-sun_3pN1J",
	"faSun": "ThemeButton_fa-sun_3pN1J"
};