// // Intro
// import introMove from './steps/intro-1-move.en.gif';
// import introSay from './steps/intro-2-say.en.gif';
// import introGreenFlag from './steps/intro-3-green-flag.en.gif';

// // Text to Speech
// import speechAddExtension from './steps/speech-add-extension.en.gif';
// import speechSaySomething from './steps/speech-say-something.en.png';
// import speechSetVoice from './steps/speech-set-voice.en.png';
// import speechMoveAround from './steps/speech-move-around.en.png';
// import speechAddBackdrop from './steps/pick-backdrop.LTR.gif';
// import speechAddSprite from './steps/speech-add-sprite.LTR.gif';
// import speechSong from './steps/speech-song.en.png';
// import speechChangeColor from './steps/speech-change-color.en.png';
// import speechSpin from './steps/speech-spin.en.png';
// import speechGrowShrink from './steps/speech-grow-shrink.en.png';

// // Cartoon Network
// import cnShowCharacter from './steps/cn-show-character.LTR.gif';
// import cnSay from './steps/cn-say.en.png';
// import cnGlide from './steps/cn-glide.en.png';
// import cnPickSprite from './steps/cn-pick-sprite.LTR.gif';
// import cnCollect from './steps/cn-collect.en.png';
// import cnVariable from './steps/add-variable.en.gif';
// import cnScore from './steps/cn-score.en.png';
// import cnBackdrop from './steps/cn-backdrop.en.png';

// // Add sprite
// import addSprite from './steps/add-sprite.LTR.gif';

// // Animate a name
// import namePickLetter from './steps/name-pick-letter.LTR.gif';
// import namePlaySound from './steps/name-play-sound.en.png';
// import namePickLetter2 from './steps/name-pick-letter2.LTR.gif';
// import nameChangeColor from './steps/name-change-color.en.png';
// import nameSpin from './steps/name-spin.en.png';
// import nameGrow from './steps/name-grow.en.png';

// // Make Music
// import musicPickInstrument from './steps/music-pick-instrument.LTR.gif';
// import musicPlaySound from './steps/music-play-sound.en.png';
// import musicMakeSong from './steps/music-make-song.en.png';
// import musicMakeBeat from './steps/music-make-beat.en.png';
// import musicMakeBeatbox from './steps/music-make-beatbox.en.png';

// // Chase-Game
// import chaseGameAddBackdrop from './steps/chase-game-add-backdrop.LTR.gif';
// import chaseGameAddSprite1 from './steps/chase-game-add-sprite1.LTR.gif';
// import chaseGameRightLeft from './steps/chase-game-right-left.en.png';
// import chaseGameUpDown from './steps/chase-game-up-down.en.png';
// import chaseGameAddSprite2 from './steps/chase-game-add-sprite2.LTR.gif';
// import chaseGameMoveRandomly from './steps/chase-game-move-randomly.en.png';
// import chaseGamePlaySound from './steps/chase-game-play-sound.en.png';
// import chaseGameAddVariable from './steps/add-variable.en.gif';
// import chaseGameChangeScore from './steps/chase-game-change-score.en.png';

// // Clicker-Game (Pop Game)
// import popGamePickSprite from './steps/pop-game-pick-sprite.LTR.gif';
// import popGamePlaySound from './steps/pop-game-play-sound.en.png';
// import popGameAddScore from './steps/add-variable.en.gif';
// import popGameChangeScore from './steps/pop-game-change-score.en.png';
// import popGameRandomPosition from './steps/pop-game-random-position.en.png';
// import popGameChangeColor from './steps/pop-game-change-color.en.png';
// import popGameResetScore from './steps/pop-game-reset-score.en.png';

// // Animate A Character
// import animateCharPickBackdrop from './steps/pick-backdrop.LTR.gif';
// import animateCharPickSprite from './steps/animate-char-pick-sprite.LTR.gif';
// import animateCharSaySomething from './steps/animate-char-say-something.en.png';
// import animateCharAddSound from './steps/animate-char-add-sound.en.png';
// import animateCharTalk from './steps/animate-char-talk.en.png';
// import animateCharMove from './steps/animate-char-move.en.png';
// import animateCharJump from './steps/animate-char-jump.en.png';
// import animateCharChangeColor from './steps/animate-char-change-color.en.png';

// // Tell A Story
// import storyPickBackdrop from './steps/story-pick-backdrop.LTR.gif';
// import storyPickSprite from './steps/story-pick-sprite.LTR.gif';
// import storySaySomething from './steps/story-say-something.en.png';
// import storyPickSprite2 from './steps/story-pick-sprite2.LTR.gif';
// import storyFlip from './steps/story-flip.en.gif';
// import storyConversation from './steps/story-conversation.en.png';
// import storyPickBackdrop2 from './steps/story-pick-backdrop2.LTR.gif';
// import storySwitchBackdrop from './steps/story-switch-backdrop.en.png';
// import storyHideCharacter from './steps/story-hide-character.en.png';
// import storyShowCharacter from './steps/story-show-character.en.png';

// // Video Sensing
// import videoAddExtension from './steps/video-add-extension.en.gif';
// import videoPet from './steps/video-pet.en.png';
// import videoAnimate from './steps/video-animate.en.png';
// import videoPop from './steps/video-pop.en.png';

// // Make it Fly
// import flyChooseBackdrop from './steps/fly-choose-backdrop.LTR.gif';
// import flyChooseCharacter from './steps/fly-choose-character.LTR.png';
// import flySaySomething from './steps/fly-say-something.en.png';
// import flyMoveArrows from './steps/fly-make-interactive.en.png';
// import flyChooseObject from './steps/fly-object-to-collect.LTR.png';
// import flyFlyingObject from './steps/fly-flying-heart.en.png';
// import flySelectFlyingSprite from './steps/fly-select-flyer.LTR.png';
// import flyAddScore from './steps/add-variable.en.gif';
// import flyKeepScore from './steps/fly-keep-score.en.png';
// import flyAddScenery from './steps/fly-choose-scenery.LTR.gif';
// import flyMoveScenery from './steps/fly-move-scenery.en.png';
// import flySwitchLooks from './steps/fly-switch-costume.en.png';

// // Pong
// import pongAddBackdrop from './steps/pong-add-backdrop.LTR.png';
// import pongAddBallSprite from './steps/pong-add-ball-sprite.LTR.png';
// import pongBounceAround from './steps/pong-bounce-around.en.png';
// import pongAddPaddle from './steps/pong-add-a-paddle.LTR.gif';
// import pongMoveThePaddle from './steps/pong-move-the-paddle.en.png';
// import pongSelectBallSprite from './steps/pong-select-ball.LTR.png';
// import pongAddMoreCodeToBall from './steps/pong-add-code-to-ball.en.png';
// import pongAddAScore from './steps/add-variable.en.gif';
// import pongChooseScoreFromMenu from './steps/pong-choose-score.en.png';
// import pongInsertChangeScoreBlock from './steps/pong-insert-change-score.en.png';
// import pongResetScore from './steps/pong-reset-score.en.png';
// import pongAddLineSprite from './steps/pong-add-line.LTR.gif';
// import pongGameOver from './steps/pong-game-over.en.png';

// // Imagine a World
// import imagineTypeWhatYouWant from './steps/imagine-type-what-you-want.en.png';
// import imagineClickGreenFlag from './steps/imagine-click-green-flag.en.png';
// import imagineChooseBackdrop from './steps/imagine-choose-backdrop.LTR.png';
// import imagineChooseSprite from './steps/imagine-choose-any-sprite.LTR.png';
// import imagineFlyAround from './steps/imagine-fly-around.en.png';
// import imagineChooseAnotherSprite from './steps/imagine-choose-another-sprite.LTR.png';
// import imagineLeftRight from './steps/imagine-left-right.en.png';
// import imagineUpDown from './steps/imagine-up-down.en.png';
// import imagineChangeCostumes from './steps/imagine-change-costumes.en.png';
// import imagineGlideToPoint from './steps/imagine-glide-to-point.en.png';
// import imagineGrowShrink from './steps/imagine-grow-shrink.en.png';
// import imagineChooseAnotherBackdrop from './steps/imagine-choose-another-backdrop.LTR.png';
// import imagineSwitchBackdrops from './steps/imagine-switch-backdrops.en.png';
// import imagineRecordASound from './steps/imagine-record-a-sound.en.gif';
// import imagineChooseSound from './steps/imagine-choose-sound.en.png';

// // Add a Backdrop
// import addBackdrop from './steps/add-backdrop.LTR.png';

// // Add Effects
// import addEffects from './steps/add-effects.en.png';

// // Hide and Show
// import hideAndShow from './steps/hide-show.en.png';

// // Switch Costumes
// import switchCostumes from './steps/switch-costumes.en.png';

// // Change Size
// import changeSize from './steps/change-size.en.png';

// // Spin
// import spinTurn from './steps/spin-turn.en.png';
// import spinPointInDirection from './steps/spin-point-in-direction.en.png';

// // Record a Sound
// import recordASoundSoundsTab from './steps/record-a-sound-sounds-tab.en.png';
// import recordASoundClickRecord from './steps/record-a-sound-click-record.en.png';
// import recordASoundPressRecordButton from './steps/record-a-sound-press-record-button.en.png';
// import recordASoundChooseSound from './steps/record-a-sound-choose-sound.en.png';
// import recordASoundPlayYourSound from './steps/record-a-sound-play-your-sound.en.png';

// // Use Arrow Keys
// import moveArrowKeysLeftRight from './steps/move-arrow-keys-left-right.en.png';
// import moveArrowKeysUpDown from './steps/move-arrow-keys-up-down.en.png';

// // Glide Around
// import glideAroundBackAndForth from './steps/glide-around-back-and-forth.en.png';
// import glideAroundPoint from './steps/glide-around-point.en.png';

// // Code a Cartoon
// import codeCartoonSaySomething from './steps/code-cartoon-01-say-something.en.png';
// import codeCartoonAnimate from './steps/code-cartoon-02-animate.en.png';
// import codeCartoonSelectDifferentCharacter from './steps/code-cartoon-03-select-different-character.LTR.png';
// import codeCartoonUseMinusSign from './steps/code-cartoon-04-use-minus-sign.en.png';
// import codeCartoonGrowShrink from './steps/code-cartoon-05-grow-shrink.en.png';
// import codeCartoonSelectDifferentCharacter2 from './steps/code-cartoon-06-select-another-different-character.LTR.png';
// import codeCartoonJump from './steps/code-cartoon-07-jump.en.png';
// import codeCartoonChangeScenes from './steps/code-cartoon-08-change-scenes.en.png';
// import codeCartoonGlideAround from './steps/code-cartoon-09-glide-around.en.png';
// import codeCartoonChangeCostumes from './steps/code-cartoon-10-change-costumes.en.png';
// import codeCartoonChooseMoreCharacters from './steps/code-cartoon-11-choose-more-characters.LTR.png';

// // Talking Tales
// import talesAddExtension from './steps/speech-add-extension.en.gif';
// import talesChooseSprite from './steps/talking-2-choose-sprite.LTR.png';
// import talesSaySomething from './steps/talking-3-say-something.en.png';
// import talesChooseBackdrop from './steps/talking-4-choose-backdrop.LTR.png';
// import talesSwitchBackdrop from './steps/talking-5-switch-backdrop.en.png';
// import talesChooseAnotherSprite from './steps/talking-6-choose-another-sprite.LTR.png';
// import talesMoveAround from './steps/talking-7-move-around.en.png';
// import talesChooseAnotherBackdrop from './steps/talking-8-choose-another-backdrop.LTR.png';
// import talesAnimateTalking from './steps/talking-9-animate.en.png';
// import talesChooseThirdBackdrop from './steps/talking-10-choose-third-backdrop.LTR.png';
// import talesChooseSound from './steps/talking-11-choose-sound.en.gif';
// import talesDanceMoves from './steps/talking-12-dance-moves.en.png';
// import talesAskAnswer from './steps/talking-13-ask-and-answer.en.png';

// const enImages = {
//     // Intro
//     introMove: introMove,
//     introSay: introSay,
//     introGreenFlag: introGreenFlag,

//     // Text to Speech
//     speechAddExtension: speechAddExtension,
//     speechSaySomething: speechSaySomething,
//     speechSetVoice: speechSetVoice,
//     speechMoveAround: speechMoveAround,
//     speechAddBackdrop: speechAddBackdrop,
//     speechAddSprite: speechAddSprite,
//     speechSong: speechSong,
//     speechChangeColor: speechChangeColor,
//     speechSpin: speechSpin,
//     speechGrowShrink: speechGrowShrink,

//     // Cartoon Network
//     cnShowCharacter: cnShowCharacter,
//     cnSay: cnSay,
//     cnGlide: cnGlide,
//     cnPickSprite: cnPickSprite,
//     cnCollect: cnCollect,
//     cnVariable: cnVariable,
//     cnScore: cnScore,
//     cnBackdrop: cnBackdrop,

//     // Add sprite
//     addSprite: addSprite,

//     // Animate a name
//     namePickLetter: namePickLetter,
//     namePlaySound: namePlaySound,
//     namePickLetter2: namePickLetter2,
//     nameChangeColor: nameChangeColor,
//     nameSpin: nameSpin,
//     nameGrow: nameGrow,

//     // Make-Music
//     musicPickInstrument: musicPickInstrument,
//     musicPlaySound: musicPlaySound,
//     musicMakeSong: musicMakeSong,
//     musicMakeBeat: musicMakeBeat,
//     musicMakeBeatbox: musicMakeBeatbox,

//     // Chase-Game
//     chaseGameAddBackdrop: chaseGameAddBackdrop,
//     chaseGameAddSprite1: chaseGameAddSprite1,
//     chaseGameRightLeft: chaseGameRightLeft,
//     chaseGameUpDown: chaseGameUpDown,
//     chaseGameAddSprite2: chaseGameAddSprite2,
//     chaseGameMoveRandomly: chaseGameMoveRandomly,
//     chaseGamePlaySound: chaseGamePlaySound,
//     chaseGameAddVariable: chaseGameAddVariable,
//     chaseGameChangeScore: chaseGameChangeScore,

//     // Make-A-Pop/Clicker Game
//     popGamePickSprite: popGamePickSprite,
//     popGamePlaySound: popGamePlaySound,
//     popGameAddScore: popGameAddScore,
//     popGameChangeScore: popGameChangeScore,
//     popGameRandomPosition: popGameRandomPosition,
//     popGameChangeColor: popGameChangeColor,
//     popGameResetScore: popGameResetScore,

//     // Animate A Character
//     animateCharPickBackdrop: animateCharPickBackdrop,
//     animateCharPickSprite: animateCharPickSprite,
//     animateCharSaySomething: animateCharSaySomething,
//     animateCharAddSound: animateCharAddSound,
//     animateCharTalk: animateCharTalk,
//     animateCharMove: animateCharMove,
//     animateCharJump: animateCharJump,
//     animateCharChangeColor: animateCharChangeColor,

//     // Tell A Story
//     storyPickBackdrop: storyPickBackdrop,
//     storyPickSprite: storyPickSprite,
//     storySaySomething: storySaySomething,
//     storyPickSprite2: storyPickSprite2,
//     storyFlip: storyFlip,
//     storyConversation: storyConversation,
//     storyPickBackdrop2: storyPickBackdrop2,
//     storySwitchBackdrop: storySwitchBackdrop,
//     storyHideCharacter: storyHideCharacter,
//     storyShowCharacter: storyShowCharacter,

//     // Video Sensing
//     videoAddExtension: videoAddExtension,
//     videoPet: videoPet,
//     videoAnimate: videoAnimate,
//     videoPop: videoPop,

//     // Make it Fly
//     flyChooseBackdrop: flyChooseBackdrop,
//     flyChooseCharacter: flyChooseCharacter,
//     flySaySomething: flySaySomething,
//     flyMoveArrows: flyMoveArrows,
//     flyChooseObject: flyChooseObject,
//     flyFlyingObject: flyFlyingObject,
//     flySelectFlyingSprite: flySelectFlyingSprite,
//     flyAddScore: flyAddScore,
//     flyKeepScore: flyKeepScore,
//     flyAddScenery: flyAddScenery,
//     flyMoveScenery: flyMoveScenery,
//     flySwitchLooks: flySwitchLooks,

//     // Pong
//     pongAddBackdrop: pongAddBackdrop,
//     pongAddBallSprite: pongAddBallSprite,
//     pongBounceAround: pongBounceAround,
//     pongAddPaddle: pongAddPaddle,
//     pongMoveThePaddle: pongMoveThePaddle,
//     pongSelectBallSprite: pongSelectBallSprite,
//     pongAddMoreCodeToBall: pongAddMoreCodeToBall,
//     pongAddAScore: pongAddAScore,
//     pongChooseScoreFromMenu: pongChooseScoreFromMenu,
//     pongInsertChangeScoreBlock: pongInsertChangeScoreBlock,
//     pongResetScore: pongResetScore,
//     pongAddLineSprite: pongAddLineSprite,
//     pongGameOver: pongGameOver,

//     // Imagine a World
//     imagineTypeWhatYouWant: imagineTypeWhatYouWant,
//     imagineClickGreenFlag: imagineClickGreenFlag,
//     imagineChooseBackdrop: imagineChooseBackdrop,
//     imagineChooseSprite: imagineChooseSprite,
//     imagineFlyAround: imagineFlyAround,
//     imagineChooseAnotherSprite: imagineChooseAnotherSprite,
//     imagineLeftRight: imagineLeftRight,
//     imagineUpDown: imagineUpDown,
//     imagineChangeCostumes: imagineChangeCostumes,
//     imagineGlideToPoint: imagineGlideToPoint,
//     imagineGrowShrink: imagineGrowShrink,
//     imagineChooseAnotherBackdrop: imagineChooseAnotherBackdrop,
//     imagineSwitchBackdrops: imagineSwitchBackdrops,
//     imagineRecordASound: imagineRecordASound,
//     imagineChooseSound: imagineChooseSound,

//     // Add a Backdrop
//     addBackdrop: addBackdrop,

//     // Add Effects
//     addEffects: addEffects,

//     // Hide and Show
//     hideAndShow: hideAndShow,

//     // Switch Costumes
//     switchCostumes: switchCostumes,

//     // Change Size
//     changeSize: changeSize,

//     // Spin
//     spinTurn: spinTurn,
//     spinPointInDirection: spinPointInDirection,

//     // Record a Sound
//     recordASoundSoundsTab: recordASoundSoundsTab,
//     recordASoundClickRecord: recordASoundClickRecord,
//     recordASoundPressRecordButton: recordASoundPressRecordButton,
//     recordASoundChooseSound: recordASoundChooseSound,
//     recordASoundPlayYourSound: recordASoundPlayYourSound,

//     // Use Arrow Keys
//     moveArrowKeysLeftRight: moveArrowKeysLeftRight,
//     moveArrowKeysUpDown: moveArrowKeysUpDown,

//     // Glide Around
//     glideAroundBackAndForth: glideAroundBackAndForth,
//     glideAroundPoint: glideAroundPoint,

//     // Code a Cartoon
//     codeCartoonSaySomething: codeCartoonSaySomething,
//     codeCartoonAnimate: codeCartoonAnimate,
//     codeCartoonSelectDifferentCharacter: codeCartoonSelectDifferentCharacter,
//     codeCartoonUseMinusSign: codeCartoonUseMinusSign,
//     codeCartoonGrowShrink: codeCartoonGrowShrink,
//     codeCartoonSelectDifferentCharacter2: codeCartoonSelectDifferentCharacter2,
//     codeCartoonJump: codeCartoonJump,
//     codeCartoonChangeScenes: codeCartoonChangeScenes,
//     codeCartoonGlideAround: codeCartoonGlideAround,
//     codeCartoonChangeCostumes: codeCartoonChangeCostumes,
//     codeCartoonChooseMoreCharacters: codeCartoonChooseMoreCharacters,

//     // Talking Tales
//     talesAddExtension: talesAddExtension,
//     talesChooseSprite: talesChooseSprite,
//     talesSaySomething: talesSaySomething,
//     talesAskAnswer: talesAskAnswer,
//     talesChooseBackdrop: talesChooseBackdrop,
//     talesSwitchBackdrop: talesSwitchBackdrop,
//     talesChooseAnotherSprite: talesChooseAnotherSprite,
//     talesMoveAround: talesMoveAround,
//     talesChooseAnotherBackdrop: talesChooseAnotherBackdrop,
//     talesAnimateTalking: talesAnimateTalking,
//     talesChooseThirdBackdrop: talesChooseThirdBackdrop,
//     talesChooseSound: talesChooseSound,
//     talesDanceMoves: talesDanceMoves
// };

// export {enImages};
