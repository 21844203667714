import React from "react";
import PropTypes from "prop-types";
import { intlShape, injectIntl } from "react-intl";
import bindAll from "lodash.bindall";
import { connect } from "react-redux";
import axios from "../baseurl";
import { setProjectUnchanged } from "../reducers/project-changed";
import {
    LoadingStates,
    getIsCreatingNew,
    getIsFetchingWithId,
    getIsLoading,
    getIsShowingProject,
    onFetchedProjectData,
    projectError,
    setProjectId,
} from "../reducers/project-state";
import { activateTab, BLOCKS_TAB_INDEX } from "../reducers/editor-tab";
import { setProjectTitle } from "../reducers/project-title";
import { setGameInfo } from "../reducers/game_details";

import { getUrl } from "./get-url/get-url.js";

import log from "./log";
import storage from "./storage";
const isDemo = getUrl();

/* Higher Order Component to provide behavior for loading projects by id. If
 * there's no id, the default project is loaded.
 * @param {React.Component} WrappedComponent component to receive projectData prop
 * @returns {React.Component} component with project loading behavior
 */

const ProjectFetcherHOC = function (WrappedComponent) {
    class ProjectFetcherComponent extends React.Component {
        constructor(props) {
            super(props);
            bindAll(this, ["fetchProject"]);
            storage.setProjectHost(props.projectHost);
            storage.setProjectToken(props.projectToken);
            storage.setAssetHost(props.assetHost);
            storage.setTranslatorFunction(props.intl.formatMessage);
            // props.projectId might be unset, in which case we use our default;
            // or it may be set by an even higher HOC, and passed to us.
            // Either way, we now know what the initial projectId should be, so
            // set it in the redux store.
            if (
                props.projectId !== "" &&
                props.projectId !== null &&
                typeof props.projectId !== "undefined"
            ) {
                this.props.setProjectId(props.projectId.toString());
            }
        }
        componentDidUpdate(prevProps) {
            if (prevProps.projectHost !== this.props.projectHost) {
                storage.setProjectHost(this.props.projectHost);
            }
            if (prevProps.projectToken !== this.props.projectToken) {
                storage.setProjectToken(this.props.projectToken);
            }
            if (prevProps.assetHost !== this.props.assetHost) {
                storage.setAssetHost(this.props.assetHost);
            }
            if (this.props.isFetchingWithId && !prevProps.isFetchingWithId) {
                this.fetchProject(
                    this.props.reduxProjectId,
                    this.props.loadingState,
                );
            }
            if (this.props.isShowingProject && !prevProps.isShowingProject) {
                this.props.onProjectUnchanged();
            }
            if (
                this.props.isShowingProject &&
                (prevProps.isLoadingProject || prevProps.isCreatingNew)
            ) {
                this.props.onActivateTab(BLOCKS_TAB_INDEX);
            }
        }
        fetchProject(projectId, loadingState) {
            const gameId = localStorage.getItem("gameId");
            const userId = localStorage.getItem("UserId");
            const userRole = localStorage.getItem("UserRole");
            const userToken = localStorage.getItem("UserToken");
            const classRoomSessionId =
                localStorage.getItem("classRoomSessionId");
            let paramString = window.location.href.split("?")[1];
            let demoGameId = paramString.split("=")[1];
            var URL;
            if (userRole == "student") {
                URL = "/student/u_game";
            }
            if (userRole == "admin") {
                URL = "/master/game";
            }
            axios
                .post(
                    isDemo
                        ? `student/u_game/${demoGameId}/loadDemo`
                        : `${URL}/${gameId}/load?student_id=${userId}`,
                    {
                        student_id: userId,
                        sessionCode: classRoomSessionId,
                    },
                    {
                        headers: { Authorization: userToken },
                    },
                )
                .then((response) => {
                    var targetsarr = [];
                    const projectdata = response.data;
                    if (projectdata) {
                        this.props.onFetchedProjectData(
                            JSON.stringify(projectdata),
                            loadingState,
                        );
                    }
                })
                .catch((err) => console.log(err));

            axios
                .post(
                    isDemo
                        ? `student/u_game/${demoGameId}/dataDemo`
                        : `${URL}/${gameId}/data?student_id=${userId}`,
                    {
                        student_id: userId,
                        sessionCode: classRoomSessionId,
                    },
                    {
                        headers: {
                            Authorization: userToken,
                        },
                    },
                )
                .then((res) => {
                    if (res.data.success == true) {
                        this.props.setGameInfo(
                            "set_game_objective",
                            res?.data?.userGame?.objective,
                        );
                        this.props.setGameInfo(
                            "set_game_hint_and_tips",
                            res?.data?.userGame?.hint_and_tips,
                        );

                        userRole == "student"
                            ? this.props.setProjectTitle(
                                  res?.data?.userGame?.name,
                              )
                            : this.props.setProjectTitle(res?.data?.game?.name);
                    }
                })
                .catch((err) => console.log(err));
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                assetHost,
                intl,
                isLoadingProject: isLoadingProjectProp,
                loadingState,
                onActivateTab,
                onError: onErrorProp,
                onFetchedProjectData: onFetchedProjectDataProp,
                onProjectUnchanged,
                projectHost,
                projectId,
                reduxProjectId,
                setProjectId: setProjectIdProp,
                /* eslint-enable no-unused-vars */
                isFetchingWithId: isFetchingWithIdProp,
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    fetchingProject={isFetchingWithIdProp}
                    {...componentProps}
                />
            );
        }
    }
    ProjectFetcherComponent.propTypes = {
        assetHost: PropTypes.string,
        canSave: PropTypes.bool,
        intl: intlShape.isRequired,
        isCreatingNew: PropTypes.bool,
        isFetchingWithId: PropTypes.bool,
        isLoadingProject: PropTypes.bool,
        isShowingProject: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onActivateTab: PropTypes.func,
        onError: PropTypes.func,
        onFetchedProjectData: PropTypes.func,
        onProjectUnchanged: PropTypes.func,
        projectHost: PropTypes.string,
        projectToken: PropTypes.string,
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        reduxProjectId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        setProjectId: PropTypes.func,
        projectTitle: PropTypes.string,
        setProjectTitle: PropTypes.func,
        setGameInfo: PropTypes.func,
    };
    ProjectFetcherComponent.defaultProps = {
        assetHost: "https://assets-futurestars.s3.eu-north-1.amazonaws.com",
        // separate host for admin and user
        projectHost: "http://18.135.246.197:5000/api",
    };

    const mapStateToProps = (state) => ({
        isCreatingNew: getIsCreatingNew(
            state.scratchGui.projectState.loadingState,
        ),
        isFetchingWithId: getIsFetchingWithId(
            state.scratchGui.projectState.loadingState,
        ),
        isLoadingProject: getIsLoading(
            state.scratchGui.projectState.loadingState,
        ),
        isShowingProject: getIsShowingProject(
            state.scratchGui.projectState.loadingState,
        ),
        loadingState: state.scratchGui.projectState.loadingState,
        reduxProjectId: state.scratchGui.projectState.projectId,
    });
    const mapDispatchToProps = (dispatch) => ({
        onActivateTab: (tab) => dispatch(activateTab(tab)),
        onError: (error) => dispatch(projectError(error)),
        onFetchedProjectData: (projectData, loadingState) =>
            dispatch(onFetchedProjectData(projectData, loadingState)),
        setProjectId: (projectId) => dispatch(setProjectId(projectId)),
        onProjectUnchanged: () => dispatch(setProjectUnchanged()),
        setProjectTitle: (title) => dispatch(setProjectTitle(title)),
        setGameInfo: (type, data) => dispatch(setGameInfo(type, data)),
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) =>
        Object.assign({}, stateProps, dispatchProps, ownProps);
    return injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps,
            mergeProps,
        )(ProjectFetcherComponent),
    );
};

export { ProjectFetcherHOC as default };
