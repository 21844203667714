const initialState = {
    isObjectiveOpen: false,
    showObjectiveTab: false,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case 'SET_OBJECTIVE_OPEN':
            return Object.assign({}, state, action.state);
        case 'SET_SHOW_OBJECTIVE_TAB':
            return Object.assign({}, state, {
                showObjectiveTab: action.state.showObjectiveTab
            });
        default:
            return state;
    }
};

const setObjectiveOpen = function (isObjectiveOpen) {
    return {
        type: 'SET_OBJECTIVE_OPEN',
        state: {
            isObjectiveOpen: isObjectiveOpen
        }
    };
};

const setShowObjectiveTab = function (showObjectiveTab) {
    return {
        type: 'SET_SHOW_OBJECTIVE_TAB',
        state: {
            showObjectiveTab: showObjectiveTab
        }
    };
};

export {
    reducer as default,
    initialState as showObjectiveInitialState,
    setObjectiveOpen,
    setShowObjectiveTab
};