import 'driver.js/dist/driver.css';
import './gui.css';
import enLangTranslations from '../../../public/locales/en/translation.json';
import esLangTranslations from '../../../public/locales/es/translation.json';
import grLangTranslations from '../../../public/locales/gr/translation.json';

export const useDriverSteps = (driverInstance) => {

    const translations = {
        en: enLangTranslations,
        gr: grLangTranslations,
        es: esLangTranslations
    };

    const width = window.innerWidth;

    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    const t = key => translations[selectedLanguage][key] || key;

    const steps = [
        {
            element: '.languageChange',
            popover: {
                title: t('languageChangeTitle'),
                description: t('languageChangeDescription')
            }
        },
        {
            element: '.reloadPage',
            popover: {
                title: t('reloadPageTitle'),
                description: t('reloadPageDescription')
            }
        },
        {
            element: '.profile',
            popover: {
                title: t('profileTitle'),
                description: t('profileDescription')
            }
        },
        {
            element: '.blocks',
            popover: {
                title: t('blocksTitle'),
                description: t('blocksDescription')
            }
        },
        {
            element: '.stage',
            popover: {
                title: t('stageTitle'),
                description: t('stageDescription')
            }
        },
        {
            element: '.sprite',
            popover: {
                title: t('spriteTitle'),
                description: t('spriteDescription')
            }
        },
        {
            element: '.costumes',
            popover: {
                title: t('costumesTitle'),
                description: t('costumesDescription')
            }
        },
        {
            element: '.sound',
            popover: {
                title: t('soundTitle'),
                description: t('soundDescription')
            }
        },
        {
            element: '.code',
            popover: {
                title: t('codeTitle'),
                description: t('codeDescription')
            }
        },
        {
            element: '.extension',
            popover: {
                title: t('extensionTitle'),
                description: t('extensionDescription')
            }
        },
        {
            element: '.go',
            popover: {
                title: t('goTitle'),
                description: t('goDescription')
            }
        },
        {
            element: '.stop',
            popover: {
                title: t('stopTitle'),
                description: t('stopDescription')
            }
        },
        {
            element: '.fullscreen',
            popover: {
                title: t('fullscreenTitle'),
                description: t('fullscreenDescription')
            }
        },
    ];

    if (width <= 1280) {
        steps.push({
            element: '.stageInfoIcon',
            popover: {
                title: t('stageInfoIconTitle'),
                description: t('stageInfoIconDescription'),
            },
        });
    } else {
        steps.push(
            {
                element: '.objective',
                popover: {
                    title: t('objectiveTitle'),
                    description: t('objectiveDescription'),
                },
            },
            {
                element: '.hints',
                popover: {
                    title: t('hintsTitle'),
                    description: t('hintsDescription'),
                },
            }
        );
    }

    return steps;
};
